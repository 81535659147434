// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import AppModule from '../AppModule';
import {setCrumbPath, showSnackbar} from '../../actions';
import classes from './Taken.module.scss';
import {apiDeleteTaskById, fetchAllTasks} from "../../lib/api";
import i18n from "i18next";
import EnhancedTable from "../EnhancedTable";
import moment from "../../lib/moment";
import ConfirmDialog from "../ConfirmDialog";
import {Menu, MenuItem} from "@mui/material";
import TableActionHeader from "../TableActionHeader";
import FilterHeader from "../FilterHeader";
import $ from "jquery";
import {push} from "connected-react-router";
import classnames from "classnames";


type Props = {
};

type State = {

};

const mapStateToProps = (store, props) => {

    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCrumbPath: () => {
            dispatch(setCrumbPath({ title: i18n.t('Task Title') }));
        },
        goTo: (path) => {
            dispatch(push(path));
        },

    };
};

class Taken extends React.Component<Props, State> {
    props: Props;
    state: state = {
        loading: false,
        confirmDialogOpen: false,
        editDialogOpen: false,
        editDialogViewOnly: true,
        selectedTask: null,
        tasks: [],
        typeFilter: 0,
        openType: false,
        searchtext: "",
        actions: [
            { id: 'delete', label: i18n.t('Delete') },
        ],
        columns: [
            {
                id: 'for',
                numeric: false,
                size: '20%',
                label: 'Voor Medewerker',
            },
            {
                id: 'date',
                numeric: false,
                size: '15%',
                label: 'Datum',
            },
            {
                id: 'title',
                numeric: false,
                size: '50%',
                label: 'Title',
            },
            {
                id: 'status',
                numeric: false,
                size: '15%',
                label: "Status",
            },
        ],
    };

    componentDidMount() {
        this.loadData();
        this.props.setCrumbPath();
    }

    loadData() {
        this.setState({ loading: true });
        fetchAllTasks()
            .then((entities) => {
                // Sort latest till oldest
                const sortedTasks = entities.body._embedded.tasks.sort((a,b) => {
                    if (a.date < b.date) return -1;
                    if (a.date > b.date) return 1;
                    return 0;
                })

                this.setState({
                    loading: false,
                    tasks: sortedTasks
                });

            })
            .catch((error) => {
                this.setState({ loading: false });
                showSnackbar(
                    `${i18n.t(
                        'Unknown error while fetching all tasks'
                    )}: ` + error.message
                )
                throw error;
            });
    }

    deleteTask = (task) => {
        apiDeleteTaskById(task)
            .then((entities) => {
                const newList = this.state.tasks.filter((myTask) => (myTask.id !== task));
                this.setState({
                    tasks: newList,
                    taskToHandle: null,
                });

            })
            .catch((error) => {
                showSnackbar(
                    `${i18n.t(
                        'Unknown error while deleting tasks'
                    )}: ` + error.message
                )
                throw error;
            });

    }

    updateList = (updatedTask) => {
        let updatedList = []
        let updated = false;
        this.state.tasks.forEach((task) => {
            if (task.id === updatedTask.id) {
                updatedList.push(updatedTask);
                updated = true;
            } else {
                updatedList.push(task);
            }
        })
        if (updated === false) {
            // This is a new task
            updatedList.push(updatedTask);
        }
        this.setState({
            tasks: updatedList
        });
        // this.loadData();
    }
    onRowClick = (task, event) => {
        if (task.inspectionAssign) {
            // Goto inspection assign werkplekinspectie/details/12599#trend
            let path='werkplekinspectie/details/' + task.inspectionAssign.id + '#tasks';
            this.props.goTo(path);
        }
        if (task.incident) {
            // Goto inspection assign werkplekinspectie/details/12599#trend
            let path='registratiemelding/details/' + task.incident.id + '#tasks';
            this.props.goTo(path);
        }
    }

    handleAction = (event, action) => {
        if (action.id === 'delete') {
            this.setState({
                taskToHandle: action.rowId,
            });

            this.showConfirmDialog();
        }
    };

    hideConfirmDialog = () => {
        this.setState({
            confirmDialogOpen: false,
        });
    };

    showConfirmDialog = () => {
        this.setState({
            confirmDialogOpen: true,
        });
    };

    hideEditDialog = () => {
        this.setState({
            editDialogOpen: false,
            editDialogViewOnly: true
        });
    };

    showEditDialog = () => {
        this.setState({
            editDialogOpen: true,
        });
    };
    showAddDialog = () => {
        this.setState({
            selectedTask: null,
            editDialogViewOnly: false,
            editDialogOpen: true,
        });
    };


    mapTaskToRow = (task  ) => {
        const completed = (
            <div>
                <span
                    className={classnames({
                        [classes.taskFinished]: task.taskFeedback.done,
                        [classes.taskNotFinished]: !task.taskFeedback.done,
                    })}
                    style={{ fontWeight: '300' }}
                >
                    {task ? task.taskFeedback.done ? i18n.t('StatusDone') : i18n.t('StatusNotDone') : '-' }
                </span>
            </div>
        );

        return {
            ...task,
            for: task
                ? task.user
                    ? task.user.fullname
                    : '-'
                : '0',
            date: task
                ? task.date
                    ? moment(task.date).format('L HH:mm')
                    : '-'
                : '-',
            title: task
                ? task.title
                : '-',
            status: completed,
        };
    };

    taskSorter = () => {
        return {
            date: (a, b) => {
                a = a.date;
                b = b.date;

                if (a == null) {
                    return -1;
                }
                if (b == null) {
                    return 1;
                }

                a = moment(a).valueOf();
                b = moment(b).valueOf();

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            for: (a, b) => {
                a = a.user.fullname.toLowerCase();
                b = b.user.fullname.toLowerCase();

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            title: (a, b) => {
                a = a.title.toLowerCase();
                b = b.title.toLowerCase();

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            status: (a, b) => {
                a = a.taskFeedback.done;
                b = b.taskFeedback.done;

                if (a === true && b === false) {
                    return 1;
                } else if (a === false && b === true) {
                    return -1;
                } else {
                    return 0;
                }
            },

        };
    };

    handleSearch = (searchtext) => {
        this.setState({
            searchtext: searchtext.toLowerCase(),
        });
    };

    handleTypeFilterChange = (event) => {
        this.handleRequestClose();
        this.setState({ typeFilter: event.target.value });
    };

    handleRequestClose = () => {
        const newState = Object.assign({}, this.state, { openType: false });
        this.setState(newState);
    };

    handleTouchTap0 = (event: Event) => {
        // This prevents ghost click.
        event.preventDefault();

        const newState = Object.assign({}, this.state, {
            openType: true,
            anchorEl: event.currentTarget,
        });

        this.setState(newState);
        setTimeout(() => {
            $('ul.tabs').tabs();
        }, 100);
    };



    getFilterBar = (searchresults) => {
        const { t } = i18n;
        const { tasks, searchtext } = this.state;
        let filterDone = true;
        let filterOpen = true;

        return (
            <div className="filterbar">
                <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                    {tasks.length +  ' ' + (tasks.length === 1 ? t('task') : t('tasks')) +
                        (searchtext.length > 0
                            ? (searchresults.length === 1
                                ? ' - ' + searchresults.length + ' ' + t('searchresult')
                                : '') +
                            (searchresults.length > 1 || searchresults.length === 0
                                ? ' - ' + searchresults.length + t('searchresults')
                                : '')
                            : '')}
                </div>
                <div style={{ marginLeft: '20px' }}>
                    <span onClick={this.handleTouchTap0}>
                        <FilterHeader
                            filterTitle={'Status'}
                            filterValue={
                                this.state.typeFilter === 0
                                    ? t('Alles')
                                    : this.state.typeFilter === 1
                                        ? t('StatusNotDone')
                                        : t('StatusDone')
                            }
                        />
                    </span>
                </div>

                <Menu
                    open={this.state ? this.state.openType : false}
                    onClose={this.handleRequestClose}
                    value={this.state.typeFilter}
                    anchorEl={this.state ? this.state.anchorEl : 0}
                >
                    <MenuItem onClick={this.handleTypeFilterChange} value={0}>
                        {t('Everything')}
                    </MenuItem>
                    {filterOpen && (
                        <MenuItem
                            onClick={this.handleTypeFilterChange}
                            value={1}
                        >
                            {t('StatusNotDone')}
                        </MenuItem>
                    )}
                    {filterDone && (
                        <MenuItem
                            onClick={this.handleTypeFilterChange}
                            value={2}
                        >
                            {t('StatusDone')}
                        </MenuItem>
                    )}
                </Menu>
            </div>
        );
    };




    render() {
        const { t } = i18n;
        const { tasks, actions, columns, loading, searchtext } = this.state;

        const searchresults = tasks.filter((task) => {
            // Filter on Search
            let textFound = false;
            if (task.title.toLowerCase().indexOf(searchtext) > -1) {
                textFound = true;
            }
            if (task.description.toLowerCase().indexOf(searchtext) > -1) {
                textFound = true;
            }
            if (task.user.fullname.toLowerCase().indexOf(searchtext) > -1) {
                textFound = true;
            }
            if (task.project) {
                if (task.project.omschrijving.toLowerCase().indexOf(searchtext) > -1) {
                    textFound = true;
                }
            }
            return textFound;
        }).filter((task) => {
            // Filter on filter selection
            if (this.state.typeFilter === 0) {
                return true; // All
            }
            if (this.state.typeFilter === 1 && task.taskFeedback.done === false) {
                return true; // Open
            }
            if (this.state.typeFilter === 2 && task.taskFeedback.done === true) {
                return true; // Closed
            }

            return false;


        });

        const tableActionHeader = (
            <TableActionHeader
                searchPlaceholder="Zoek binnen taken"
                onSearchChange={this.handleSearch}
                title={this.getFilterBar(searchresults)}
            />
        );


        return (
            <div className="Task">
                <AppModule className={classes.appModule}
                    loading={loading}
                    hasTabs={false}
                    prepend={tableActionHeader}
                >

                    <ConfirmDialog
                        open={this.state.confirmDialogOpen}
                        handleClose={this.hideConfirmDialog}
                        deletetask={this.state.taskToHandle}
                        deleteTaskFunction={this.deleteTask}
                    />
                    <EnhancedTable
                        hover
                        columns={columns}
                        onClick={this.onRowClick}
                        defaultOrder="desc"
                        defaultOrderBy={t('Description')}
                        rows={searchresults}
                        formatter={this.mapTaskToRow}
                        actions={actions}
                        onAction={this.handleAction}
                        sorter={this.taskSorter()}
                        emptyState={
                            tasks
                                ? t('No tasks')
                                : t('No tasks')
                        }

                        onPageChange={this.props.setPage}
                        onRowsPerPageChange={this.props.setRowsPerPage}
                        startPage={1}
                        startRowsPerPage={10}

                    />

                </AppModule>
            </div>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Taken);
