// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { push, goBack } from 'connected-react-router';
import { Dialog, DialogTitle } from '@mui/material';
import { bindActionCreators } from 'redux';

import Slide from '@mui/material/Slide';
import {
    initEmployeeDraft,
    editEmployeeDraft,
    upsertEmployee,
    editEmployee,
} from '../../../actions';
import { inProgress } from '../../../selectors';
import type { TEmployee } from '../../../reducers/employeesReducer';
import EmployeeEditCard from '../EmployeeEditCard';
import { changeFactuurEmail } from '../../../lib/api';
import { fetchSubscriptions } from '../../../actions';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

type Props = {
    loading: boolean,
    open: boolean,
    editemployee: TEmployee,
    goBack: () => mixed,
    onEdit: (employee: TEmployee) => mixed,
    clearDiff: () => mixed,
    pageAfterSave?: string,
    display: string,
};

type State = {
    passwordConfirm: string,
    password: string,
};

const mapStateToProps = (store, props) => {
    return {
        loading: !inProgress(store),
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        onSave: (employee) => {
            upsertEmployee(employee)(dispatch);
            dispatch(
                push(props.pageAfterSave ? props.pageAfterSave : '/medewerkers')
            );
            if (props.pageAfterSave === '/abonnement') {
                //dispatch(push(window.location.reload()))
                dispatch(fetchSubscriptions());
                //dispatch(getCurrentEmployee());
            }
        },
        onChange: (key, value) => {
            dispatch(editEmployeeDraft(props.employee.id, key, value));
        },
        initDraft: (toolbox) => {
            dispatch(initEmployeeDraft(toolbox));
        },
        editEmployee: bindActionCreators(editEmployee, dispatch),
        goBack: () => {
            dispatch(goBack());
        },
        changeFactuurEmail: (email) => {
            changeFactuurEmail('subscription', {
                ...props.subscription,
                factuurEmail: email,
            });
        },
    };
};

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class EmployeeEdit extends React.Component<Props, State> {
    props: Props;
    state: State = {
        passwordConfirm: '',
        password: '',
    };

    cancelEdit = () => {
        this.props.goBack();
        if (this.props.onEdit) {
            this.props.onEdit(undefined);
        }
    };

    componentDidMount() {
        if (this.props.toolbox) {
            this.props.initDraft(this.props.employee);
        }
    }

    render() {
        //Als we geen employee hebben niets renderen
        if (!this.props.editEmployee) {
            return <div />;
        }

        const { open, t } = this.props;

        return (
            <Dialog
                open={open}
                onClose={this.cancelEdit}
                TransitionComponent={Transition}
                className="victor"
            >
                <DialogTitle className="title">
                    {this.props.abonneehouder
                        ? t('Change subscriber')
                        : t('Change employee')}
                </DialogTitle>
                <EmployeeEditCard
                    hideParents={true}
                    employee={this.props.editemployee}
                    onEdit={this.props.editEmployee}
                    onSave={this.props.onSave}
                    goBack={this.props.goBack}
                    clearDiff={this.props.clearDiff}
                    display={'employee'}
                    abonneehouder={this.props.abonneehouder}
                    changeFactuurEmail={this.props.changeFactuurEmail}
                    factuurEmail={this.props.factuurEmail}
                />
            </Dialog>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(EmployeeEdit);
