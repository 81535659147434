// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { Divider } from '@mui/material';

import AppModule from '../../AppModule';
import { setCrumbPath } from '../../../actions';
import { getPermissions, inProgress } from '../../../selectors';
import { push } from 'connected-react-router';
import { openDialog } from '../../../actions/uiActions';
import TableActionHeader from '../../TableActionHeader/TableActionHeader';
import { TEmployee } from '../../../reducers/employeesReducer';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import classes from './PublicInspections.module.scss';
import { getPublicInspections } from '../../../actions';
import AddIcon from '@mui/icons-material/AddCircle';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';

type Props = {
    setCrumbPath: () => void,
    permissions: any,
    classes: any,
    loading: boolean,
    getPublicInspections: () => any,
};

type State = {
    currentEmpl: TEmployee,
};

const mapStateToProps = (store) => {
    return {
        loading: !inProgress(store),
        permissions: getPermissions(store),
        publicInspections: store.entities.publicInspections,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCrumbPath: () => {
            dispatch(
                setCrumbPath({ title: i18n.t('Workplace inspection forms') })
            );
        },
        openDialog: (dialogName, data) => {
            dispatch(openDialog(dialogName, data));
        },
        getPublicInspections: () => {
            dispatch(getPublicInspections());
        },
        goTo: (path) => {
            dispatch(push(path));
        },
    };
};

class PublicInspections extends React.Component<Props, State> {
    props: Props;
    state: State = {};

    componentDidMount() {
        this.props.setCrumbPath();
        this.props.getPublicInspections();
    }

    gotoInspectionResult = (id) => {
        this.props.goTo('nieuweinspectie/' + id);
    };

    render() {
        const { loading, publicInspections, t } = this.props;

        // window.$crisp.push(['do', 'chat:hide']);

        let inspections = [];
        if (publicInspections && inspections.length === 0) {
            publicInspections.allIds.map(
                (id) =>
                    publicInspections.byId[id].inspection.results !== null &&
                    inspections.push(publicInspections.byId[id])
            );
        }

        const tableActionHeader = <TableActionHeader />;
        return (
            <div>
                <AppModule loading={loading} prepend={tableActionHeader}>
                    <div className={classes.content}>
                        {inspections.length > 0 ? (
                            inspections.map((row, index) => {
                                return (
                                    <div
                                        style={{
                                            width: '610px',
                                            height: '92px',
                                            margin: 'auto',
                                            marginBottom: '16px',
                                            cursor: 'pointer',
                                        }}
                                        id="toolboxBlocks"
                                        onClick={() =>
                                            this.gotoInspectionResult(row.id)
                                        }
                                    >
                                        <div
                                            style={{
                                                width: '75px',
                                                height: '100%',
                                                backgroundImage:
                                                    'url(/assets/images/cat_werkplekinspectie.png)',
                                                backgroundRepeat: 'no-repeat',
                                                float: 'left',
                                                backgroundPosition: 'center',
                                                backgroundSize: '56px 56px',
                                            }}
                                        />
                                        <ArrowForwardIcon
                                            style={{
                                                float: 'right',
                                                marginRight: '15px',
                                                marginTop: '15px',
                                            }}
                                        />
                                        <div
                                            style={{
                                                float: 'left',
                                                width: '150px',
                                            }}
                                        >
                                            <p
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    width: '450px',
                                                }}
                                                id="toolboxText"
                                            >
                                                <b>{row.title}</b>
                                            </p>
                                            <p
                                                style={{
                                                    opacity: '0.5',
                                                    lineHeight: '2px',
                                                    width: '450px',
                                                }}
                                            >
                                                {t('Author') +
                                                    ': ' +
                                                    row.author}
                                            </p>
                                        </div>
                                        <Divider style={{ width: '100%' }} />
                                    </div>
                                );
                            })
                        ) : (
                            <div style={{ marginTop: '30vh' }}>
                                <AddIcon
                                    style={{
                                        margin: 'auto',
                                        width: '100%',
                                        height: '64px',
                                        opacity: '0.4',
                                    }}
                                />
                                <div
                                    style={{
                                        textAlign: 'center',
                                        opacity: '0.4',
                                    }}
                                >
                                    {t(
                                        'There are no public workplace inspections'
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </AppModule>
                <div style={{ visibility: 'hidden' }}>
                    {
                        (document.getElementById('html').style.backgroundColor =
                            '#f5f5f5')
                    }
                </div>
            </div>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
    
)(PublicInspections);
