// @flow
import React from 'react'; // eslint-disable-line no-unused-vars

import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Card, Button } from '@mui/material';
import { openDialog, setCrumbPath } from '../../actions';
import {
    inProgress,
    getPermissions,
    getVisibleInspectionVersions,
} from '../../selectors';
import getRoles from '../../selectors/getRoles';

import CheckListBlockPreview from './CheckListBlockPreview';

import './InspectionPreview.css';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import {withRouter} from "react-router";
import classes from './InspectionPreview.module.scss';

type Props = {
    loading: boolean,
    toolboxes: Array<any>,
    sectors: Array<any>,
    categories: Array<any>,
    roles: Array<any>,
};

type State = {
    toolboxErrorDialogOpen: boolean,
    toolboxErrorDialogText: string,
};


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        editInspection: (id) => {
            dispatch(push('/werkplekinspecties/' + id));
        },
        deployInspection: (id) => {
            dispatch(openDialog('inspectionDeploy', { id }));
        },
        setCrumbPath: (inspectionName: string) => {

            const hash = '?' + ownProps.location.hash.split('?')[1];
            let page = new URLSearchParams(hash).get("page");
            let rowsPerPage = new URLSearchParams(hash).get("rowsPerPage");
            console.log("INFO calling setCrumbPath in InspectionPreview: hash = " + hash + " page="+page + "rowsPerPage="+rowsPerPage);

            let activeTab = '#overzicht';
            if (ownProps.location.hash.includes("#ingepland")) { activeTab = '#ingepland' };
            if (ownProps.location.hash.includes("#uitgestuurd")) { activeTab = '#uitgestuurd' };

            dispatch(
                setCrumbPath({
                    title: 'Werkplekinspecties',
                    crumbs: [
                        {
                            name: inspectionName,
                            link: '/werkplekinspecties' + activeTab + '?page=' + page + '&rowsPerPage=' + rowsPerPage,
                        },
                    ],
                })
            );
        },
    };
};

const mapStateToProps = (store) => {
    let inspections = getVisibleInspectionVersions(store);
    const subscription = store.drafts.employees.currentUser
        ? store.drafts.employees.currentUser.subscription
        : null;

    return {
        loading: !inProgress(store),
        inspections: inspections,
        sectorEntities: store.entities.sectors,
        categoryEntities: store.entities.categories,
        contentBlockDrafts: store.drafts.contentBlocks,
        questionBlockDrafts: store.drafts.questionBlocks,
        permissions: getPermissions(store),
        roles: getRoles(store),
        subscription: subscription,
    };
};

class InspectionPreview extends React.Component<Props, State> {
    componentDidMount() {

        console.log("INFO: componentDidMount try to set page and rowsPerPage");
        if (this.props.location.search.includes("page")) {
            const fromPage = new URLSearchParams(this.props.location.search).get("page");

            this.setState({ page: Number(fromPage) });
        }
        if (this.props.location.search.includes("rowsPerPage")) {
            const rowsPerPage = new URLSearchParams(this.props.location.search).get("rowsPerPage");
            this.setState({ rowsPerPage: Number(rowsPerPage) });
        }
    }

    editInspection = () => {
        this.props.editInspection(this.props.inspection.id);
    };

    sendInspection = () => {
        this.props.deployInspection(this.props.inspection.id);
    };

    render() {
        const { style, contentBlockDrafts, t } = this.props;
        const inspection = this.props.inspection;
        const showActionButtons = true;
        const previousHash = new URLSearchParams(window.location.search).get(
            'from'
        );

        if (!inspection) {
            return <div />;
        }

        if (window.location.href.indexOf('details') > -1) {
            inspection.inspection.blocks = inspection.inspection.categories;
        }

        return (
            <div style={style} className={classes.root}>
                <div className="previewView" style={{ textAlign: '' }}>
                    <div
                        className={
                            window.location.href.indexOf('details') > -1
                                ? 'wpiPreview'
                                : 'ietsAnders'
                        }
                    >
                        <Card
                            className={
                                window.location.href.indexOf('details') > -1
                                    ? 'ietsAnders'
                                    : 'fix'
                            }
                        >
                            <div style={{ margin: '16px', padding: '16px' }}>
                                <div style={{ margin: 'auto', width: '300px' }}>
                                    {inspection.title && (
                                        <h2 style={{ wordBreak: 'break-all' }}>
                                            {inspection.title}
                                        </h2>
                                    )}
                                    {inspection.inspection.blocks.map(
                                        (block) => {
                                            const blockId = block.id
                                                ? block.id
                                                : block;
                                            let draftBlock = contentBlockDrafts ?  contentBlockDrafts[blockId] : null;
                                            if (!draftBlock) {
                                                return <div />;
                                            }
                                            return (
                                                <div key={blockId}>
                                                    <h3
                                                        className={
                                                            'actiePuntTitle'
                                                        }
                                                        style={{
                                                            wordBreak:
                                                                'break-all',
                                                        }}
                                                    >
                                                        {draftBlock.title}
                                                    </h3>
                                                    <CheckListBlockPreview
                                                        block={draftBlock}
                                                    />
                                                </div>
                                            );
                                        }
                                    )}
                                </div>
                            </div>
                        </Card>
                    </div>
                    {window.location.href.indexOf('details') > -1 && (
                        <div className="info">
                            <div className="fixedContainer">
                                <div className="check">
                                    <h3
                                        className="myNetjes"
                                        style={{ wordBreak: 'break-all' }}
                                    >
                                        {inspection.title}
                                    </h3>

                                    <div className="myNetjes">
                                        {t('View an example of the workplace inspection on the left.')}
                                    </div>

                                    {showActionButtons && (
                                        <div>
                                            <h3 className="myNetjes">
                                                {t('CHECKLIST SENDING A WORKPLACE INSPECTION')}
                                            </h3>
                                            <div className="myNetjes">
                                                {t('1. Choose the correct workplace inspection and check the contents')}
                                            </div>
                                            <div className="myNetjes">
                                                {t('2. Fill in the relevant details for the workplace inspection')}
                                            </div>
                                            <div className="myNetjes">
                                                {t('3. Select your correct workplace inspection recipient')}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {inspection.source === 'cumela' ? (
                                    (this.props.roles.includes('CUMELA') ||
                                        (!this.props.roles.includes('CUMELA') &&
                                            this.props.subscription &&
                                            this.props.subscription.cumela ===
                                                true) ||
                                        (!this.props.roles.includes('CUMELA') &&
                                            this.props.subscription &&
                                            this.props.subscription.cumela ===
                                                false &&
                                            this.props.subscription.caoLeo ===
                                                true)) && (
                                        <div>
                                            {showActionButtons &&
                                                previousHash !==
                                                    'uitgestuurd' &&
                                                previousHash !==
                                                    'ingepland' && (
                                                    <Button
                                                        className="buttonNetjes"
                                                        onClick={
                                                            this.editInspection
                                                        }
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        {t('Change workplace inspection')}
                                                    </Button>
                                                )}
                                            {showActionButtons &&
                                                previousHash !==
                                                    'uitgestuurd' &&
                                                previousHash !==
                                                    'ingepland' && (
                                                    <Button
                                                        className="buttonNetjes"
                                                        onClick={
                                                            this.sendInspection
                                                        }
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        {t('Sent out workplace inspection')}
                                                    </Button>
                                                )}
                                            {showActionButtons && (
                                                <Button
                                                    className="buttonNetjes"
                                                    onClick={
                                                        this.props.onPDFClick
                                                    }
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    {t('PDF')}
                                                </Button>
                                            )}
                                        </div>
                                    )
                                ) : (
                                    <div>
                                        {showActionButtons &&
                                            previousHash !== 'uitgestuurd' &&
                                            previousHash !== 'ingepland' && (
                                                <Button
                                                    className="buttonNetjes"
                                                    onClick={
                                                        this.editInspection
                                                    }
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    {t('Change workplace inspection')}
                                                </Button>
                                            )}
                                        {showActionButtons &&
                                            previousHash !== 'uitgestuurd' &&
                                            previousHash !== 'ingepland' && (
                                                <Button
                                                    className="buttonNetjes"
                                                    onClick={
                                                        this.sendInspection
                                                    }
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    {t('Sent out workplace inspection')}
                                                </Button>
                                            )}
                                        {showActionButtons && (
                                            <Button
                                                className="buttonNetjes"
                                                onClick={this.props.onPDFClick}
                                                variant="contained"
                                                color="primary"
                                            >
                                                {t('PDF')}
                                            </Button>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(compose(withTranslation(), )(InspectionPreview))
);

