// @flow
import React from 'react'; // eslint-disable-line no-unused-vars

import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
    Paper,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@mui/material';
import {
    getCurrentEmployee,
    getToolboxById,
    initToolboxDraft,
    openDialog,
    setCrumbPath,
} from '../../actions';
import {
    inProgress,
    getPermissions,
} from '../../selectors';
import getRoles from '../../selectors/getRoles';
import _ from 'lodash';

import BlockPreview from './BlockPreview';
import QuestionPreview from './QuestionPreview';

import './ToolboxPreview.css';
import { isUndefinedOrNull } from '../../lib/utils';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import classes from './ToolboxPreview.module.scss';
import colors from "../../lib/themes/theme.scss";


type Props = {
    loading: boolean,
    sectors: Array<any>,
    categories: Array<any>,
    roles: Array<any>,
};

type State = {
    toolboxErrorDialogOpen: boolean,
    toolboxErrorDialogText: string,
    format: string,
};

const mapDispatchToProps = (dispatch) => {
    return {
        editToolbox: (id) => {
            dispatch(push('/toolboxen/' + id));
        },
        deployToolbox: (id) => {
            dispatch(openDialog('toolboxDeploy', { id }));
        },
        setCrumbPath: (toolboxName: string) => {
            dispatch(
                setCrumbPath({
                    title: 'Toolboxen',
                    crumbs: [
                        { name: toolboxName, link: '/toolboxen#verzonden' },
                    ],
                })
            );
        },
        fetchCurrentUser: () => {
            dispatch(getCurrentEmployee());
        },
        getToolboxById: (toolboxId) => {
            dispatch(getToolboxById(toolboxId));
        },
        initDraft: (toolbox) => {
            dispatch(initToolboxDraft(toolbox));
        },
    };
};

const mapStateToProps = (store, props) => {
    const subscription = store.drafts.employees.currentUser
        ? store.drafts.employees.currentUser.subscription
        : null;

    let toolbox = props.toolbox;
    if (
        (isUndefinedOrNull(toolbox) || isUndefinedOrNull(toolbox.toolbox)) &&
        !isUndefinedOrNull(store.entities.toolbox.byId[toolbox.id])
    ) {
        toolbox = props.toolboxDraft || store.entities.toolbox.byId[toolbox.id];
    }

    return {
        loading: !inProgress(store),
        toolbox: toolbox,
        sendToolbox: store.entities.toolbox.byId[toolbox.id],
        sectorEntities: store.entities.sectors,
        categoryEntities: store.entities.categories,
        contentBlockDrafts: store.drafts.contentBlocks,
        questionBlockDrafts: store.drafts.questionBlocks,
        permissions: getPermissions(store),
        roles: getRoles(store),
        subscription: subscription,
    };
};

class ToolboxPreview extends React.Component<Props, State> {
    props: Props;
    state: State = {
        format: 'desktop',
        toolboxErrorDialogOpen: false,
    };

    componentDidMount() {
        this.props.fetchCurrentUser();
    }

    componentDidUpdate(prevProps: Props, prevState: State, prevContext: *): * {
        if (
            !_.isEqual(prevProps.toolbox.toolbox, this.props.toolbox.toolbox) &&
            !isUndefinedOrNull(this.props.toolbox.toolbox)
        ) {
            this.props.initDraft(this.props.toolbox);
        }

        if (this.props.toolbox.id !== prevProps.toolbox.id) {
            this.props.getToolboxById(this.props.toolbox.id);
        }
    }

    editToolbox = () => {
        // window.$crisp.push(['do', 'chat:hide']);
        this.props.editToolbox(this.props.toolbox.id);
    };

    sendToolbox = () => {
        let toolbox = this.props.sendToolbox;

        // Check eerst of er wel vragen zijn
        if (!toolbox || !toolbox.toolbox.questions.length) {
            this.showToolboxQuestionCountError();
        } else {
            // Check of alle vragen ook mogelijke antwoorden hebben
            let questionsWithoutAnswers = toolbox.toolbox.questions
                .map((q, i) => {
                    return { index: i, answers: q.possibleAnswers };
                })
                .filter((q) => q.answers.length < 2)
                .map((q) => q.index + 1);

            if (questionsWithoutAnswers.length) {
                this.showToolboxQuestionAnswerError(questionsWithoutAnswers);
            } else {
                let questionsWithoutCorrectAnswers = toolbox.toolbox.questions
                    .map((q, i) => {
                        return { index: i, correct: q.correctAnswers };
                    })
                    .filter((q) => !q.correct.length)
                    .map((q) => q.index + 1);

                if (questionsWithoutCorrectAnswers.length) {
                    this.showToolboxQuestionCorrectAnswerError(
                        questionsWithoutCorrectAnswers
                    );
                } else {
                    this.props.deployToolbox(this.props.toolbox.id);
                }
            }
        }
    };

    showToolboxQuestionCountError = () => {
        const { t } = this.props;
        this.setState({
            toolboxErrorDialogOpen: true,
            toolboxErrorDialogText:
                t('Toolbox must have at least one question'),
        });
    };

    changeView = () => {
        if (this.state.format === 'mobile') {
            this.setState({
                format: 'desktop',
            });
        } else {
            this.setState({
                format: 'mobile',
            });
        }
    };

    showToolboxQuestionAnswerError = (emptyQuestions) => {
        const { t } = this.props;
        let text =
            t('All questions in the toolbox must have at least two possible answers. Question number') +
            emptyQuestions
                .map((qi, i) => {
                    let count = emptyQuestions.length;
                    if (i === count - 1) {
                        return qi;
                    } else if (i === count - 2) {
                        return qi + (' and ');
                    } else {
                        return qi + ', ';
                    }
                })
                .join('') +
            t('not enough question are added');
        this.setState({
            toolboxErrorDialogOpen: true,
            toolboxErrorDialogText: text,
        });
    };

    showToolboxQuestionCorrectAnswerError = (emptyQuestions) => {
        const { t } = this.props;
        let text =
            t('All questions in the toolbox must have an answer marked as correct. Question number') +
            emptyQuestions
                .map((qi, i) => {
                    let count = emptyQuestions.length;
                    if (i === count - 1) {
                        return (
                            qi +
                            (count === 1
                                ? t('is not yet an answer')
                                : t('are not yet answers'))
                        );
                    } else if (i === count - 2) {
                        return qi + (' and ');
                    } else {
                        return qi + ', ';
                    }
                })
                .join('') +
            t('marked correct');
        this.setState({
            toolboxErrorDialogOpen: true,
            toolboxErrorDialogText: text,
        });
    };

    render() {
        const {
            style,
            toolbox,
            contentBlockDrafts,
            questionBlockDrafts,
            roles,
            t,
        } = this.props;
        const { toolboxErrorDialogOpen, toolboxErrorDialogText } = this.state;

        const showActionButtons =
            toolbox.publishState === 'PUBLISHED' ||
            roles.includes('CUMELA') ||
            roles.includes('ADMIN');
        const previousHash = new URLSearchParams(window.location.search).get(
            'from'
        );

        return (
            <div
                style={style}
                className={
                    window.location.href.indexOf('details') > -1
                        ? classes.root
                        : this.state.format !== 'mobile'
                        ? classes.rootdesktop
                        : classes.rootmobile
                }
            >
                <Dialog
                    open={toolboxErrorDialogOpen}
                    onClose={() => {
                        this.setState({ toolboxErrorDialogOpen: false });
                    }}
                >
                    <DialogTitle>
                        {t('Toolbox can not be send out yet')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {toolboxErrorDialogText}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            sx={{color: colors.secondaryColor}}
                            onClick={() => {
                                this.setState({
                                    toolboxErrorDialogOpen: false,
                                });
                            }}
                             
                        >
                            {t('Ok')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <div className={classes.previewView}>
                    <div className={classes.preview}>
                        <Paper elevation={4} className={classes.paper}>
                            {toolbox.title && (
                                <h1 style={{ wordBreak: 'break-word' }}>
                                    {toolbox.title}
                                </h1>
                            )}
                            {toolbox.toolbox.blocks.map((foundBlock) => {
                                const block =
                                    typeof foundBlock === 'object'
                                        ? foundBlock
                                        : contentBlockDrafts[foundBlock];
                                if (isUndefinedOrNull(block)) return null;
                                return (
                                    <BlockPreview
                                        block={block}
                                        key={block.id}
                                        format={this.state.format}
                                    />
                                );
                            })}
                            <h1>{t('Questionnaire')}</h1>
                            {toolbox.toolbox.questions.length === 0 && (
                                <p>{t('The questionnaire is empty')}</p>
                            )}
                            {toolbox.toolbox.questions.map(
                                (foundQuestion, i) => {
                                    const fullQuestion =
                                        typeof foundQuestion === 'object'
                                            ? foundQuestion
                                            : questionBlockDrafts[
                                                  foundQuestion
                                              ];
                                    if (isUndefinedOrNull(fullQuestion))
                                        return null;
                                    return (
                                        <QuestionPreview
                                            contentBlockDrafts={
                                                contentBlockDrafts
                                            }
                                            question={fullQuestion}
                                            key={fullQuestion.id}
                                            index={i}
                                            format={this.state.format}
                                        />
                                    );
                                }
                            )}
                        </Paper>
                    </div>
                    {!(window.location.href.indexOf('details') > -1) && (
                        <Button
                            className={classes.buttonNetjes}
                            onClick={this.changeView}
                            
                            variant="contained"
                            color="primary"
                            style={{
                                position: 'fixed',
                                bottom: '20px',
                                right: '60px',
                            }}
                        >
                            {this.state.format !== 'mobile'
                                ? t('Show mobile view')
                                : t('Show desktop view')}
                        </Button>
                    )}
                    {window.location.href.indexOf('details') > -1 && (
                        <div className={classes.info}>
                            <div className={classes.fixedContainer}>
                                <div className={classes.check}>
                                    <h3 className={classes.myNetjes}>
                                        {toolbox.title}
                                    </h3>

                                    <div className={classes.myNetjes}>
                                        {t('View an example of the toolbox on the left.')}
                                    </div>

                                    {showActionButtons && (
                                        <div>
                                            <h3 className={classes.myNetjes}>
                                                {t('CHECKLIST SEND TOOLBOX')}
                                            </h3>
                                            <div className={classes.myNetjes}>
                                                {t('1. Check the information in the toolbox')}
                                            </div>
                                            <div className={classes.myNetjes}>
                                                {t('2. Check associated questions and answers')}
                                            </div>
                                            <div className={classes.myNetjes}>
                                                {t('3. Select the recipients from the toolbox')}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {toolbox.source === 'cumela' ? (
                                    (this.props.roles.includes('CUMELA') ||
                                        (!this.props.roles.includes('CUMELA') &&
                                            this.props.subscription &&
                                            this.props.subscription.cumela ===
                                                true) ||
                                        (!this.props.roles.includes('CUMELA') &&
                                            this.props.subscription &&
                                            this.props.subscription.cumela ===
                                                false &&
                                            this.props.subscription.caoLeo ===
                                                true)) && (
                                        <div>
                                            {showActionButtons &&
                                                previousHash !==
                                                    'uitgestuurd' &&
                                                previousHash !==
                                                    'ingepland' && (
                                                    <Button
                                                        className={classes.buttonNetjes}
                                                        onClick={
                                                            this.editToolbox
                                                        }
                                                        
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        {t('Toolbox Change')}
                                                    </Button>
                                                )}
                                            {showActionButtons &&
                                                previousHash !==
                                                    'uitgestuurd' &&
                                                previousHash !== 'ingepland' &&
                                                toolbox.publishState ===
                                                    'PUBLISHED' && (
                                                    <Button
                                                        className={classes.buttonNetjes}
                                                        onClick={
                                                            this.sendToolbox
                                                        }
                                                        
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        {t('Sent out toolbox')}
                                                    </Button>
                                                )}
                                            {showActionButtons && (
                                                <Button
                                                    className={classes.buttonNetjes}
                                                    onClick={this.props.onPDFClick}
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    {t('PDF')}
                                                </Button>
                                            )}
                                        </div>
                                    )
                                ) : (
                                    <div>
                                        {showActionButtons &&
                                            previousHash !== 'uitgestuurd' &&
                                            previousHash !== 'ingepland' && (
                                                <Button
                                                    className={classes.buttonNetjes}
                                                    onClick={this.editToolbox}
                                                    
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    {t('Toolbox Change')}
                                                </Button>
                                            )}
                                        {showActionButtons &&
                                            previousHash !== 'uitgestuurd' &&
                                            previousHash !== 'ingepland' &&
                                            toolbox.publishState ===
                                                'PUBLISHED' && (
                                                <Button
                                                    className={classes.buttonNetjes}
                                                    onClick={this.sendToolbox}
                                                    
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    {t('Sent out toolbox')}
                                                </Button>
                                            )}
                                        {showActionButtons && (
                                            <Button
                                                className={classes.buttonNetjes}
                                                onClick={this.props.onPDFClick}
                                                
                                                variant="contained"
                                                color="primary"
                                            >
                                                {t('PDF')}
                                            </Button>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    
    withTranslation()
)(ToolboxPreview);
