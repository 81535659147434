//@flow
import { fetchAll } from './apiActions';
import { removeEntity, setEntities, setEntity } from './entityActions';
import { showSnackbar } from './uiActions';

import * as api from '../lib/api';
import { List } from 'immutable';
import type { PlainDispatch } from 'redux';
import type { TProject } from '../reducers/ProjectsReducer';
import i18n from 'i18next';

export function fetchProjects() {
    return (dispatch: PlainDispatch<TProjectAction>) => {
        return fetchAll('project')(dispatch).then((entities) => {
            dispatch(setEntities('projects', entities));
        });
    };
}

type TPAFetchStart = {
    type: 'FETCH_PROJECTS_REQUEST',
};

type TPAFetchError = {
    type: 'FETCH_PROJECTS_FAILURE',
    text: string,
    timeout: number,
    level: string,
};

type TPAFetchSuccess = {
    type: 'FETCH_PROJECTS_SUCCESS',
    projects: Array<TProject>,
};

type TPAFetchProjectStart = {
    type: 'FETCH_PROJECT_REQUEST',
    id: number,
};

type TPAFetchProjectError = {
    type: 'FETCH_PROJECT_FAILURE',
    text: string,
    timeout: number,
    level: string,
};

type TPAFetchProjectSuccess = {
    type: 'FETCH_PROJECT_SUCCESS',
    project: TProject,
};

type TPAProjectImportStart = {
    type: 'PROJECT_IMPORT_START',
};

type TPAProjectImportError = {
    type: 'PROJECT_IMPORT_ERROR',
    text: string,
    timeout: number,
    level: string,
};

type TPAProjectImportSuccess = {
    type: 'PROJECT_IMPORT_SUCCESS',
    diff: { removed: List<TProject>, added: List<TProject> },
};

type TPAProjectUpdateStart = {
    type: 'PROJECT_UPDATE_START',
};

type TPAProjectUpdateError = {
    type: 'PROJECT_UPDATE_ERROR',
    text: string,
    timeout: number,
    level: string,
};

type TPAProjectUpdateSuccess = {
    type: 'PROJECT_UPDATE_SUCCESS',
    projects: List<TProject>,
};

type TPAEditProject = {
    type: 'PROJECT_EDIT',
    project?: TProject,
};

type TPAUpsertStart = {
    type: 'PROJECT_UPSERT_START',
};

type TPAUpsertError = {
    type: 'PROJECT_UPSERT_ERROR',
    text: string,
    timeout: number,
    level: string,
};

type TPAUpsertSuccess = {
    type: 'PROJECT_UPSERT_SUCCESS',
    project: TProject,
};

type TPAClearDiff = {
    type: 'PROJECT_CLEAR_DIFF',
};

export type TProjectAction =
    | TPAFetchStart
    | TPAFetchError
    | TPAFetchSuccess
    | TPAFetchProjectStart
    | TPAFetchProjectError
    | TPAFetchProjectSuccess
    | TPAEditProject
    | TPAUpsertStart
    | TPAUpsertError
    | TPAUpsertSuccess
    | TPAProjectImportStart
    | TPAProjectImportError
    | TPAProjectImportSuccess
    | TPAProjectUpdateStart
    | TPAProjectUpdateError
    | TPAProjectUpdateSuccess
    | TPAClearDiff;

export function fetchProjectRequest(projectId: number) {
    return {
        type: 'FETCH_PROJECT_REQUEST',
        id: projectId,
    };
}

export function fetchProjectSuccess(json: any) {
    return {
        type: 'FETCH_PROJECT_SUCCESS',
        Project: json._embedded,
    };
}

export function fetchProjectFailure(json: any) {
    return {
        type: 'FETCH_PROJECT_FAILURE',
        text: json,
        timeout: 5000,
        level: 'error',
    };
}

export function fetchProjectsRequest() {
    return {
        type: 'FETCH_PROJECTS_REQUEST',
    };
}

export function fetchProjectsSuccess(json: any) {
    return {
        type: 'FETCH_PROJECTS_SUCCESS',
        Projects: json._embedded.projects,
    };
}

export function fetchProjectsFailure(json: any) {
    return {
        type: 'FETCH_PROJECTS_FAILURE',
        text: json,
        timeout: 5000,
        level: 'error',
    };
}

export function editProject(project?: TProject) {
    return {
        type: 'PROJECT_EDIT',
        project: project,
    };
}

export function upsertProject(project: TProject): Function {
    return (dispatch: PlainDispatch<TProjectAction>) => {
        dispatch({
            type: 'PROJECT_UPSERT_START',
        });

        api.upsert('project', project)
            .then((entity: TProject) => {
                if (entity && entity.omschrijving) {
                    dispatch(
                        showSnackbar(
                            `${i18n.t('Project')} ` +
                                entity.omschrijving +
                                `${i18n.t('is Saved')} .`
                        )
                    );
                    dispatch(setEntity('projects', { ...project, ...entity }));
                }
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Unknown error when saving project')}: ` +
                            error.message
                    )
                );
                throw error;
            });
    };
}

export function deleteProject(rowId) {
    return (dispatch) => {
        api.deleteProject('project', rowId)
            .then((entity) => {
                dispatch(removeEntity('projects', { ...entity }));
                dispatch(showSnackbar(i18n.t('Project is deleted')));
            })
            .catch((error) => {
                dispatch(
                    showSnackbar(
                        `${i18n.t('Can not delete project')}: ` + error.message
                    )
                );
                throw error;
            });
    };
}

export function parseProjectImport(file: any, callback: Function): Function {
    return (dispatch: PlainDispatch<TProjectAction>) => {
        dispatch({
            type: 'PROJECT_IMPORT_START',
        });

        api.parseImport('project', file, function (err, res) {
            if (err) {
                dispatch(showSnackbar('Error processing data: ' + err));
            } else {
                dispatch({
                    type: 'PROJECT_IMPORT_SUCCESS',
                    diff: res.body._embedded.diff,
                });

                if (callback) {
                    callback(err);
                }
            }
        });
    };
}

export function acknowledgeProjectImport(
    file: any,
    callback: Function
): Function {
    return (dispatch: PlainDispatch<TProjectAction>) => {
        dispatch({
            type: 'PROJECT_UPDATE_START',
        });

        api.acknowledgeImport('project', file, function (err, res) {
            if (err) {
                dispatch(showSnackbar('Error processing data: ' + err));
            } else {
                dispatch({
                    type: 'PROJECT_UPDATE_SUCCESS',
                    projects: res.body._embedded.projects,
                });
                dispatch(fetchProjects());
                dispatch(
                    showSnackbar(
                        (res.body._embedded.projects.length !== 0
                            ? res.body._embedded.projects.length
                            : '') +
                            ' ' +
                            i18n.t('Projects changed')
                    )
                );

                if (callback) {
                    callback(err);
                }
            }
        });
    };
}

export function clearProjectDiff(): TProjectAction {
    return {
        type: 'PROJECT_CLEAR_DIFF',
    };
}
