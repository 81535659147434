// @flow
import React from 'react';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import FilterHeader from '../../FilterHeader';
import $ from 'jquery';
import 'materialize-tabs';
import { connect } from 'react-redux';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { getCurrentFilterSet } from '../../../reducers/filterReducer';
import {
    filterPeriodType,
    filterPeriodYear,
    filterPeriodMonth,
    filterPeriodWeek,
    filterPeriodFrom,
    filterPeriodTill,
} from '../../../actions';
import type { StoreState } from '../../../reducers/index';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import 'moment/min/locales';
import i18n from 'i18next';
import classes from "./PeriodFilterBlock.module.scss"
import nl from "date-fns/locale/nl";
import en from "date-fns/locale/en-US";
moment.locale(i18n.t('LOCALE'));

type Props = {
    label: string,
    periodType: string,
    periodYear: number,
    periodMonth: number,
    periodWeek: number,
    periodFrom: Date,
    periodTill: Date,
    onChange: (periodType: string, period: string) => mixed,
    filterPeriodType: Function,
    filterPeriodYear: Function,
    filterPeriodMonth: Function,
    filterPeriodWeek: Function,
    filterPeriodFrom: Function,
    filterPeriodTill: Function,
};

type State = {
    open: boolean,
    anchorEl?: any,
};


const mapStateToProps = (state: StoreState, ownProps: Props) => {
    const filters = getCurrentFilterSet(state.filters);

    return {
        periodType: filters.periodType,
        periodYear: filters.periodYear,
        periodMonth: filters.periodMonth,
        periodWeek: filters.periodWeek,
        periodFrom: filters.periodFrom,
        periodTill: filters.periodTill,
        label: i18n.t('Periode'),
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        filterPeriodType: bindActionCreators(filterPeriodType, dispatch),
        filterPeriodYear: bindActionCreators(filterPeriodYear, dispatch),
        filterPeriodMonth: bindActionCreators(filterPeriodMonth, dispatch),
        filterPeriodWeek: bindActionCreators(filterPeriodWeek, dispatch),
        filterPeriodFrom: bindActionCreators(filterPeriodFrom, dispatch),
        filterPeriodTill: bindActionCreators(filterPeriodTill, dispatch),
    };
};

const chevronStyle = {
    verticalAlign: 'middle',
    fontSize: '24px',
    cursor: 'pointer',
    userSelect: 'none',
};

const chevronStyleDisabled = {
    verticalAlign: 'middle',
    fontSize: '24px',
    cursor: 'pointer',
    userSelect: 'none',
    color: '#BBB',
};

class PeriodFilterBlock extends React.Component<Props, State> {
    state = {
        open: false,
        anchorEl: undefined,
    };

    formatFromDate = () => {
        return moment(this.props.periodFrom).format('LL');
    };

    formatTillDate = () => {
        return moment(this.props.periodTill).format('LL');
    };

    handleFromDateChange = (date) => {
        this.props.filterPeriodFrom(date);
    };

    handleTillDateChange = (date) => {
        this.props.filterPeriodTill(date);
    };

    handleChevron = (event: Events) => {
        if (event.target.id === 'right') {
            if (this.props.periodType === 'year') {
                let newYear = this.props.periodYear + 1;

                if (newYear <= moment().get('year'))
                    this.props.filterPeriodYear(this.props.periodYear + 1);

                if (newYear >= new Date().getFullYear())
                    document.getElementById('right').style.color = '#BBB';

                document.getElementById('left').style.color = '#000';
            } else if (this.props.periodType === 'month') {
                let newMonth = this.props.periodMonth + 1;

                if (
                    this.props.periodYear === new Date().getFullYear()
                        ? this.props.periodMonth < new Date().getMonth()
                        : newMonth < 12
                )
                    this.props.filterPeriodMonth(this.props.periodMonth + 1);

                if (
                    this.props.periodYear >= new Date().getFullYear()
                        ? newMonth >= new Date().getMonth()
                        : newMonth >= 11
                )
                    document.getElementById('right').style.color = '#BBB';

                document.getElementById('left').style.color = '#000';
            } else if (this.props.periodType === 'week') {
                let newWeek = this.props.periodWeek + 1;

                if (
                    this.props.periodYear === new Date().getFullYear()
                        ? this.props.periodWeek < moment().week()
                        : newWeek <= 52
                )
                    this.props.filterPeriodWeek(this.props.periodWeek + 1);

                if (
                    this.props.periodYear >= new Date().getFullYear()
                        ? newWeek >= moment().week()
                        : newWeek >= 52
                )
                    document.getElementById('right').style.color = '#BBB';

                document.getElementById('left').style.color = '#000';
            } else {
                document.getElementById('left').style.color = '#BBB';
            }
        } else {
            if (this.props.periodType === 'year') {
                if (this.props.periodYear > 2017)
                    this.props.filterPeriodYear(this.props.periodYear - 1);

                if (this.props.periodYear <= 2018)
                    document.getElementById('left').style.color = '#BBB';

                document.getElementById('right').style.color = '#000';
            } else if (this.props.periodType === 'month') {
                let newMonth = this.props.periodMonth - 1;

                if (newMonth >= 0)
                    this.props.filterPeriodMonth(this.props.periodMonth - 1);

                if (newMonth === 0)
                    document.getElementById('left').style.color = '#BBB';

                if (newMonth < new Date().getMonth())
                    document.getElementById('right').style.color = '#000';
            } else if (this.props.periodType === 'week') {
                let newWeek = this.props.periodWeek - 1;

                if (newWeek >= 1)
                    this.props.filterPeriodWeek(this.props.periodWeek - 1);

                if (newWeek <= 1)
                    document.getElementById('left').style.color = '#BBB';

                if (newWeek < moment().week())
                    document.getElementById('right').style.color = '#000';
            } else {
                document.getElementById('left').style.color = '#BBB';
            }
        }
    };

    handleTouchTap = (event: Event) => {
        // This prevents ghost click.
        event.preventDefault();

        const newState = Object.assign({}, this.state, {
            open: true,
            anchorEl: event.currentTarget,
        });

        this.setState(newState);
        setTimeout(() => {
            $('ul.tabs').tabs();
        }, 100);
    };

    getFilterHeaderValue() {
        switch (this.props.periodType) {
            case 'year':
                return this.props.periodYear;
            case 'month':
                return moment()
                    .year(this.props.periodYear)
                    .month(this.props.periodMonth)
                    .format('MMM YYYY');
            case 'week':
                return (
                    'Week ' +
                    this.props.periodWeek +
                    ' (' +
                    this.props.periodYear +
                    ')'
                );
            case 'fromtill':
                return (
                    moment(this.props.periodFrom).format('LL') +
                    ' t/m ' +
                    moment(this.props.periodTill).format('LL')
                );
            default:
                return 'Not set';
        }
    }

    componentDidMount() {
        if (!this.props.periodFrom) {
            this.handleFromDateChange(
                null,
                new Date(moment().subtract(6, 'days'))
            );
        }
        if (!this.props.periodTill) {
            this.handleTillDateChange(null, new Date());
        }
    }

    handleRequestClose = () => {
        const newState = Object.assign({}, this.state, { open: false });
        this.setState(newState);
    };

    onTabClicked = (event: any) => {
        this.props.filterPeriodType(event.target.value);
    };

    onYearChanged = (event: any) => {
        this.props.filterPeriodYear(event.target.value);

        if (event.target.value >= new Date().getFullYear())
            document.getElementById('right').style.color = '#BBB';
        else document.getElementById('right').style.color = '#000';
    };

    onMonthChanged = (event: any) => {
        this.props.filterPeriodMonth(event.target.value);

        if (event.target.value >= new Date().getMonth())
            document.getElementById('right').style.color = '#BBB';
        else document.getElementById('right').style.color = '#000';
    };

    onWeekChanged = (event: any) => {
        this.props.filterPeriodWeek(event.target.value);

        if (event.target.value >= moment().week())
            document.getElementById('right').style.color = '#BBB';
        else document.getElementById('right').style.color = '#000';
    };

    getYearOptions = () => {
        let options = [];
        for (let year = moment().year(); year >= 2017; year--) {
            options.push(
                <MenuItem key={year} value={year}>
                    <ListItemText primary={year} />
                </MenuItem>
            );
        }
        return options;
    };

    getMonthOptions = () => {
        let options = [];
        for (
            let month = 0;
            month <
            (this.props.periodYear === new Date().getFullYear()
                ? new Date().getMonth() + 1
                : 12);
            month++
        ) {
            options.push(
                <MenuItem key={month} value={month}>
                    <ListItemText
                        primary={moment().month(month).format('MMMM')}
                    />
                </MenuItem>
            );
        }
        return options;
    };

    getWeekOptions = () => {
        let options = [];
        if (!this.props.periodYear) {
            return options;
        }
        let numberOfWeeks = moment()
            .year(this.props.periodYear)
            .isoWeeksInYear();
        let locale: string =
            window.navigator.userLanguage || window.navigator.language;

        for (
            let week = 1;
            week <=
            (this.props.periodYear === new Date().getFullYear()
                ? moment().week()
                : numberOfWeeks);
            week++
        ) {
            let begin = moment()
                .locale(locale)
                .year(this.props.periodYear)
                .weeks(week)
                .weekday(0);
            let end = moment()
                .locale(locale)
                .year(this.props.periodYear)
                .weeks(week)
                .weekday(6);
            options.push(
                <MenuItem key={week} value={week}>
                    <ListItemText
                        primary={
                            'Week ' +
                            week +
                            ' (' +
                            begin.format('D/M') +
                            ' - ' +
                            end.format('D/M') +
                            ')'
                        }
                    />{' '}
                </MenuItem>
            );
        }
        return options;
    };

    render() {
        const { t } = this.props;

        if (!this.props.periodFrom) {
            this.handleFromDateChange(
                null,
                new Date(moment().subtract(6, 'days'))
            );
        }
        if (!this.props.periodTill) {
            this.handleTillDateChange(null, new Date());
        }
        let myLocale = nl;
        if (t('LOCALE') === 'en') {
            myLocale = en;
        }


        return (
            <div>
                <div>
                    <i
                        className="material-icons"
                        id={'left'}
                        style={
                            this.props.periodType === 'fromtill'
                                ? chevronStyleDisabled
                                : chevronStyle
                        }
                        onClick={this.handleChevron}
                    >
                        chevron_left
                    </i>
                    <span onClick={this.handleTouchTap}>
                        <FilterHeader
                            filterTitle={t('Period')}
                            filterValue={this.getFilterHeaderValue()}
                        />
                    </span>
                    <i
                        className="material-icons"
                        id={'right'}
                        style={
                            this.props.periodType === 'fromtill'
                                ? { ...chevronStyleDisabled }
                                : { ...chevronStyle, color: '#BBB' }
                        }
                        onClick={this.handleChevron}
                    >
                        chevron_right
                    </i>
                </div>
                <Popover
                    open={this.state.open}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleRequestClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                >
                    <div className={classes.popoverClass}>
                    <div className="row">
                        <div className="col-md-12" style={{margin: "12px"}}>
                            <Select
                                variant='standard'
                                value={this.props.periodType}
                                onChange={this.onTabClicked}
                                className={classes.selects}
                            >
                                <MenuItem key="year" value="year">
                                    <ListItemText
                                        primary={t('Annual review')}
                                    />
                                </MenuItem>
                                <MenuItem key="month" value="month">
                                    <ListItemText
                                        primary={t('Monthly overview')}
                                    />
                                </MenuItem>
                                <MenuItem key="week" value="week">
                                    <ListItemText
                                        primary={t('Week overview')}
                                    />
                                </MenuItem>
                                <MenuItem key="fromtill" value="fromtill">
                                    <ListItemText
                                        primary={t('From/till overview')}
                                    />
                                </MenuItem>
                            </Select>
                        </div>
                    </div>
                    <div className="row">
                        {this.props.periodType !== 'fromtill' && (
                            <div className="col-md-4" style={{margin: '12px'}}>
                                <Select
                                    variant='standard'
                                    value={this.props.periodYear}
                                    onChange={this.onYearChanged}
                                    className={classes.selects}
                                >
                                    {this.getYearOptions()}
                                </Select>
                            </div>
                        )}
                        {this.props.periodType === 'month' && (
                            <div className="col-md-4" style={{margin: "12px"}}>
                                <Select
                                    variant='standard'
                                    value={this.props.periodMonth}
                                    onChange={this.onMonthChanged}
                                    disabled={!this.props.periodYear}
                                >
                                    {this.getMonthOptions()}
                                </Select>
                            </div>
                        )}
                        {this.props.periodType === 'week' && (
                            <div className="col-md-8" style={{margin: "12px"}}>
                                <Select
                                    value={this.props.periodWeek}
                                    onChange={this.onWeekChanged}
                                    disabled={!this.props.periodYear}
                                    variant='standard'
                                    className={classes.selects}
                                >
                                    {this.getWeekOptions()}
                                </Select>
                            </div>
                        )}
                        {this.props.periodType === 'fromtill' && (
                            <div className="col-md-8" style={{margin: "12px"}}>
                                <p>{t('Date from')}:</p>
                                <FormControl>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={myLocale} >
                                        <DatePicker
                                            id="PeriodFrom"
                                            label={t('Date from')}
                                            okLabel={t('Ok')}
                                            cancelLabel={t('Cancel')}
                                            locale={t('LOCALE')}
                                            onChange={this.handleFromDateChange}
                                            inputFormat={t('DateFormat')}
                                            maxDate={this.props.periodTill}
                                            value={this.props.periodFrom}
                                        />

                                    </LocalizationProvider>
                                </FormControl>
                                <p>{t('Date till')}:</p>
                                <FormControl>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={myLocale} >
                                        <DatePicker
                                            id="PeriodTill"
                                            label={t('Date till')}
                                            okLabel={t('Ok')}
                                            cancelLabel={t('Cancel')}
                                            locale={t('LOCALE')}
                                            onChange={this.handleTillDateChange}
                                            inputFormat={t('DateFormat')}
                                            maxDate={new Date()}
                                            minDate={this.props.periodFrom}
                                            value={this.props.periodTill}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </div>
                        )}
                    </div>
                    </div>
                </Popover>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(PeriodFilterBlock);
