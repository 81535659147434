// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import {Fab} from '@mui/material';


import {deleteToolboxAssigns, setCrumbPath} from '../../actions';
import AppModule from '../AppModule';
import PaginationTable from '../PaginationTable';
import moment from '../../lib/moment';
import {getPermissions,} from '../../selectors';
import './ToolboxAssignedOverview.css';
import TableActionHeader from '../TableActionHeader/TableActionHeader';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Menu,
    MenuItem,
} from '@mui/material';
import {sendToolboxReminder,} from '../../actions/toolboxActions';
import classnames from 'classnames';
import DeployIcon from '@mui/icons-material/Send';
import {openDialog, showSnackbar} from '../../actions/uiActions';
import $ from 'jquery';
import {getToolboxAssignSizeWithFilter, fetchAssignedToolboxesOverviewWithParams} from '../../lib/api';
import FilterHeader from '../FilterHeader';
import {percentColors3} from '../../lib/utils';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';
import {withRouter} from "react-router";
import i18n from "i18next";
import {toolboxAssignOverviewInProgress} from "../../selectors/inProgress";
import classes from './ToolboxAssignedOverview.module.scss';

type Props = {
    loading: boolean,
    deployments: [any],
    sendReminder: (toolboxId: String, deploymentId: number) => void,
    setCrumbPath: () => void,
    goToDetailView: (toolboxId: string, deployedDate: Date) => mixed,
    deleteToolboxAssigns: (toolboxId: string, deploymentId: number) => void
};

type State = {
    rows: Array<any>,
    page: number,
    rowsPerPage: number,
    searchtext: string,
    actions: Array<{
        id: string,
        label: string,
        isVisible?: (id: any) => boolean,
    }>,
    showSendReminderDialog: boolean,
    showDeleteDialog: boolean,
    toolboxToDelete: any,
    deploymentToDelete: Array<any>,
    amountOfUsersForReminder: number,
    deploymentReminder: any,
    typeFilter: any,
    openSector: boolean,
    sectorFilter: any,
    openCategory: boolean,
    categoryFilter: any,
    searchSize: Number,
    totalSize: Number,
    typeFilterText: String,
    categoryFilterText: String,
    index: Number,
};

const mapStateToProps = (store) => {

    const sectors = store.entities.sectors;
    const categories = store.entities.categories;

    return {
        loading: toolboxAssignOverviewInProgress(store),
        permissions: getPermissions(store),
        sectors: sectors.allIds.map((id) => sectors.byId[id]),
        categories: categories.allIds.map((id) => categories.byId[id]),
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        setCrumbPath: () => {
            dispatch(setCrumbPath({ title: 'Toolboxen' }));
        },
        goToDetailView: (toolboxId, rowId, page, rowsPerPage, searchString, typeFilterText, categoryFilterText) => {
            dispatch(
                push(
                    `/toolbox/details/${toolboxId}/${rowId}#resultaten?page=${page}&rowsPerPage=${rowsPerPage}&searchString=${searchString}&typeFilter=${typeFilterText}&categoryFilter=${categoryFilterText}`
                )
            );
        },
        sendReminder: (toolboxId, deploymentId) => {
            dispatch(sendToolboxReminder(toolboxId, deploymentId));
        },
        openDialog: (dialogName, data) => {
            dispatch(openDialog(dialogName, data));
        },
        deleteToolboxAssigns: (toolboxId: string, deploymentId: number) => {
            dispatch(deleteToolboxAssigns(toolboxId, deploymentId));
        },
    };
};


let categories = [];

class ToolboxAssignedOverview extends React.Component<Props, State> {
    props: Props;
    state: State = {
        page: 0,
        rowsPerPage: 10,
        searchtext: '',
        actions: [
            {
                id: 'reminder',
                label: 'Send reminder',
                isVisible: (id) => this.isReminderOptionVisible(id),
            },
            { id: 'delete', label: 'Delete result', isVisible: true },
        ],
        columns: [
            { id: 'deployed', label: 'Laatst uitgestuurd', size: '15%' },
            { id: 'logo', label: '', size: '48px' },
            { id: 'toolboxName', label: 'Titel', size: '35%' },
            { id: 'version', label: 'Versie', size: '10%' },
            { id: 'toolboxMeeting', label: 'Toolboxmeeting', size: '15%' },
            { id: 'employees', label: '# Medewerkers', size: '15%' },
            { id: 'finished', label: '# Afgerond', size: '10%' },
        ],
        showSendReminderDialog: false,
        showDeleteDialog: false,
        typeFilter: 0,
        openSector: false,
        sectorFilter: 0,
        openCategory: false,
        categoryFilter: 0,
        totalSize: 0,
        searchSize: 0,
        orderBy: "deployed",
        orderDirection: "desc",
        typeFilterText: "",
        categoryFilterText: "",
        index: 0,
    };

    isReminderOptionVisible = (rowId) => {
        // Todo: Only show when there are internal employees assigned and not only external, (but first find out if that's really necessary)
        const deployment = this.state.rows.find((deployment) => deployment.id === rowId)

        if (deployment) {
            return deployment.finished !== deployment.employees;
        }
        return true;
    };

    showSendReminderDialog = () => {
        this.setState({
            showSendReminderDialog: true,
        });
    };

    showDeleteDialog = () => {
        this.setState({
            showDeleteDialog: true,
        });
    };

    mapToolboxAssignToRow = (deployment) => {
        const { t } = this.props;
        moment.locale(i18n.t('LOCALE'));

        let tooltip;
        if (deployment.finished === 1 && deployment.employees === 1) {
            tooltip = t('The employee has completed the toolbox');
        } else if (deployment.finished === 0 && deployment.employees === 1) {
            tooltip = t('The employee didnt complete the toolbox');
        } else {
            tooltip =
                deployment.finished +
                ' van de ' +
                deployment.employees +
                ' medewerkers heeft de toolbox afgerond.';
        }

        let toolboxMeetingToolTipLocation = '';
        let toolboxMeetingToolTipDate = '';
        if (deployment.toolboxMeeting === true) {
            toolboxMeetingToolTipLocation = deployment.toolboxMeetingLocation;
            toolboxMeetingToolTipDate = moment(deployment.toolboxMeetingDate).format('L HH:mm');
        }

        return {
            ...deployment,
            toolboxName: deployment.toolbox.title,
            version: deployment.toolbox.version,
            deployed: !(deployment.deployed || deployment.scheduled) ? '-' : moment(deployment.deployed || deployment.scheduled).format('L HH:mm'),
            deployDate: moment(deployment.deployed || deployment.scheduled).format('L HH:mm'),
            toolboxMeeting: deployment.toolboxMeeting === true ? (
                    <div className={'tooltip'}>
                        <span className={'tooltiptext'} style={{ height: '20px' }}><div>{toolboxMeetingToolTipLocation}</div><div>{toolboxMeetingToolTipDate}</div></span>
                        Ja
                    </div>
                ) : (
                    <div>Nee</div>
            ),
            logo:
                deployment.toolbox.source === 'cumela' ? (
                    <div className={'tooltip'}>
                        <span
                            className={'tooltiptext'}
                            style={{ left: '30px' }}
                        >
                            Cumela toolbox
                        </span>
                        <img
                            height="20"
                            width="20"
                            src="/assets/images/cumela-logo.png"
                            alt="Cumela logo"
                        />
                    </div>
                ) : deployment.toolbox.source === 'global' ? (
                    <div className={'tooltip'}>
                        <span
                            className={'tooltiptext'}
                            style={{ left: '10px' }}
                        >
                            Veilig Vakwerk toolbox
                        </span>
                        <img
                            height="20"
                            width="20"
                            src="/assets/images/vvw-admin-logo.svg"
                            alt="Admin logo"
                        />
                    </div>
                ) : deployment.toolbox.rootboxSource === 'global' ||
                  deployment.toolbox.rootboxSource === 'cumela' ? (
                    <div className={'tooltip'}>
                        <span
                            className={'tooltiptext'}
                            style={{ left: '15px' }}
                        >
                            Aangepaste toolbox
                        </span>
                        <img
                            height="20"
                            width="20"
                            src="/assets/images/aangepast.svg"
                            alt="Aangepast logo"
                        />
                    </div>
                ) : (
                    <div className={'tooltip'}>
                        <span
                            className={'tooltiptext'}
                            style={{ left: '30px' }}
                        >
                            Eigen toolbox
                        </span>
                        <img
                            height="20"
                            width="20"
                            src="/assets/images/vvw-logo.svg"
                            alt="Eigen logo"
                        />
                    </div>
                ),
            finished: (
                <div className={'tooltip'}>
                    <span className={'tooltiptext'}>{tooltip}</span>
                    <div
                        className={'border-text'}
                        style={{
                            backgroundColor: getColorForPercentage(
                                deployment.finished / deployment.employees
                            ),
                            borderColor: getColorForPercentage(
                                deployment.finished / deployment.employees
                            ),
                            width: '50px',
                        }}
                    >
                        {deployment.finished + '/' + deployment.employees}
                    </div>
                </div>
            ),
            score: deployment.finished + '/' + deployment.employees,
            showMenu: true,
        };
    };

    onRowClick = (row) => {
        if (row.toolbox.id && row.deployed) {
            this.props.goToDetailView(row.toolbox.id, row.id, this.state.page, this.state.rowsPerPage, this.state.searchtext, this.state.typeFilterText, this.state.categoryFilterText);
        }
    };

    componentDidMount() {
        const searchString = '?' + this.props.location.hash.split("?")[1]
        const searchStringWithValues = new URLSearchParams(searchString)

        let index;
        let startRowsPerPage = this.state.rowsPerPage;
        let startPage = this.state.page;
        let searchtext = this.state.searchtext
        let typeFilterText = this.state.typeFilterText
        let typeFilter = this.state.typeFilter
        let categoryFilterText = this.state.categoryFilterText
        let categoryFilter = this.state.categoryFilter


        if (searchStringWithValues.has("rowsPerPage") && this.state.index === 0) {
            startRowsPerPage = searchStringWithValues.get("rowsPerPage");
        }
        if(searchStringWithValues.has("page") && this.state.index === 0){
            startPage = parseInt(searchStringWithValues.get("page"), 10);
        }
        if(searchStringWithValues.has("searchString") && this.state.index === 0){
            searchtext = searchStringWithValues.get("searchString")
        }
        if(searchStringWithValues.has("typeFilter") && this.state.index === 0){
            typeFilterText = searchStringWithValues.get("typeFilter")
            typeFilter = this.typeFilterTextToTypeFilter(typeFilterText)
        }
        if(searchStringWithValues.has("categoryFilter") && this.state.index === 0){
            categoryFilterText = searchStringWithValues.get("categoryFilter")
            categoryFilter = categoryFilterText
        }
        index++


        this.setState({
            index: index,
            rowsPerPage : startRowsPerPage,
            searchtext: searchtext,
            typeFilterText: typeFilterText,
            typeFilter: typeFilter,
            categoryFilterText: categoryFilterText,
            categoryFilter : categoryFilter,
            page: startPage
        },() =>{
            this.toolboxesAssigendData()
            this.getToolboxSizeWithFilters()
        })

        this.props.setCrumbPath();

        this.setState({
            actions: [
                {
                    id: 'reminder',
                    label: 'Send reminder',
                    isVisible: (id) => this.isReminderOptionVisible(id),
                },
                { id: 'delete', label: 'Delete result', isVisible: true },
            ],
            columns: [
                { id: 'deployed', label: 'Last sent out', size: '15%' },
                { id: 'logo', label: '', size: '48px' },
                { id: 'toolboxName', label: 'Title', size: '35%' },
                { id: 'version', label: 'Version', size: '10%' },
                {
                    id: 'toolboxMeeting',
                    label: 'Toolboxmeeting',
                    size: '15%',
                },
                { id: 'employees', label: '#Employees', size: '15%' },
                { id: 'finished', label: '#Completed', size: '10%' },
            ],
        });
    }
    componentDidUpdate = (prevProps, prevState) => {

        if(prevState.page !== this.state.page || prevState.rowsPerPage !== this.state.rowsPerPage || prevState.orderBy !== this.state.orderBy || prevState.orderDirection !== this.state.orderDirection || prevState.searchtext !== this.state.searchtext || prevState.typeFilterText !== this.state.typeFilterText || prevState.categoryFilterText !== this.state.categoryFilterText){
            this.toolboxesAssigendData()
        }

        if(prevState.searchtext !== this.state.searchtext || prevState.typeFilterText !== this.state.typeFilterText || prevState.categoryFilterText !== this.state.categoryFilterText)
            this.getToolboxSizeWithFilters()
      }

    setPage = (page) => {
        console.log("INFO: ToolboxOverview page = " + page);
        // Store page in state
        this.setState({ page: page });
    }
    setRowsPerPage = (rowsPerPage) => {
        console.log("INFO: ToolboxOverview rowsPerPage = " + rowsPerPage);
        // Store rowsPerPage in state
        this.setState({ 
            rowsPerPage: rowsPerPage,
            page: 0
         });
    }

    handleAction = (event, action) => {
        if (action.id === 'reminder') {
            const deployment = this.state.rows.find((deployment) => deployment.id === action.rowId)
            this.setState({
                deploymentReminder: deployment,
                amountOfUsersForReminder: deployment.employees - deployment.finished,
            });
            this.showSendReminderDialog();
        }
        if (action.id === 'delete') {
            const deployment = this.state.rows.find((deployment) => deployment.id === action.rowId)
            this.setState({
                deploymentToDelete: deployment
            })
            this.showDeleteDialog();
        }
    };

    handleSorting = (orderBy) => {
        if(this.state.orderBy === orderBy){
            if(this.state.orderDirection === "desc"){
                this.setState({ orderDirection : "asc"})
            }else{
                this.setState({ orderDirection : "desc"})
            }
        }else{
            this.setState( {
                orderBy: orderBy
            })
        }
    }

    componentWillUnmount() {}

    handleDeployButtonClick = () => {
        this.props.openDialog('toolboxDeploy', { initialScheduled: false });
    };

    handleSearch = (searchtext) => {
        this.setState({
            searchtext: searchtext,
            page: 0,

        });
    };

    toolboxesAssigendData = () => {
        fetchAssignedToolboxesOverviewWithParams(this.state.searchtext, this.state.page + 1, this.state.rowsPerPage, this.state.orderBy, this.state.orderDirection, this.state.typeFilterText, this.state.categoryFilterText)
        .then((res) => {
            this.setState({
                rows : res.body._embedded.toolboxAssigns
            })
        }).catch((error) => {
        showSnackbar(
            `${i18n.t(
                'Unknown error while fetching '
            )}: ` + error.message
        )
        throw error;
        });
    }

    handleRequestClose = () => {
        const newState = Object.assign({}, this.state, {
            openType: false,
            openSector: false,
            openCategory: false,
        });
        this.setState(newState);
    };

    handleTypeFilterChange = (event) => {
        this.handleRequestClose();
        let filter = event.target.value;
        let filterText = "";

        if(filter === 0){
            filterText = ""
        }else if(filter === 1){
            filterText = "global"
        }else if(filter === 2) {
            filterText = "cumela"
        }else if(filter === 4){
            filterText = "Own"
        }

        this.setState({
            typeFilterText : filterText,
            typeFilter : filter,
            page : 0
            })
    };

    handleTouchTap2 = (event: Event) => {
        // This prevents ghost click.
        event.preventDefault();

        const newState = Object.assign({}, this.state, {
            openSector: true,
            anchorEl: event.currentTarget,
        });

        this.setState(newState);
        setTimeout(() => {
            $('ul.tabs').tabs();
        }, 100);
    };

    getToolboxSizeWithFilters(){
        getToolboxAssignSizeWithFilter(this.state.searchtext,this.state.typeFilterText,this.state.categoryFilterText)
        .then((res) => {
            this.setState({
                searchSize: res.body._embedded.toolboxAssigns
            });
        }).catch((error) => {
        showSnackbar(
            `${i18n.t(
                'Unknown error while fetching'
            )}: ` + error.message
        )
        throw error;
        });

    }

    typeFilterTextToTypeFilter(typeFilterText: String){
        if(typeFilterText !==""){
            if(typeFilterText === "global"){
                return 1;
            }
            if(typeFilterText === "cumela"){
                return 2;
            }
            if(typeFilterText === "Own"){
                return 4;
            }
        }else {
            return 0;
        }
    }

    handleTouchTap0 = (event: Event) => {
        // This prevents ghost click.
        event.preventDefault();

        const newState = Object.assign({}, this.state, {
            openType: true,
            anchorEl: event.currentTarget,
        });

        this.setState(newState);
        setTimeout(() => {
            $('ul.tabs').tabs();
        }, 100);
    };

    handleToolboxCategoryFilterChange = (event) => {
        this.handleRequestClose();
                
        let cat
        if (event.target.value === -1) {
            this.setState({ categoryFilter: "Alles" })
            this.setState({ categoryFilterText: ""});
        } else {
            cat = categories[event.target.value].id;
            this.setState({ categoryFilter: cat })
            this.setState({ categoryFilterText: cat});
        }

        this.setState({
            page: 0
        })
    };

    getFilterCategoryValue(catfilter) {
        const { t } = this.props;
        let filteredCat = t('Everything');
        if (catfilter === 'all') {
            return filteredCat;
        }

        this.props.categories.forEach((cat) => {
            if (cat.id === catfilter) {
                filteredCat = cat.name;
            }
        });

        return filteredCat;
    }

    handleTouchTap3 = (event: Event) => {
        // This prevents ghost click.
        event.preventDefault();

        const newState = Object.assign({}, this.state, {
            openCategory: true,
            anchorEl: event.currentTarget,
        });

        this.setState(newState);
        setTimeout(() => {
            $('ul.tabs').tabs();
        }, 100);
    };

    getFilterBar = (total, searchresults) => {
        const { t } = this.props;
        categories = this.props.categories;

        let vvw = true;
        let cumela = true;
        let aangepast = false;
        let eigen = true;

        let catIndex = 0;

        return (
            <div className="filterbar">
                <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                    {total +
                        ' ' +
                        t('Toolbox') +
                        (total === 1 ? '' : t('and')) +
                        ' ' +
                        t('Sent out') +
                        (this.state.searchtext.length > 0
                            ? (searchresults.length === 1
                                  ? ' - ' +
                                    searchresults.length +
                                    t('searchresult')
                                  : '') +
                              (searchresults.length > 1 ||
                              searchresults.length === 0
                                  ? ' - ' +
                                    searchresults.length +
                                    t('searchresults')
                                  : '')
                            : '')}
                </div>
                <div style={{ marginLeft: '20px' }}>
                    <span onClick={this.handleTouchTap0}>
                        <FilterHeader
                            filterTitle={'Type'}
                            filterValue={
                                this.state.typeFilter === 0
                                    ? t('Everything')
                                    : this.state.typeFilter === 1
                                    ? t('Veilig Vakwerk')
                                    : this.state.typeFilter === 2
                                    ? t('CUMELA')
                                    : this.state.typeFilter === 3
                                    ? t('Edited')
                                    : t('Own')
                            }
                        />
                    </span>
                </div>

                <Menu
                    open={this.state.openType ?? false}
                    onClose={this.handleRequestClose}
                    value={this.state.typeFilter}
                    anchorEl={this.state ? this.state.anchorEl : 0}
                >
                    <MenuItem onClick={this.handleTypeFilterChange} value={0}>
                        {t('Everything')}
                    </MenuItem>
                    {vvw && (
                        <MenuItem
                            onClick={this.handleTypeFilterChange}
                            value={1}
                        >
                            {t('Veilig vakwerk')}
                        </MenuItem>
                    )}
                    {cumela && (
                        <MenuItem
                            onClick={this.handleTypeFilterChange}
                            value={2}
                        >
                            {t('CUMELA')}
                        </MenuItem>
                    )}
                    {aangepast && (
                        <MenuItem
                            onClick={this.handleTypeFilterChange}
                            value={3}
                        >
                            {t('Edited')}
                        </MenuItem>
                    )}
                    {eigen && (
                        <MenuItem
                            onClick={this.handleTypeFilterChange}
                            value={4}
                        >
                            {t('Own')}
                        </MenuItem>
                    )}
                </Menu>

                <div>
                    <span onClick={this.handleTouchTap3}>
                        <FilterHeader
                            filterTitle={'Categorie'}
                            filterValue={this.getFilterCategoryValue(
                                this.state.categoryFilter
                            )}
                        />
                    </span>
                </div>

                <Menu
                    open={this.state.openCategory ?? false}
                    onClose={this.handleRequestClose}
                    value={this.state.categoryFilter}
                    onChange={this.handleToolboxCategoryFilterChange}
                    anchorEl={this.state ? this.state.anchorEl : 0}
                >
                    <MenuItem
                        onClick={this.handleToolboxCategoryFilterChange}
                        value={-1}
                    >
                        {t('Everything')}
                    </MenuItem>
                    {categories.map((cat) => {
                        return (
                            <MenuItem
                                key={cat.id}
                                onClick={this.handleToolboxCategoryFilterChange}
                                value={catIndex++}
                            >
                                {t(cat.locKey || cat.name)}
                            </MenuItem>
                        );
                    })}
                </Menu>
            </div>
        );
    };

    render() {
        const { loading, t } = this.props;
        const { columns, actions, showDeleteDialog } = this.state;

        const tableActionHeader = (
            <TableActionHeader
                searchPlaceholder={t('Search within toolboxes')}
                onSearchChange={this.handleSearch}
                title={this.getFilterBar(this.state.searchSize, this.state.searchSize,this.state.rows)}
                widemode={true}
            />
        );
        return (
            <div>
                <Dialog
                    onClose={() => {
                        this.setState({ showDeleteDialog: false });
                    }}
                    open={showDeleteDialog}
                >
                    <DialogTitle>{t('Delete')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t('Sure to Delete result?')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                this.setState({ showDeleteDialog: false });
                            }}
                        >
                            {t('No')}
                        </Button>
                        <Button onClick={() => {
                            const deployment = this.state.deploymentToDelete
                            this.props.deleteToolboxAssigns(deployment.toolboxId, deployment.id);
                            this.setState({
                                showDeleteDialog: false,
                            });
                        }}>{t('Yes')
                        }</Button>
                    </DialogActions>
                </Dialog>

                {/* Send reminder dialog */}
                <Dialog
                    open={this.state.showSendReminderDialog}
                    onClose={() => {
                        this.setState({showSendReminderDialog: false});
                    }}
                >
                    <DialogTitle>
                        {t("Send reminder?")}
                    </DialogTitle>
                    {this.state.amountOfUsersForReminder > 1 ? (
                        <DialogContent>
                            {t('A number of')} {this.state.amountOfUsersForReminder}{' '}
                            {t('staff users will get a reminder, are u sure?')}
                        </DialogContent>
                    ) : (
                        <DialogContent>
                            {t('A number of')} {this.state.amountOfUsersForReminder}{' '}
                            {t('staff user will get a reminder, are u sure?')}
                        </DialogContent>
                    )}
                    <DialogActions>
                        <Button
                            onClick={() => {
                                this.setState({
                                    showSendReminderDialog: false,
                                });
                            }}
                             >
                            {t('Cancel')}
                        </Button>
                        <Button
                            onClick={() => {
                                this.props.sendReminder(this.state.deploymentReminder.toolbox.id, this.state.deploymentReminder.deploymentId)
                                this.setState({
                                    showSendReminderDialog: false,
                                });
                            }}
                             >
                            {t('Ok')}
                        </Button>
                    </DialogActions>
                </Dialog>


                <AppModule
                    loading={loading}
                    hasTabs
                    prepend={tableActionHeader}
                >
                    <div
                        className={classnames({
                            [classes.button]: true,
                            'mui-fixed': true,
                        })}
                    >
                        <div className={'tooltip'}>
                            <span
                                className={'tooltiptext'}
                                style={{ top: '60px', left: '35px' }}
                            >
                                {t('Sent out toolbox')}
                            </span>
                            <Fab
                                 
                                onClick={this.handleDeployButtonClick}
                            >
                                <DeployIcon />
                            </Fab>
                        </div>
                    </div>
                    <PaginationTable
                        defaultOrderBy="deployed"
                        defaultOrder="desc"
                        hover
                        className="click"
                        onClick={this.onRowClick}
                        columns={columns}
                        rows={this.state.rows}
                        length={this.state.searchSize}
                        page={this.state.page}
                        rowsPerPage={this.state.rowsPerPage}
                        formatter={this.mapToolboxAssignToRow}
                        actions={actions}
                        onAction={this.handleAction}
                        emptyState={
                            this.state.searchtext.length > 0
                                ? t('No searchresults')
                                : t('No toolboxes have been sent out yet.')
                        }
                        onPageChange={this.setPage}
                        onRowsPerPageChange={this.setRowsPerPage}
                        handleSorting={this.handleSorting}
                        startPage={this.state.startPage}
                        startRowsPerPage={this.state.startRowsPerPage}

                    />
                </AppModule>
            </div>
        );
    }
}

var percentColors = percentColors3;

var getColorForPercentage = function (pct) {
    let color = percentColors[1];

    if (pct === 0) {
        color = percentColors[0];
    }

    if (pct === 1) {
        color = percentColors[2];
    }

    return 'rgba(' + [color.r, color.g, color.b].join(',') + ', 1)';
};


export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(compose(withTranslation(), )(ToolboxAssignedOverview))
);