// @flow
import React from 'react';
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    FormControl,
    Switch,
    Dialog,
    DialogTitle,
    DialogContent,
    MenuItem,
    ListItemText,
    InputLabel,
    Input,
} from '@mui/material';
import { connect } from 'react-redux';
import AppModule from '../../AppModule';
import {
    fetchIncidentCause,
    fetchIncidentTypes,
    getAppIncidents,
    sendIncidentResult,
} from '../../../actions';

import classes from './IncidentCreate.module.scss';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FormControlLabel } from '@mui/material';
import { push } from 'connected-react-router';
import TableActionHeader from '../../TableActionHeader';
import { inProgress } from '../../../selectors';
import SimpleImageInput from '../../SimpleImageInput';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import Select from '@mui/material/Select';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

type Props = {
    incidentcause: Array<any>,
    incidenttype: Array<any>,
};

type State = {
    feedbackText: string,
    unsafe: boolean,
    photos: any,
    incident: any,
    id: any,
    open: boolean,
    incidentType: any,
    incidentCause: any,
    slachtoffer: any,
    address: string,
    location: any,
    causeText: any,
    maatregelen: any,
    sending: boolean,
    anonymous: Boolean,
};


const mapStateToProps = (store, props) => {
    const incidentId = props.match.params.incidentId;

    const incidents = store.entities.incidents;

    let incident = null;

    if (props.match.params.incidentId !== undefined && incidents) {
        incidents.allIds.forEach((id) => {
            if (
                incidents.byId[id] !== null &&
                id === parseInt(incidentId, 10)
            ) {
                incident = incidents.byId[id];
            }
        });
    }

    return {
        incident: incident,
        incidenttype: store.entities.incidenttype,
        incidentcause: store.entities.incidentcause,
        loading: !inProgress(store),
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        sendResults: (result) => {
            dispatch(sendIncidentResult(result));
        },
        goTo: (path) => {
            dispatch(push(path));
        },
        getAppIncidents: () => {
            dispatch(getAppIncidents());
        },
        fetchIncidentTypes: () => {
            dispatch(fetchIncidentTypes());
        },
        fetchIncidentCause: () => {
            dispatch(fetchIncidentCause());
        },
    };
};

class IncidentCreate extends React.Component<Props, State> {
    props: Props;
    state: State = {
        feedbackText: '',
        unsafe: false,
        photos: [],
        id: '',
        open: false,
        incidentType: 0,
        slachtoffer: '',
        incidentCause: 0,
        causeText: '',
        location: { lat: 0.0, lon: 0.0 },
        maatregelen: '',
        sending: false,
        anonymous: false,
    };

    componentDidMount() {
        this.props.getAppIncidents();
        this.props.fetchIncidentTypes();
        this.props.fetchIncidentCause();

        navigator.geolocation.getCurrentPosition(this.getLocation);
    }

    componentWillReceiveProps(newProps: Props) {
        if (newProps.incident) {
            this.setState({
                feedbackText: newProps.incident.remark.text,
                photos: newProps.incident.remark.photos,
                unsafe: newProps.incident.remark.unsafe,
                anonymous: newProps.incident.anonymous,
                id: newProps.incident.id,
                incidentType: newProps.incident.type,
                incidentCause: newProps.incident.cause,
                slachtoffer: newProps.incident.slachtoffer,
                causeText: newProps.incident.cause_comment,
                maatregelen: newProps.incident.maatregelen,
            });
        }
    }

    getLocation = (event) => {
        this.geocodeAddress(
            event.coords.latitude + ',' + event.coords.longitude
        );
        this.setState({
            location: {
                lat: event.coords.latitude,
                lon: event.coords.longitude,
            },
        });
    };

    geocodeAddress = (address) => {
        var geocoder = new window.google.maps.Geocoder();

        const self = this;
        geocoder.geocode({ address: address }, function (results, status) {
            if (status === 'OK') {
                var address = results[0].formatted_address;
                var formatted = results[0].formatted_address.split(', ');
                if (formatted.length === 3) {
                    address = formatted[1] + ', ' + formatted[2];
                }
                self.setState({ address: address });
            }
        });
    };

    handleFormChange = (key) => (event) => {
        if (key === 'text') {
            if (event.target.value.length < 255) {
                this.setState({
                    feedbackText: event.target.value,
                });
            }
        } else if (key === 'type') {
            this.setState({
                incidentType: event.target.value,
            });
        } else if (key === 'slachtoffer') {
            if (event.target.value.length < 255) {
                this.setState({
                    slachtoffer: event.target.value,
                });
            }
        } else if (key === 'cause') {
            this.setState({
                incidentCause: event.target.value,
            });
        } else if (key === 'causeText') {
            if (event.target.value.length < 255) {
                this.setState({
                    causeText: event.target.value,
                });
            }
        } else if (key === 'maatregelen') {
            if (event.target.value.length < 255) {
                this.setState({
                    maatregelen: event.target.value,
                });
            }
        }
    };

    handleUnsafeChange = (event) => {
        if (this.props.match.params.incidentId == null) {
            this.setState({
                unsafe: !this.state.unsafe,
            });
        }
    };

    handleAnonymousChange = (event) => {
        if (this.props.match.params.incidentId == null) {
            this.setState({
                anonymous: !this.state.anonymous,
            });
        }
    };

    handleImageUpload = (event) => {
        if (event.id !== undefined) {
            this.setState({
                photos: [
                    ...this.state.photos,
                    { id: event.id, url: event.url, photo: event.id },
                ],
            });
        }
    };

    sendResults = () => {
        if (
            this.state.feedbackText.length > 0 &&
            this.state.incidentType !== 0 &&
            this.state.incidentCause !== 0 &&
            this.state.causeText.length > 0
        ) {
            this.setState({
                sending: true,
            });
            let results = {
                complete: true,
                date: Date.now(),
                remark: {
                    photos: this.state.photos,
                    text: this.state.feedbackText,
                    unsafe: this.state.unsafe,
                },
                slachtoffer: this.state.slachtoffer,
                cause: this.state.incidentCause,
                type: this.state.incidentType,
                maatregelen: this.state.maatregelen,
                cause_comment: this.state.causeText,
                lat: this.state.location.lat,
                lon: this.state.location.lon,
                address: this.state.address,
                anonymous: this.state.anonymous,
            };
            this.props.sendResults(results);
        } else {
            this.setState({
                open: true,
            });
        }
    };

    handleRemoveImage = (id) => {
        if (this.state.photos) {
            let photos = this.state.photos;
            let newPhotos = [];

            photos.forEach((photo, index) => {
                if (index !== id) {
                    newPhotos.push(photos[index]);
                }
            });

            this.setState({
                photos: newPhotos,
            });
        }
    };

    backToOverview = () => {
        this.props.goTo('/web/incident');
    };

    render() {
        const { loading, t } = this.props;
        const { unsafe, anonymous ,open } = this.state;

        const tableActionHeader = (
            <TableActionHeader
                title={
                    this.props.match.params.incidentId != null
                        ? t('View your reported incident below')
                        : t('Enter the details below')
                }
            />
        );

        // window.$crisp.push(['do', 'chat:hide']);

        return (
            <div className="App">
                <Dialog
                    open={open}
                    onClose={() => this.setState({ open: false })}
                >
                    <DialogTitle className="title">
                        {t('Not all mandatory fields completed')}
                    </DialogTitle>
                    <DialogContent>
                        {t('Fill all fields and send again')}
                    </DialogContent>
                    <div className="confirmButton-container">
                        <Button
                             
                            className="confirmButton"
                            onClick={() => this.setState({ open: false })}
                        >
                            {t('Ok')}
                        </Button>
                    </div>
                </Dialog>
                <AppBar position="fixed" style={{ boxShadow: 'none' }}>
                    <Toolbar disableGutters={true}>
                        <IconButton onClick={this.backToOverview}>
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography
                            type="title"
                            id="tbExternTitle"
                            style={{ marginLeft: '25px' }}
                        >
                            {t('Veilig vakwerk')}
                        </Typography>
                    </Toolbar>
                </AppBar>

                <AppModule
                    className="appModule"
                    prepend={tableActionHeader}
                    loading={loading}
                >
                    <div
                        className={classes.paper}
                        id="tbExternPaperFeedback"
                        style={{
                            minWidth: '128px',
                            maxWidth: '568px',
                            margin: '0 auto',
                        }}
                    >
                        <h1>
                            {!this.props.incident
                                ? t('Registration report')
                                : t('Your registration report from') +
                                  ' ' +
                                  moment(this.props.incident.date).format(
                                      t('dateTime')
                                  )}
                        </h1>
                        {this.props.match.params.incidentId == null && (
                            <p style={{ opacity: '0.6' }}>
                                {t(
                                    'Describe the report as accurately as possible so that we can prevent incidents in the future, for example.'
                                )}
                            </p>
                        )}
                        <br />
                        <p style={{ fontSize: '16px' }}>
                            <b>{t('Type of report')}</b>
                        </p>
                        <FormControl
                            className={classes.item}
                            style={{ width: '230px' }}
                        >
                            <InputLabel htmlFor="type">
                                {t('Type of report')}*
                            </InputLabel>
                            <Select
                            fullWidth
                                value={
                                    this.state.incidentType
                                        ? this.state.incidentType
                                        : ''
                                }
                                disabled={
                                    this.props.match.params.incidentId != null
                                }
                                onChange={this.handleFormChange('type')}
                            >
                                {this.props.incidenttype &&
                                    this.props.incidenttype.allIds.map(
                                        (type) => {
                                            return (
                                                <MenuItem
                                                    key={
                                                        this.props.incidenttype
                                                            .byId[type].id
                                                    }
                                                    value={
                                                        this.props.incidenttype
                                                            .byId[type].id
                                                    }
                                                >
                                                    <ListItemText
                                                        primary={t(
                                                            this.props
                                                                .incidenttype
                                                                .byId[type]
                                                                .locKey ||
                                                                this.props
                                                                    .incidenttype
                                                                    .byId[type]
                                                                    .name
                                                        )}
                                                    />
                                                </MenuItem>
                                            );
                                        }
                                    )}
                            </Select>
                        </FormControl>
                        <br />
                        <p style={{ fontSize: '16px' }}>
                            <b>{t('Victim / affected person / customer')}</b>
                        </p>
                        <FormControl className={classes.item2}>
                            {this.props.match.params.incidentId == null && (
                                <InputLabel htmlFor="incident-description">
                                    {t('Enter a name if required')}
                                </InputLabel>
                            )}
                            <Input
                                fullWidth={true}
                                
                                id="incident-description"
                                value={this.state.slachtoffer}
                                onChange={this.handleFormChange('slachtoffer')}
                                disabled={
                                    this.props.match.params.incidentId != null
                                }
                            />
                        </FormControl>
                        <br />
                        <p style={{ fontSize: '16px' }}>
                            <b>{t('Description')}</b>
                        </p>
                        <FormControl
                            className={classes.item2}
                            style={{ backgroundColor: '#F0F0F0' }}
                        >
                            <Input
                                fullWidth={true}
                                placeholder={t(
                                    'Enter notification description'
                                )}
                                
                                id="incident-description"
                                value={this.state.feedbackText}
                                onChange={this.handleFormChange('text')}
                                multiline
                                rows={5}
                                disabled={
                                    this.props.match.params.incidentId != null
                                }
                            />
                        </FormControl>
                        <br />
                        <p style={{ fontSize: '16px' }}>
                            <b>{t('Anonymous report')}</b>
                        </p>
                        <FormControl
                            style={
                                this.props.match.params.incidentId != null
                                    ? { opacity: '0.5' }
                                    : {}
                            }
                        >
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={anonymous}
                                        onChange={this.handleAnonymousChange}
                                        id="anonymousReport"
                                    />
                                }
                                label={t('Anonymous report')}
                            />
                        </FormControl>
                        <br />
                        <p style={{ fontSize: '16px' }}>
                            <b>{t('Cause')}</b>
                        </p>
                        <FormControl
                            className={classes.item}
                            style={{ width: '230px' }}
                        >
                            <InputLabel htmlFor="cause">
                                {t('Type of cause')}*
                            </InputLabel>
                            <Select
                                fullWidth
                                value={
                                    this.state.incidentCause
                                        ? this.state.incidentCause
                                        : ''
                                }
                                onChange={this.handleFormChange('cause')}
                                disabled={
                                    this.props.match.params.incidentId != null
                                }
                            >
                                {this.props.incidentcause &&
                                    this.props.incidentcause.allIds.map(
                                        (type) => {
                                            return (
                                                <MenuItem
                                                    key={
                                                        this.props.incidentcause
                                                            .byId[type].id
                                                    }
                                                    value={
                                                        this.props.incidentcause
                                                            .byId[type].id
                                                    }
                                                >
                                                    <ListItemText
                                                        primary={t(
                                                            this.props
                                                                .incidentcause
                                                                .byId[type]
                                                                .locKey ||
                                                                this.props
                                                                    .incidentcause
                                                                    .byId[type]
                                                                    .name
                                                        )}
                                                    />
                                                </MenuItem>
                                            );
                                        }
                                    )}
                            </Select>
                        </FormControl>
                        <FormControl
                            className={classes.item2}
                            style={{ backgroundColor: '#F0F0F0' }}
                        >
                            {this.props.match.params.incidentId == null ? (
                                <Input
                                    fullWidth={true}
                                    placeholder={t('Enter explanation cause')}
                                    
                                    id="incident-description"
                                    value={this.state.causeText}
                                    onChange={this.handleFormChange(
                                        'causeText'
                                    )}
                                    multiline
                                    rows={3}
                                />
                            ) : (
                                <Input
                                    fullWidth={true}
                                    placeholder={t('No explanation of cause')}
                                    
                                    id="incident-description"
                                    value={this.state.causeText}
                                    onChange={this.handleFormChange(
                                        'causeText'
                                    )}
                                    multiline
                                    rows={3}
                                    disabled={true}
                                />
                            )}
                        </FormControl>
                        <br />
                        <p style={{ fontSize: '16px' }}>
                            <b>{t('Measures taken immediately')}</b>
                        </p>
                        <FormControl
                            className={classes.item2}
                            style={{ backgroundColor: '#F0F0F0' }}
                        >
                            {this.props.match.params.incidentId == null ? (
                                <Input
                                    fullWidth={true}
                                    placeholder={t(
                                        'Enter the measures taken immediately'
                                    )}
                                    
                                    id="incident-description"
                                    value={this.state.maatregelen}
                                    onChange={this.handleFormChange(
                                        'maatregelen'
                                    )}
                                    multiline
                                    rows={3}
                                />
                            ) : (
                                <Input
                                    fullWidth={true}
                                    placeholder={t(
                                        'No measures taken immediately'
                                    )}
                                    
                                    id="incident-description"
                                    value={this.state.maatregelen}
                                    onChange={this.handleFormChange(
                                        'maatregelen'
                                    )}
                                    multiline
                                    rows={3}
                                    disabled={true}
                                />
                            )}
                        </FormControl>
                        <br />
                        <p style={{ fontSize: '16px' }}>
                            <b>{t('Unsafe situation')}</b>
                        </p>
                        <FormControl
                            style={
                                this.props.match.params.incidentId != null
                                    ? { opacity: '0.5' }
                                    : {}
                            }
                        >
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={unsafe}
                                        onChange={this.handleUnsafeChange}
                                        id="unsafeIncident"
                                    />
                                }
                                label={t('Unsafe situation')}
                            />
                        </FormControl>
                        <br />
                        <p style={{ fontSize: '16px' }}>
                            <b>
                                {this.props.match.params.incidentId == null
                                    ? t('Add image(s)')
                                    : t('Images')}
                            </b>
                        </p>
                        {!loading &&
                            this.props.match.params.incidentId == null && (
                                <div style={{ display: 'inline-block' }}>
                                    <div
                                        style={{
                                            float: 'left',
                                            marginRight: '10px',
                                            marginTop: '10px',
                                            position: 'relative',
                                        }}
                                    >
                                        <SimpleImageInput
                                            id={'incidentphoto0'}
                                            onChange={this.handleImageUpload}
                                            url={
                                                this.state.photos &&
                                                this.state.photos[0]
                                                    ? this.state.photos[0].url
                                                    : ''
                                            }
                                        />
                                        {this.state.photos &&
                                            this.state.photos[0] && (
                                                <IconButton
                                                     
                                                    className="drawer-button"
                                                    onClick={() =>
                                                        this.handleRemoveImage(
                                                            0
                                                        )
                                                    }
                                                    style={{
                                                        position: 'absolute',
                                                        bottom: '-5px',
                                                        right: '-20px',
                                                    }}
                                                >
                                                    <DeleteIcon className="drawer-icon" />
                                                </IconButton>
                                            )}
                                    </div>
                                    {this.state.photos[0] && (
                                        <div
                                            style={{
                                                float: 'left',
                                                marginRight: '10px',
                                                marginTop: '10px',
                                                position: 'relative',
                                            }}
                                        >
                                            <SimpleImageInput
                                                id={'incidentphoto1'}
                                                onChange={
                                                    this.handleImageUpload
                                                }
                                                disabled={this.state.id !== ''}
                                                url={
                                                    this.state.photos &&
                                                    this.state.photos[1]
                                                        ? this.state.photos[1]
                                                              .url
                                                        : ''
                                                }
                                            />
                                            {this.state.photos &&
                                                this.state.photos[1] && (
                                                    <IconButton
                                                         
                                                        className="drawer-button"
                                                        onClick={() =>
                                                            this.handleRemoveImage(
                                                                1
                                                            )
                                                        }
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            bottom: '-5px',
                                                            right: '-20px',
                                                        }}
                                                    >
                                                        <DeleteIcon className="drawer-icon" />
                                                    </IconButton>
                                                )}
                                        </div>
                                    )}
                                    {this.state.photos[1] && (
                                        <div
                                            style={{
                                                float: 'left',
                                                marginRight: '10px',
                                                marginTop: '10px',
                                                position: 'relative',
                                            }}
                                        >
                                            <SimpleImageInput
                                                id={'incidentphoto2'}
                                                onChange={
                                                    this.handleImageUpload
                                                }
                                                url={
                                                    this.state.photos &&
                                                    this.state.photos[2]
                                                        ? this.state.photos[2]
                                                              .url
                                                        : ''
                                                }
                                            />
                                            {this.state.photos &&
                                                this.state.photos[2] && (
                                                    <IconButton
                                                         
                                                        className="drawer-button"
                                                        onClick={() =>
                                                            this.handleRemoveImage(
                                                                2
                                                            )
                                                        }
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            bottom: '-5px',
                                                            right: '-20px',
                                                        }}
                                                    >
                                                        <DeleteIcon className="drawer-icon" />
                                                    </IconButton>
                                                )}
                                        </div>
                                    )}
                                    {this.state.photos[2] && (
                                        <div
                                            style={{
                                                float: 'left',
                                                marginRight: '10px',
                                                marginTop: '10px',
                                                position: 'relative',
                                            }}
                                        >
                                            <SimpleImageInput
                                                id={'incidentphoto3'}
                                                onChange={
                                                    this.handleImageUpload
                                                }
                                                url={
                                                    this.state.photos &&
                                                    this.state.photos[3]
                                                        ? this.state.photos[3]
                                                              .url
                                                        : ''
                                                }
                                            />
                                            {this.state.photos &&
                                                this.state.photos[3] && (
                                                    <IconButton
                                                         
                                                        className="drawer-button"
                                                        onClick={() =>
                                                            this.handleRemoveImage(
                                                                3
                                                            )
                                                        }
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            bottom: '-5px',
                                                            right: '-20px',
                                                        }}
                                                    >
                                                        <DeleteIcon className="drawer-icon" />
                                                    </IconButton>
                                                )}
                                        </div>
                                    )}
                                    {this.state.photos[3] && (
                                        <div
                                            style={{
                                                float: 'left',
                                                marginRight: '10px',
                                                marginTop: '10px',
                                                position: 'relative',
                                            }}
                                        >
                                            <SimpleImageInput
                                                id={'incidentphoto4'}
                                                onChange={
                                                    this.handleImageUpload
                                                }
                                                url={
                                                    this.state.photos &&
                                                    this.state.photos[4]
                                                        ? this.state.photos[4]
                                                              .url
                                                        : ''
                                                }
                                            />
                                            {this.state.photos &&
                                                this.state.photos[4] && (
                                                    <IconButton
                                                         
                                                        className="drawer-button"
                                                        onClick={() =>
                                                            this.handleRemoveImage(
                                                                4
                                                            )
                                                        }
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            bottom: '-5px',
                                                            right: '-20px',
                                                        }}
                                                    >
                                                        <DeleteIcon className="drawer-icon" />
                                                    </IconButton>
                                                )}
                                        </div>
                                    )}
                                </div>
                            )}
                        {this.props.incident &&
                            this.props.incident.remark.photos.map((photo) => (
                                <img
                                    style={{
                                        float: 'left',
                                        marginRight: '10px',
                                        marginTop: '10px',
                                    }}
                                    src={photo.url}
                                    alt={'foto'}
                                    width="128px"
                                    height="128px"
                                />
                            ))}
                        <br />
                        {this.props.match.params.incidentId == null && (
                            <Button
                                
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={this.sendResults}
                                id="sendresultsButton"
                                style={{
                                    width: '100%',
                                    height: '64px',
                                    marginTop: '24px',
                                }}
                                disabled={this.state.sending}
                            >
                                {t('Send notification')}
                            </Button>
                        )}
                        <br />
                        <br />
                    </div>
                </AppModule>
            </div>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
    
)(IncidentCreate);
