// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import TaskView from '../../Task/TaskView';
import _ from 'lodash';
import {setCrumbPath, fetchIncidentCause, fetchIncidentTypes, showSnackbar } from '../../../actions';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AppModule from '../../AppModule';
import TableActionHeader from '../../TableActionHeader/TableActionHeader';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import {withRouter} from "react-router";
import classes from './IncidentDetailView.module.scss';
import colors from "../../../lib/themes/theme.scss";
import IncidentEditView from "../IncidentEditView/IncidentEditView";
import moment from "../../../lib/moment";
import {Fab} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/GetApp";
import InsertIcon from "@mui/icons-material/InsertDriveFile";
import DescIcon from "@mui/icons-material/Description";
import {fetchPDF} from "../../../lib/api";


type Props = {
    match: any,
    setCrumbPath: (inspectionName: string) => void,
};

type State = {
    tab: number,
};

const mapStateToProps = (store, props) => {
    return {
        incidenttype: store.entities.incidenttype,
        incidentcause: store.entities.incidentcause,
        incidents: store.entities.incidents,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        showSnackbar: (message: string) => dispatch(
            showSnackbar(message)
        ),
        setCrumbPath: (name: string) => {
            dispatch(setCrumbPath({
                title: 'Registratiemeldingen',
                crumbs: [
                    {
                        name: name,
                        link: '/registratiemeldingen',
                    }
                ],
            }));
        },
        fetchIncidentTypes: () => {
            dispatch(fetchIncidentTypes());
        },
        fetchIncidentCause: () => {
            dispatch(fetchIncidentCause());
        },
    };
};

class IncidentDetailView extends React.Component<Props, State> {
    props: Props;
    state: State = {
        tab: 0,
    };

    componentDidMount() {
        let tab = 0
        if (this.props.location) {
            if (this.props.location.hash === '#informatie') {
                tab = 0;
            }
            if (this.props.location.hash === '#tasks') {
                tab = 1;
            }
        }
        this.setState({
            tab: tab,
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Use match params incidentId to set the proper incident
        if (!prevState.incident && this.props.match.params && this.props.incidents) {
            let incidentId = this.props.match.params.incidentId
            // Read incident from the full list
            let incident = this.props.incidents.byId[incidentId];
            if (incident) {
                this.props.setCrumbPath(moment(incident.date).format('LLL'));
                this.setState({ incident: incident });
            }
        }
    }

    handleTabChange = (event: any, value: number) => {
        this.setState({ tab: value });
    };


    toggleExport = () => {
        if (this.state && this.state.exportOpen) {
            this.setState({
                exportOpen: false,
            });
        } else {
            this.setState({
                exportOpen: true,
            });
        }
    };

    handlePDFClick = () => {
        const { t } = this.props.i18n;
        const { incident } = this.state;
        const { i18n, incidentcause, incidenttype} = this.props;
        const title = moment(incident.date).format('LLL');
        const version = '1.0.0';
        const theCause = t(incidentcause.byId[incident.cause].locKey || incidentcause.byId[incident.cause].name);
        const theType = t(incidenttype.byId[incident.type].locKey || incidenttype.byId[incident.type].name);

        fetchPDF({
            platform: 'VV',
            customer: 'VV',
            template: 'REGISTRATIEMELDING',
            options: {},
            data: [],
            info: {
                title: title,
                version: version,
                incident: incident,
                showResults: true,
                theCause: theCause,
                theType: theType,
                kamComment: incident.kamComment,
                kamResolved: incident.kamResolved,
            },
        })
            .then((res) => {
                var encodedUri = window.URL.createObjectURL(res.body);
                var link = document.createElement('a');
                link.setAttribute('href', encodedUri);
                link.setAttribute('download', title + ' v' + version + '.pdf');
                document.body.appendChild(link);
                link.click(); //
            })
            .catch((err) => console.log(err));
    };

    geocodeAddress = (address) => {
        var geocoder = new window.google.maps.Geocoder();

        const self = this;
        geocoder.geocode({ address: address }, function (results, status) {
            if (status === 'OK') {
                var address = results[0].formatted_address;
                var formatted = results[0].formatted_address.split(', ');
                if (formatted.length === 3) {
                    address = formatted[1] + ', ' + formatted[2];
                }
                self.setState({ address: address });
            }
        });
    };

    render() {
        const {t, } = this.props;
        const { tab, incident  } = this.state;

        const tableActionHeader = <TableActionHeader />;

        if (!incident) {
            return t('Loading');
        }
        return (
            <div>
                <Tabs
                    value={tab}
                    onChange={this.handleTabChange}
                    className={classes.root}
                    indicatorColor={colors.primaryColor}
                    textColor="secondary"
                    sx={{color: colors.mainTabsColor, '& .Mui-selected': { color: colors.mainTabsSelectedColor } }}
                >
                    <Tab label={t('Information')} href="#informatie" />
                    <Tab label={t('Task Title')} href="#tasks" />
                </Tabs>
                {(
                    <AppModule hasTabs prepend={tableActionHeader}>
                        {tab === 0 && incident && (
                            <div>
                                <div
                                    style={{
                                        position: 'fixed',
                                        top: '84px',
                                        right: '30px',
                                        zIndex: 1500,
                                    }}
                                >
                                    <div>
                                        <Fab
                                            onClick={this.toggleExport}
                                        >
                                            {this.state && this.state.exportOpen ? (
                                                <CloseIcon />
                                            ) : (
                                                <DownloadIcon />
                                            )}
                                        </Fab>
                                    </div>
                                    <div
                                        style={
                                            this.state && this.state.exportOpen
                                                ? {
                                                    marginTop: '20px',
                                                    marginLeft: '8px',
                                                    visibility: 'visible',
                                                    transition:
                                                        'margin-top 300ms',
                                                }
                                                : {
                                                    marginTop: '0px',
                                                    marginLeft: '8px',
                                                    visibility: 'hidden',
                                                    transition:
                                                        'margin-top 300ms',
                                                }
                                        }
                                        className={'tooltip'}
                                    >
                                    <span
                                        className={
                                            this.state && this.state.exportOpen
                                                ? 'tooltiptextvisible'
                                                : 'tooltiptext'
                                        }
                                        style={{ top: '87px', right: '60px' }}
                                    >
                                        {t('Download PDF file')}
                                    </span>
                                        <Fab
                                            color="primary"

                                            onClick={this.handlePDFClick}
                                        >
                                            <InsertIcon />
                                        </Fab>
                                    </div>
                                </div>
                                <IncidentEditView
                                    incident={incident}
                                />
                            </div>
                        )}

                        {tab === 1 && incident && (
                            <TaskView
                                incidentId={incident.id}
                            />
                        )}

                    </AppModule>
                )}
            </div>
        );
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(compose(withTranslation(), )(IncidentDetailView))
);
