// @flow

import { connect } from 'react-redux';
import React from 'react';
import type { StoreState } from '../../../reducers/index';
import StatisticsEmployeeDetailCard from '../StatisticsCard/StatisticsEmployeeDetailCard';
import type { Dispatch as ReduxDispatch } from 'redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import {
    clearAllFilters,
    filterPeriodMonth,
    filterPeriodType,
    filterPeriodWeek,
    filterPeriodYear,
    setViewkey,
    setCrumbPath,
    filterPeriodFrom,
    filterPeriodTill, fetchToolboxAssigns, showSnackbar,
} from '../../../actions';
import PeriodFilterBlock from '../../FilterBlocks/PeriodFilterBlock';
import { getCurrentFilterSet } from '../../../reducers/filterReducer';
import moment from 'moment';
import AppModule from '../../AppModule';
import TableActionHeader from '../../TableActionHeader/TableActionHeader';
import StatisticsListEmployeeAssign from '../StatisticsList/StatisticsListEmployeeAssign';
import getFilteredInspectionAssigns from '../../../selectors/getFilteredInspectionAssigns';
import { getFilteredToolboxAssigns } from '../../../selectors';
import { fetchCSV, fetchPDF, sendPDFInfo } from '../../../lib/api';
import DescIcon from '@mui/icons-material/Description';
import { Fab} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InsertIcon from '@mui/icons-material/InsertDriveFile';
import DownloadIcon from '@mui/icons-material/GetApp';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

type Dispatch = ReduxDispatch<StoreState, { type: $Subtype<string> }>;

const viewkey: string = 'STATISTICS_VIEW';

type Props = {};

type State = {
    searchtext: string,
};

let employeeId;
let employee;

const mapStateToProps = (state, props) => {
    let filters = getCurrentFilterSet(state.filters);

    const inspections = state.entities.inspections;             // Use these inspections to fill set the full inspection record in inspectionAssigns


    employeeId = props.match.params.id;
    employee = state.entities.employees.byId[employeeId];

    let inspectionAssigns = getFilteredInspectionAssigns(state);
    let toolboxAssigns = getFilteredToolboxAssigns(state);

    if (inspectionAssigns && employee) {
        inspectionAssigns = inspectionAssigns.filter((ia) => {
            return ia.user.id === employee.id;
        });
    } else {
        inspectionAssigns = null;
    }

    // Insert the inspection into the ia record.
    if (inspectionAssigns && inspections && inspections.allIds.length > 0) {
        inspectionAssigns = inspectionAssigns.map((ia) => {
            return {
                ...ia,
                inspection: {
                    ...inspections.byId[ia.inspectionId || 0 ],
                },
            };
        });
    }


    if (toolboxAssigns && employee) {
        toolboxAssigns = toolboxAssigns.filter((ia) => {
            return ia.user.id === employee.id;
        });
    } else {
        toolboxAssigns = null;
    }

    return {
        inspection: employee,
        inspectionAssigns: inspectionAssigns,
        toolboxAssigns: toolboxAssigns,
        listData: state.statistics.listData,
        periodType: filters.periodType,
        periodYear: filters.periodYear,
        periodMonth: filters.periodMonth,
        periodWeek: filters.periodWeek,
        periodFrom: filters.periodFrom,
        periodTill: filters.periodTill,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        filterPeriodType: bindActionCreators(filterPeriodType, dispatch),
        filterPeriodYear: bindActionCreators(filterPeriodYear, dispatch),
        filterPeriodMonth: bindActionCreators(filterPeriodMonth, dispatch),
        filterPeriodWeek: bindActionCreators(filterPeriodWeek, dispatch),
        filterPeriodFrom: bindActionCreators(filterPeriodFrom, dispatch),
        filterPeriodTill: bindActionCreators(filterPeriodTill, dispatch),
        clearAllFilters: bindActionCreators(clearAllFilters, dispatch),
        fetchToolboxAssigns: () => {
            dispatch(fetchToolboxAssigns());
        },

        setViewkey: () => {
            dispatch(setViewkey(viewkey));
        },
        goTo: (path) => {
            dispatch(push(path));
        },
        mailSent: (t) => {
            dispatch(showSnackbar(`${t('Email was sent')} `));
        },

        setCrumbPath: (t) => {
            dispatch(
                setCrumbPath({
                    title: 'Rapportages',
                    crumbs: [
                        {
                            name:
                                `${t('Employee')}: ` + employee &&
                                employee !== undefined &&
                                `${t('Employee')}: ` + employee.fullname,
                            link: '/rapportages#medewerkers',
                        },
                    ],
                })
            );
        },
    };
};

class StatisticsEmployeeView extends React.Component<Props, State> {
    props: Props;
    state: {
        searchtext: '',
    };

    componentDidMount() {
        this.props.fetchToolboxAssigns();

        //set view key
        this.props.setViewkey();

        let query = this.props.match.params;
        if (query.year) {
            this.props.clearAllFilters();

            if (query.month) {
                this.props.filterPeriodType('month');
                this.props.filterPeriodYear(parseInt(query.year, 10));
                this.props.filterPeriodMonth(parseInt(query.month - 1, 10));
            } else if (query.week) {
                this.props.filterPeriodType('week');
                this.props.filterPeriodYear(parseInt(query.year, 10));
                this.props.filterPeriodWeek(parseInt(query.week, 10));
            } else {
                this.props.filterPeriodType('year');
                this.props.filterPeriodYear(parseInt(query.year, 10));
            }
        }

        this.props.setCrumbPath(this.props.t);
    }

    componentWillReceiveProps(newProps: Props) {
        if (this.props.inspection !== newProps.inspection) {
            this.props.setCrumbPath(this.props.t);
        }
    }

    onItemClicked = (clickedInspectionAssign) => {
        if (clickedInspectionAssign.inspection !== undefined) {
            let target = `/rapportages/medewerker/${employeeId}/werkplekinspectie/details/${clickedInspectionAssign.id}?from=rapportages#resultaten`;
            this.props.goTo(target);
        } else if (clickedInspectionAssign.toolbox !== undefined) {
            let target = `/rapportages/medewerker/${employeeId}/toolbox/details/${clickedInspectionAssign.toolbox.id}/${clickedInspectionAssign.deployed}/?from=rapportages#resultaten`;
            this.props.goTo(target);
        }
    };

    getList = () => {
        return (
            <StatisticsListEmployeeAssign
                inspectionAssigns={this.props.inspectionAssigns}
                toolboxAssigns={this.props.toolboxAssigns}
                onItemClick={this.onItemClicked}
                searchtext={this.state ? this.state.searchtext : ''}
            />
        );
    };

    handleSearch = (searchtext) => {
        this.setState({
            searchtext: searchtext,
        });
    };

    getFilterBar = () => {
        return (
            <div className="filterbar">
                <PeriodFilterBlock />
            </div>
        );
    };

    toggleExport = () => {
        if (this.state && this.state.exportOpen) {
            this.setState({
                exportOpen: false,
            });
        } else {
            this.setState({
                exportOpen: true,
            });
        }
    };

    downloadCsv = () => {
        const { t } = this.props;
        let inspections = this.props.inspectionAssigns.filter((employee) =>
            this.state && this.state.searchtext
                ? employee.inspection.title
                      .toLowerCase()
                      .indexOf(this.state.searchtext.toLowerCase()) > -1
                : true
        );
        let toolboxes = this.props.toolboxAssigns.filter((employee) =>
            this.state && this.state.searchtext
                ? employee.toolbox.title
                      .toLowerCase()
                      .indexOf(this.state.searchtext.toLowerCase()) > -1
                : true
        );

        let data = [];

        inspections.forEach((item) => {
            let result = '';
            if (item.result) {
                if (item.result.unsafe) {
                    result = t('Unsafe situation');
                } else {
                    result = t('Correct');
                }
            }

            data.push({
                type: 'Werkplekinspectie',
                title: item.inspection.title,
                version: item.inspection.version,
                deployed: moment(item.deployed).format('LLL'),
                afgerond: item.result
                    ? moment(item.result.date).format('LLL')
                    : t('No'),
                result: result,
            });
        });

        toolboxes.forEach((item) => {
            let total = 0;
            let score = 0;

            if (item.result) {
                item.result.answers.forEach((i) => {
                    total++;
                    if (i.correct) {
                        score++;
                    }
                });
            }

            let result = '';

            if (total > 0) {
                result =
                    score.toString() + ` ${t('off the')} ` + total.toString();
            }

            data.push({
                type: item.toolboxMeeting ? 'Toolboxmeeting' : 'Toolbox',
                title: item.toolbox.title,
                version: item.version,
                deployed: moment(item.deployed).format('LLL'),
                afgerond: item.result
                    ? moment(item.result.date).format('LLL')
                    : t('No'),
                result: result,
            });
        });

        fetchCSV({
            platform: 'VV',
            customer: 'VV',
            template: 'EMPLOYEEASSIGN_RAPPORTAGE',
            options: {},
            data: data,
            info: {},
        })
            .then((res) => {
                const url = URL.createObjectURL(res.body);
                const link = document.createElement('a');
                link.setAttribute('href', url);
                link.setAttribute('download', employee.fullname + '.csv');
                link.click();
            })
            .catch((err) => console.log(err));
    };

    getPDF = (mail) => {
        let inspections = this.props.inspectionAssigns.filter((employee) =>
            this.state && this.state.searchtext
                ? employee.inspection.title
                      .toLowerCase()
                      .indexOf(this.state.searchtext.toLowerCase()) > -1
                : true
        );
        let toolboxes = this.props.toolboxAssigns.filter((employee) =>
            this.state && this.state.searchtext
                ? employee.toolbox.title
                      .toLowerCase()
                      .indexOf(this.state.searchtext.toLowerCase()) > -1
                : true
        );

        let inspectionData = [];
        let toolboxData = [];

        inspections.forEach((item) => {
            inspectionData.push({
                inspection: {
                    title: item.inspection.title,
                    version: item.inspection.version,
                },
                deployed: item.deployed,
                result: item.result
                    ? {
                          date: item.result.date,
                          allCorrect: item.result.allCorrect,
                          answers: item.result.answers,
                      }
                    : null,
            });
        });

        toolboxes.forEach((item) => {
            toolboxData.push({
                toolbox: {
                    title: item.toolbox.title,
                },
                toolboxMeeting: item.toolboxMeeting,
                version: item.version,
                deployed: item.deployed,
                result: item.result
                    ? {
                          date: item.result.date,
                          allCorrect: item.result.allCorrect,
                          answers: item.result.answers,
                      }
                    : null,
            });
        });

        let data = inspectionData.concat(toolboxData);

        fetchPDF({
            platform: 'VV',
            customer: 'VV',
            template: 'EMPLOYEEASSIGN_RAPPORTAGE',
            options: {},
            data: data,
            info: {
                employee: this.props.inspection,
                periodType: this.props.periodType,
                periodYear: this.props.periodYear,
                periodMonth: this.props.periodMonth,
                periodWeek: this.props.periodWeek,
                periodFrom: moment(this.props.periodFrom).format('LL'),
                periodTill: moment(this.props.periodTill).format('LL'),
            },
        })
            .then((res) => {
                var encodedUri = window.URL.createObjectURL(res.body);
                if(!mail){
                    var link = document.createElement('a');
                    link.setAttribute('href', encodedUri);
                    link.setAttribute(
                        'download',
                        'medewerkersrapportage_' +
                            this.props.inspection.fullname +
                            '.pdf'
                    );
                    document.body.appendChild(link);
                    link.click(); //
                }else{
                    sendPDFInfo(employee.id, res.body)
                    .catch((err) => console.log(err));

                    this.setState( { exportOpen: false });

                    this.props.mailSent(this.props.t);



                }
            })
            .catch((err) => console.log(err));
    };

    render() {
        const { t } = this.props;
        const tableActionHeader = (
            <TableActionHeader
                title={this.getFilterBar()}
                onSearchChange={this.handleSearch}
                searchPlaceholder={t('Search in employees')}
            />
        );

        return (
            <div className="Statistics">
                <AppModule loading={false} prepend={tableActionHeader}>
                    <StatisticsEmployeeDetailCard
                        inspectionBarData={
                            this.props.chartData
                                ? this.props.chartData.correctnessBarData
                                : []
                        }
                        onDownloadCSV={this.downloadCsv}
                        list={this.getList()}
                        totalInspectionsIncorrect={
                            this.props.chartData
                                ? this.props.chartData.totalInspectionsIncorrect
                                : 0
                        }
                        totalInspectionsCorrect={
                            this.props.chartData
                                ? this.props.chartData.totalInspectionsCorrect
                                : 0
                        }
                    />
                </AppModule>
                <div
                    style={{
                        position: 'fixed',
                        top: '32px',
                        right: '36px',
                        zIndex: 1500,
                    }}
                >
                    <div>
                        <Fab   onClick={this.toggleExport}>
                            {this.state && this.state.exportOpen ? (
                                <CloseIcon />
                            ) : (
                                <DownloadIcon />
                            )}
                        </Fab>
                    </div>
                    <div
                        style={
                            this.state && this.state.exportOpen
                                ? {
                                      marginTop: '20px',
                                      marginLeft: '8px',
                                      visibility: 'visible',
                                      transition: 'margin-top 300ms',
                                  }
                                : {
                                      marginTop: '0px',
                                      marginLeft: '8px',
                                      visibility: 'hidden',
                                      transition: 'margin-top 300ms',
                                  }
                        }
                        className={'tooltip'}
                    >
                        <span
                            className={
                                this.state && this.state.exportOpen
                                    ? 'tooltiptextvisible'
                                    : 'tooltiptext'
                            }
                            style={{ top: '87px', right: '60px' }}
                        >
                            {t('Download PDF file')}
                        </span>
                        <Fab color="primary"  onClick={() => this.getPDF(false)}>
                            <InsertIcon />
                        </Fab>
                    </div>
                    <div
                        style={
                            this.state && this.state.exportOpen
                                ? {
                                      marginTop: '15px',
                                      marginLeft: '8px',
                                      visibility: 'visible',
                                      transition: 'margin-top 300ms',
                                  }
                                : {
                                      marginTop: '0px',
                                      marginLeft: '8px',
                                      visibility: 'hidden',
                                      transition: 'margin-top 300ms',
                                  }
                        }
                        className={'tooltip'}
                    >
                        <span
                            className={
                                this.state && this.state.exportOpen
                                    ? 'tooltiptextvisible'
                                    : 'tooltiptext'
                            }
                            style={{ top: '145px', right: '60px' }}
                        >
                            {t('Download CSV file')}
                        </span>
                        <Fab
                            color="primary"
                            
                            onClick={this.downloadCsv}
                        >
                            <DescIcon />
                        </Fab>
                    </div>
                    <div
                        style={
                            this.state && this.state.exportOpen
                                ? {
                                      marginTop: '15px',
                                      marginLeft: '8px',
                                      visibility: 'visible',
                                      transition: 'margin-top 300ms',
                                  }
                                : {
                                      marginTop: '0px',
                                      marginLeft: '8px',
                                      visibility: 'hidden',
                                      transition: 'margin-top 300ms',
                                  }
                        }
                        className={'tooltip'}
                    >
                        <span
                            className={
                                this.state && this.state.exportOpen
                                    ? 'tooltiptextvisible'
                                    : 'tooltiptext'
                            }
                            style={{ top: '200px', right: '60px' }}
                        >
                            {t('Send mail with report to employee')}
                        </span>
                        <Fab
                            color="primary"
                            
                            onClick={() => this.getPDF(true)}
                        >
                            <DescIcon />
                        </Fab>
                    </div>
                </div>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(StatisticsEmployeeView);
