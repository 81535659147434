// @flow

import { connect } from 'react-redux';
import React from 'react';
import { push } from 'connected-react-router';
import {
    fetchProjects,
    fetchInspectionAssigns,
    setCrumbPath,
} from '../../../../actions/index';
import AppModule from '../../../AppModule/AppModule';
import TableActionHeader from '../../../TableActionHeader/TableActionHeader';
import { List } from 'immutable';
import EnhancedTable from '../../../EnhancedTable';
import { fetchInspectionAssignByProject } from '../../../../lib/api';
import moment from '../../../../lib/moment';
import classnames from 'classnames';
import WarningIcon from '@mui/icons-material/Warning';
import Button from '@mui/material/Button';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import classes from './ProjectDetailsView.module.scss'

type Props = {
    projects: List<any>,
    projectsById: { [string]: any },
    inspectionAssigns: any,
    project: any,
};

type State = {
    page: number,
    rowsPerPage: number,
};

const mapStateToProps = (store, props) => {
    const projectId = props.match.params.id;
    const projects = store.entities.projects;
    const inspections = store.entities.inspections;
    const project = projects.byId[projectId];

    let inspectionAssigns = store.entities.inspectionAssigns;
    if (inspectionAssigns) {
        inspectionAssigns = inspectionAssigns.allIds.map(
            (id) => inspectionAssigns.byId[id]
        );
    }

    return {
        project: project,
        projectsById: projects.byId,
        projects: projects.allIds.map((id) => projects.byId[id]),
        inspections: inspections,
        inspectionAssigns: inspectionAssigns,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        fetchProjects: () => {
            dispatch(fetchProjects());
        },
        goTo: (path) => {
            dispatch(push(path));
        },
        setCrumbPath: (title) => {

            const search = ownProps.location.search;
            let page = new URLSearchParams(search).get("page");
            if(page == null){
                page = 0;
            }

            let rowsPerPage = new URLSearchParams(search).get("rowsPerPage");
            if( rowsPerPage == null){
                rowsPerPage = 10
            }

            console.log("INFO: calling setCrumbPath in ProjectDetailsView: hash = " + search + " page="+page + "rowsPerPage="+rowsPerPage);

            dispatch(
                setCrumbPath({
                    title: 'Projecten/Werkgebieden',
                    crumbs: [{ name: 'Project: ' + title, link: '/projecten?page='+page+'&rowsPerPage='+rowsPerPage }],
                })
            );
        },
        fetchInspectionAssigns: () => {
            dispatch(fetchInspectionAssigns());
        },
    };
};


class ProjectDetailsView extends React.Component<Props, State> {
    props: Props;
    state: State = {
        page: 0,
        rowsPerPage: 10,
        inspectionsAssignsByProject: [],
        columns: [
            { id: 'deployed', label: 'Uitgestuurd', size: '15%' },
            { id: 'logo', label: '', size: '48px' },
            { id: 'title', label: 'Titel', size: '20%' },
            { id: 'project', label: 'Project/werkgebied', size: '20%' },
            { id: 'deploydBy', label: 'Uit te voeren door', size: '15%' },
            { id: 'dateDone', label: 'Deadline', size: '15%' },
            { id: 'remarks', label: 'Opmerking', size: '10%' },
            { id: 'completed', label: 'Resultaat', size: '20%' },
        ],
    };

    componentDidMount() {
        const { t } = this.props;

        this.setState({
            columns: [
                { id: 'deployed', label: t('Send out'), size: '15%' },
                { id: 'logo', label: '', size: '48px' },
                { id: 'title', label: t('Title'), size: '20%' },
                { id: 'project', label: t('Project/work area'), size: '20%' },
                { id: 'deploydBy', label: t('To be executed by'), size: '15%' },
                { id: 'dateDone', label: t('Deadline'), size: '15%' },
                { id: 'remarks', label: t('Comment'), size: '10%' },
                { id: 'completed', label: t('Result'), size: '20%' },
            ],
        })

        this.props.fetchProjects();
        this.props.setCrumbPath(
            this.props.project ? this.props.project.omschrijving : 'Project'
        );
        this.getInspectionsByProjectId();

    }

    componentWillReceiveProps(newProps: Props) {
        if (
            this.props.periodType !== newProps.periodType ||
            this.props.periodYear !== newProps.periodYear ||
            this.props.periodMonth !== newProps.periodMonth ||
            this.props.periodWeek !== newProps.periodWeek
        ) {
            this.onFilterChanged(newProps);
        }
        if (this.props.toolbox !== newProps.toolbox) {
            this.props.setCrumbPath(
                newProps.toolbox ? newProps.toolbox.title : 'Toolbox'
            );
            this.onFilterChanged(newProps);
        }
    }

    setPage = (page) => {
        console.log("INFO: ProjectDetailView page = " + page);
        // Store page in state
        this.setState({ page: page });
    }
    setRowsPerPage = (rowsPerPage) => {
        console.log("INFO: ProjectDetailView rowsPerPage = " + rowsPerPage);
        // Store rowsPerPage in state
        this.setState({ rowsPerPage: rowsPerPage });
    }

    onItemClicked = (row, event) => {
        let target = '/werkplekinspectie/details/' + row.id + '#resultaten?project=' + this.props.match.params.id
        this.props.goTo(target);
    };

    mapInspectionAssignToRow = (inspectionAssign) => {
        const { t } = this.props;
        const everythingOk =
            inspectionAssign.result != null && inspectionAssign.result.results &&
            inspectionAssign.result.results.filter(
                (result) => result.result === 'nok'
            ).length === 0;
        const complete =
            inspectionAssign.result !== null && inspectionAssign.result.complete;
        let remarkCount =
            (inspectionAssign.result === null || inspectionAssign.result.results === undefined)
                ? 0
                : inspectionAssign.result.results.filter(
                      (result) => result.remark.text
                  ).length;
        let tooltip;
        if (inspectionAssign.result == null) {
            tooltip = t('Workplace inspection is not yet completed');
        } else if (everythingOk && remarkCount === 0) {
            tooltip = t('Everyting is fine no comments');
        } else if (everythingOk && remarkCount === 1) {
            tooltip = t('Everything is fine, with comment');
        } else if (everythingOk && remarkCount > 1) {
            tooltip =
                t('Everything is fine, but there are') +
                remarkCount +
                t('comments');
        } else if (!everythingOk && remarkCount === 1) {
            tooltip = t('Something is wrong, with comment');
        } else if (!everythingOk) {
            tooltip =
                t('Something is wrong, there are') +
                remarkCount +
                t('comments');
        }

        let unsafe = false;
        if (inspectionAssign.result !== null && inspectionAssign.result.remarks !== undefined ) {
            if (inspectionAssign.result.remarks.unsafe === true) {
                unsafe = true;
            } else {
                inspectionAssign.result.results.forEach((result) => {
                    if (result.remark.unsafe === true) {
                        unsafe = true;
                    }
                });
            }
        }

        let inspection = null;
        if (this.props.inspections != null && this.props.inspections.length > 0) {
            inspection = this.props.inspections[inspectionAssign.inspectionId];
        }

        if (inspectionAssign.result != null && inspectionAssign.result.date) {
            tooltip +=
                t('Completed') +
                moment().to(moment(inspectionAssign.result.date));
        }

        let text = '';
        if (inspectionAssign.result == null) {
            text = t('Not completed');
        } else if (unsafe) {
            text = t('Unsafe situation');
        } else if (everythingOk) {
            tooltip = t('Correct');
            text = t('Correct');
        } else if (!everythingOk) {
            tooltip = t('Not correct');
            text = t('Not correct');
        }

        const completed = (
            <div className={'tooltip'}>
                <span className={'tooltiptext'}>{tooltip}</span>
                <span
                    className={classnames({
                        [classes.finishedCount]: true,
                        [classes.finishedNone]: !everythingOk,
                        [classes.finishedAll]: everythingOk,
                        [classes.notComplete]: !complete,
                    })}
                    style={{ fontWeight: '300' }}
                >
                    {unsafe && (
                        <WarningIcon
                            style={{
                                width: '14px',
                                height: '14px',
                                position: 'relative',
                                top: '2px',
                                marginRight: '5px',
                            }}
                        />
                    )}
                    {text}
                </span>
            </div>
        );

        let projectEmpty =
            inspectionAssign.result == null &&
            (this.props.project &&
                    this.props.project.omschrijving === '');

        return {
            ...inspectionAssign,
            deployed: !(inspectionAssign.deployed || inspectionAssign.scheduled)
                ? '-'
                : moment(
                      inspectionAssign.deployed || inspectionAssign.scheduled
                  ).format('L HH:mm'),
            title: inspectionAssign.inspection.title,
            project: projectEmpty ? (
                <div className={'tooltip'}>
                    {t('No project added')}
                    <Button
                        style={{
                            width: '10px',
                            height: '10px',
                            backgroundColor: 'Transparent',
                            marginLeft: '-30px',
                        }}
                        onClick={(event) =>
                            this.onButtonInsideRowClick(
                                event,
                                inspectionAssign.id
                            )
                        }
                    >
                        <span
                            className={'tooltiptext'}
                            style={{ left: '10px' }}
                        >
                            {t('Add project')}
                        </span>
                        <img src="/assets/images/edit_icon.png" alt={t('Change')} />
                    </Button>
                </div>
            ) : this.props.project ? (
                this.props.project.externalId +
                ': ' +
                this.props.project.omschrijving
            ) : (
                '-'
            ),
            deploydBy: inspectionAssign.user.shortname,
            completed: completed,

            dateDone:
                inspectionAssign.deadline
                    ? moment(inspectionAssign.deadline).format(t('dateTime'))
                    : '-',
            showMenu: true,
            remarks: inspectionAssign.result === null ? '-' : inspectionAssign.result.totalComments,
            logo:
                (inspection && inspection.source) === 'cumela' ? (
                    <div className={'tooltip'}>
                        <span
                            className={'tooltiptext'}
                            style={{ left: '30px' }}
                        >
                            {t('Cumela inspection')}
                        </span>
                        <img
                            height="20"
                            width="20"
                            src="/assets/images/cumela-logo.png"
                            alt={t('Cumela logo')}
                        />
                    </div>
                ) : (inspection && inspection.source === 'global') ? (
                    <div className={'tooltip'}>
                        <span
                            className={'tooltiptext'}
                            style={{ left: '10px' }}
                        >
                            {t('Veilig vakwerk inspection')}
                        </span>
                        <img
                            height="20"
                            width="20"
                            src="/assets/images/vvw-admin-logo.svg"
                            alt={t('Admin logo')}
                        />
                    </div>
                ) : (inspection && inspection.rootInspectionSource === 'global') ||
                    (inspection && inspection.rootInspectionSource === 'cumela') ? (
                    <div className={'tooltip'}>
                        <span
                            className={'tooltiptext'}
                            style={{ left: '10px' }}
                        >
                            {t('Custom inspection')}
                        </span>
                        <img
                            height="20"
                            width="20"
                            src="/assets/images/aangepast.svg"
                            alt={t('Custom logo')}
                        />
                    </div>
                ) : (
                    <div className={'tooltip'}>
                        <span
                            className={'tooltiptext'}
                            style={{ left: '25px' }}
                        >
                            {t('Own inspection')}
                        </span>
                        <img
                            height="20"
                            width="20"
                            src="/assets/images/vvw-logo.svg"
                            alt={t('Own logo')}
                        />
                    </div>
                ),
        };
    };

    getFirstletterLastname = (user) => {
        let eersteletter = user.firstname;
        let res = eersteletter.slice(0, 1);
        let tussenvoegsel = user.middlename ? user.middlename + ' ' : '';
        let achternaam = user.lastname;

        return res + '. ' + tussenvoegsel + achternaam;
    };

    getInspectionsByProjectId(){
        const projectId = this.props.match.params.id;
        fetchInspectionAssignByProject(projectId)
            .then((entities) => {
                this.setState({
                    inspectionAssigns: entities.body._embedded.inspectionAssign
                });
            })
            .catch((error) => {
                throw error;
            });
    }

    inspectionAssignedSorter = () => {
        return {
            dateDone: (a, b) => {
                a = a.deadline;
                b = b.deadline;

                if (a == null) {
                    return -1;
                }
                if (b == null) {
                    return 1;
                }

                a = moment(a).valueOf();
                b = moment(b).valueOf();

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            title: (a, b) => {
                a = a.inspection.title.toLowerCase();
                b = b.inspection.title.toLowerCase();

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            completed: (a, b) => {
                if (a.result === null) {
                    return -1;
                } else if (b.result === null) {
                    return 1;
                } else {
                    let remarkCountA = a.result.results.filter(
                        (result) => result.remark.text
                    ).length;
                    let remarkCountB = b.result.results.filter(
                        (result) => result.remark.text
                    ).length;

                    if (remarkCountA > remarkCountB) {
                        return 1;
                    } else if (remarkCountA < remarkCountB) {
                        return -1;
                    } else {
                        return 0;
                    }
                }
            },
            logo: (a, b) => {
                if (a.inspection.source > b.inspection.source) {
                    return 1;
                } else if (a.inspection.source < b.inspection.source) {
                    return -1;
                } else {
                    return 0;
                }
            },
        };
    };

    render() {
        const { project, t } = this.props;
        const { columns } = this.state;
        const tableActionHeader = <TableActionHeader />;


        return (
            <div className="Statistics">
                <AppModule loading={false} prepend={tableActionHeader}>
                    {project &&
                        this.state.inspectionAssigns && (
                            <EnhancedTable
                                defaultOrderBy="deployed"
                                defaultOrder="desc"
                                className="click"
                                hover
                                columns={columns}
                                rows={
                                    project
                                        ? this.state
                                              .inspectionAssigns
                                        : []
                                }
                                formatter={this.mapInspectionAssignToRow}
                                actions={[]}
                                sorter={this.inspectionAssignedSorter()}
                                //emptyState={this.state.searchtext.length > 0 ?  'Er zijn geen zoekresultaten.':'Er zijn nog geen werkplekinspecties uitgestuurd.'}
                                emptyState={
                                    t('No workplace inspection sent out.')
                                }
                                onClick={this.onItemClicked}
                                onPageChange={this.setPage}
                                onRowsPerPageChange={this.setRowsPerPage}
                                startPage={this.state.startPage}
                                startRowsPerPage={this.state.startRowsPerPage}

                            />
                        )}
                </AppModule>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    
    withTranslation()
)(ProjectDetailsView);
