// @flow
import React from 'react';
import { List, Map } from 'immutable';
import PaginatedSortFilterView from '../../Util/PaginatedSortFilterView';
import PropTypes from 'prop-types';
import StatisticsRowToolboxAssign from '../../StatisticsView/StatisticsRow/StatisticsRowToolboxAssign';
import { connect } from 'react-redux';
import { fetchToolboxAssigns } from '../../../actions/toolboxActions';
import moment from 'moment';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

type Props = {
    statisticsData: List<TStateStatisticsListData>,
    onItemClick: (number) => mixed,
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchToolboxAssigns: () => {
            dispatch(fetchToolboxAssigns());
        },
    };
};

class StatisticsListDistributors extends React.PureComponent<Props, void> {
    state: State = {
        newToolboxAssign: [],
    };

    static contextTypes = {
        router: PropTypes.object,
    };

    refreshToolboxAssignToolboxMeeting() {
        let newToolboxAssign = [];
        this.props.toolboxAssigns.forEach((ta) => {
            let isNew = true;
            if (newToolboxAssign) {
                newToolboxAssign.forEach((nta) => {
                    if (
                        ta.toolboxMeeting === true &&
                        nta.toolboxMeeting === true
                    ) {
                        let before = moment(ta.deployed).add(5, 'second');
                        let after = moment(ta.deployed).subtract(5, 'second');
                        if (
                            ta.toolboxMeetingDate === nta.toolboxMeetingDate &&
                            moment(nta.deployed).isBefore(before) &&
                            moment(nta.deployed).isAfter(after)
                        ) {
                            isNew = false;
                        }
                    }
                });
            }
            if (isNew === true) {
                newToolboxAssign.push(ta);
            }
        });

        this.setState({
            newToolboxAssign: newToolboxAssign,
        });
    }

    componentDidMount() {
        this.refreshToolboxAssignToolboxMeeting();
    }

    componentWillReceiveProps = (nextProps: Props) => {
        this.refreshToolboxAssignToolboxMeeting();
    };

    rowProvider = (toolboxAssign) => {
        if (!toolboxAssign || !toolboxAssign.toolbox) {
            return undefined;
        }

        return (
            <StatisticsRowToolboxAssign
                isToolboxMeetingChecked={
                    this.props ? this.props.isToolboxMeetingChecked : false
                }
                isNoToolboxMeetingChecked={
                    this.props ? this.props.isNoToolboxMeetingChecked : false
                }
                visible={toolboxAssign.toolboxMeeting}
                key={toolboxAssign.id}
                handleRowClick={() => this.onRowClick(toolboxAssign)}
                toolboxAssign={toolboxAssign}
                toolboxData={this.props.toolbox}
                toolBoxes={this.props.toolBoxes}
            />
        );
    };

    headerProvider = () => {
        const { t } = this.props;
        return {
            deployed: t('Date sent'),
            version: t('Version'),
            fullnameUser: t('Employee'),
            isToolboxMeeting: t('Toolboxmeeting'),
            finished: t('Completed on'),
            Score: this.props.isToolboxMeetingChecked
                ? t('Total completed')
                : t('Score'),
        };
    };

    onRowClick = (toolboxAssign) => {
        this.props.onItemClick(toolboxAssign);
    };

    countParticipantsToolboxMeeting = (nta) => {
        let listParticipants = [];

        this.props.toolboxAssigns.forEach((ta) => {
            let before = moment(ta.deployed).add(5, 'second');
            let after = moment(ta.deployed).subtract(5, 'second');
            if (
                ta.toolboxMeetingDate === nta.toolboxMeetingDate &&
                moment(nta.deployed).isBefore(before) &&
                moment(nta.deployed).isAfter(after)
            ) {
                listParticipants.push(ta);
            }
        });

        /*if (nta && nta.meetingid){
            //New way of getting toolboxmeetings
            this.props.toolboxAssigns.forEach(ta=> {
                if (ta.meetingid === nta.meetingid) {
                    listParticipants.push(ta);
                }
            });
        } else {
            //Legacy toolboxmeetings
            this.props.toolboxAssigns.forEach(ta=> {
                let before = moment(ta.deployed).add(5, 'second');
                let after = moment(ta.deployed).subtract(5, 'second');
                if (ta.toolboxMeetingDate === nta.toolboxMeetingDate && moment(nta.deployed).isBefore(before) && moment(nta.deployed).isAfter(after)) {
                    listParticipants.push(ta);
                }
            });
        }*/

        return listParticipants;
    };

    render() {
        let sorters: Map<
            string,
            (TStateStatisticsListData, TStateStatisticsListData) => number
        > = Map();
        sorters = sorters.set('Score', (toolboxAssignA, toolboxAssignB) => {
            if (
                toolboxAssignA.result === null &&
                toolboxAssignB.result === null
            ) {
                return 0;
            }
            if (toolboxAssignA.result === null) {
                return -1;
            }
            if (toolboxAssignB.result === null) {
                return 1;
            }
            if (
                toolboxAssignA.result.answers.filter(
                    (result) => result.correct === true
                ).length >
                toolboxAssignB.result.answers.filter(
                    (result) => result.correct === true
                ).length
            ) {
                return 1;
            }
            if (
                toolboxAssignA.result.answers.filter(
                    (result) => result.correct === true
                ).length <
                toolboxAssignB.result.answers.filter(
                    (result) => result.correct === true
                ).length
            ) {
                return -1;
            }
            if (
                toolboxAssignA.result.answers.filter(
                    (result) => result.correct === true
                ).length ===
                toolboxAssignB.result.answers.filter(
                    (result) => result.correct === true
                ).length
            ) {
                if (
                    toolboxAssignA.result.answers.length >
                    toolboxAssignB.result.answers.length
                ) {
                    return 1;
                }
                if (
                    toolboxAssignA.result.answers.length <
                    toolboxAssignB.result.answers.length
                ) {
                    return -1;
                }
            }
            return 0;
        });

        // const data = List(this.props.toolboxAssigns.map(item => Object.assign(item, {
        //     finished: item.result?item.result.date: null
        // })));

        let dataGrouped = List(
            this.state.newToolboxAssign
                .filter((toolbox) =>
                    this.props.searchtext
                        ? toolbox.user.fullname
                              .replace('  ', ' ')
                              .toLowerCase()
                              .indexOf(this.props.searchtext.toLowerCase()) > -1
                        : true
                )
                .filter((toolbox) =>
                    this.props.isToolboxMeetingChecked
                        ? toolbox.toolboxMeeting === true
                        : true
                )
                .filter((toolbox) =>
                    this.props.isNoToolboxMeetingChecked
                        ? toolbox.toolboxMeeting === false
                        : true
                )
                .filter((toolbox) =>
                    this.props.afgerond !== 0
                        ? this.props.afgerond === 1
                            ? toolbox.result && toolbox.result.complete
                            : toolbox.result == null || !toolbox.result.complete
                        : true
                )
                .map((item) =>
                    Object.assign(item, {
                        finished: item.result ? item.result.date : null,
                        listParticipants:
                            item.toolboxMeeting === true
                                ? this.countParticipantsToolboxMeeting(item)
                                : 0,
                        expanded: false,
                    })
                )
        );

        let dataList = List(
            this.props.toolboxAssigns
                .filter((toolbox) =>
                    this.props.searchtext
                        ? toolbox.user.fullname
                              .replace('  ', ' ')
                              .toLowerCase()
                              .indexOf(this.props.searchtext.toLowerCase()) > -1
                        : true
                )
                .filter((toolbox) =>
                    this.props.isToolboxMeetingChecked
                        ? toolbox.toolboxMeeting === true
                        : true
                )
                .filter((toolbox) =>
                    this.props.isNoToolboxMeetingChecked
                        ? toolbox.toolboxMeeting === false
                        : true
                )
                .filter((toolbox) =>
                    this.props.isToolboxMeetingChecked
                        ? toolbox.toolboxMeeting === true
                        : true
                )
                .filter((toolbox) =>
                    this.props.afgerond !== 0
                        ? this.props.afgerond === 1
                            ? toolbox.result && toolbox.result.complete
                            : toolbox.result == null || !toolbox.result.complete
                        : true
                )
                .map((item) =>
                    Object.assign(item, {
                        finished: item.result ? item.result.date : null,
                        listParticipants:
                            item.toolboxMeeting === true
                                ? this.countParticipantsToolboxMeeting(item)
                                : 0,
                        expanded: false,
                    })
                )
        );

        const data = this.props.isToolboxMeetingChecked
            ? dataGrouped
            : dataList;

        return (
            <PaginatedSortFilterView
                data={this.props.toolboxAssigns.length === 0 ? List([]) : data}
                headerProvider={this.headerProvider}
                rowProvider={this.rowProvider}
                initialSort="deployed"
                asc={false}
                onRowClick={this.onRowClick}
                sorters={sorters}
            />
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(StatisticsListDistributors);
