// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import {setCrumbPath, showSnackbar} from '../../actions';
import classes from './TaskView.module.scss';
import {apiDeleteTaskById, fetchTasksForIncident, fetchTasksForInspectionAssign} from "../../lib/api";
import i18n from "i18next";
import EnhancedTable from "../EnhancedTable";
import moment from "../../lib/moment";
import ConfirmDialog from "../ConfirmDialog";
import DialogEditTask from "./DialogEditTask";
import classnames from "classnames";
import {Fab} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import WarningIcon from "@mui/icons-material/Warning";


type Props = {
};

type State = {

};

const mapStateToProps = (store, props) => {
    return {
       employees: store.entities.employees,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCrumbPath: () => {
            dispatch(setCrumbPath({ title: 'Tasks' }));
        }
    };
};

class TaskView extends React.Component<Props, State> {
    props: Props;
    state: state = {
        confirmDialogOpen: false,
        editDialogOpen: false,
        editDialogViewOnly: true,
        selectedTask: null,
        actions: [
            { id: 'edit', label: 'Wijzigen' },
            { id: 'delete', label: i18n.t('Delete') },
        ],
        columns: [
            {
                id: 'for',
                numeric: false,
                size: '20%',
                label: 'Voor Medewerker',
            },
            {
                id: 'date',
                numeric: false,
                size: '15%',
                label: 'Datum',
            },
            {
                id: 'title',
                numeric: false,
                size: '50%',
                label: 'Title',
            },
            {
                id: 'status',
                numeric: false,
                size: '15%',
                label: "Status",
            },
        ],
    };

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        if (this.props.inspectionAssignId) {
            fetchTasksForInspectionAssign(this.props.inspectionAssignId)
                .then((entities) => {
                    const sortedTasks = entities.body._embedded.tasks.sort((a,b) => {
                        if (a.date < b.date) return -1;
                        if (a.date > b.date) return 1;
                        return 0;
                    })


                    this.setState({
                        tasks: sortedTasks
                    });

                })
                .catch((error) => {
                    showSnackbar(
                        `${i18n.t(
                            'Unknown error while fetching tasks for inspectionAssignId'
                        )}: ` + error.message
                    )
                    throw error;
                });
        }
        if (this.props.incidentId) {
            fetchTasksForIncident(this.props.incidentId)
                .then((entities) => {
                    const sortedTasks = entities.body._embedded.tasks.sort((a,b) => {
                        if (a.date < b.date) return -1;
                        if (a.date > b.date) return 1;
                        return 0;
                    })
                    this.setState({
                        tasks: sortedTasks
                    });

                })
                .catch((error) => {
                    showSnackbar(
                        `${i18n.t(
                            'Unknown error while fetching tasks for inspectionAssignId'
                        )}: ` + error.message
                    )
                    throw error;
                });

        }
    }

    deleteTask = (task) => {
        apiDeleteTaskById(task)
            .then((entities) => {
                const newList = this.state.tasks.filter((myTask) => (myTask.id !== task));
                this.setState({
                    tasks: newList,
                    taskToHandle: null,
                });

            })
            .catch((error) => {
                showSnackbar(
                    `${i18n.t(
                        'Unknown error while deleting tasks'
                    )}: ` + error.message
                )
                throw error;
            });

    }

    updateList = (updatedTask) => {
        let updatedList = []
        let updated = false;
        this.state.tasks.forEach((task) => {
            if (task.id === updatedTask.id) {
                updatedList.push(updatedTask);
                updated = true;
            } else {
                updatedList.push(task);
            }
        })
        if (updated === false) {
            // This is a new task
            updatedList.push(updatedTask);
        }
        this.setState({
            tasks: updatedList
        });
        // this.loadData();
    }
    onRowClick = (task, event) => {
        // View task
        this.setState({
            selectedTask: task,
            editDialogViewOnly: true,
        });
        this.showEditDialog();

    }

    handleAction = (event, action) => {
        if (action.id === 'edit') {
            const selectedTask = this.state.tasks.find((myTask) => (myTask.id === action.rowId));
            this.setState({
                selectedTask: selectedTask,
                editDialogViewOnly: false,
            });

            this.showEditDialog();

        } else if (action.id === 'delete') {
            this.setState({
                taskToHandle: action.rowId,
            });

            this.showConfirmDialog();
        }
    };

    hideConfirmDialog = () => {
        this.setState({
            confirmDialogOpen: false,
        });
    };

    showConfirmDialog = () => {
        this.setState({
            confirmDialogOpen: true,
        });
    };

    hideEditDialog = () => {
        this.setState({
            editDialogOpen: false,
            editDialogViewOnly: true
        });
    };

    showEditDialog = () => {
        this.setState({
            editDialogOpen: true,
        });
    };
    showAddDialog = () => {
        this.setState({
            selectedTask: null,
            editDialogViewOnly: false,
            editDialogOpen: true,
        });
    };


    mapTaskToRow = (task  ) => {
        const completed = (
            <div>
                <span
                    className={classnames({
                        [classes.taskFinished]: task.taskFeedback.done,
                        [classes.taskNotFinished]: !task.taskFeedback.done,
                    })}
                    style={{ fontWeight: '300' }}
                >
                    {task ? task.taskFeedback.done ? i18n.t('StatusDone') : i18n.t('StatusNotDone') : '-' }
                </span>
            </div>
        );

        return {
            ...task,
            for: task
                ? task.user
                    ? task.user.fullname
                    : '-'
                : '0',
            date: task
                ? task.date
                    ? moment(task.date).format('L HH:mm')
                    : '-'
                : '-',
            title: task
                ? task.title
                : '-',
            status: completed,
        };
    };

    taskSorter = () => {
        return {
            date: (a, b) => {
                a = a.date;
                b = b.date;

                if (a == null) {
                    return -1;
                }
                if (b == null) {
                    return 1;
                }

                a = moment(a).valueOf();
                b = moment(b).valueOf();

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            for: (a, b) => {
                a = a.user.fullname.toLowerCase();
                b = b.user.fullname.toLowerCase();

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            title: (a, b) => {
                a = a.title.toLowerCase();
                b = b.title.toLowerCase();

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            status: (a, b) => {
                a = a.taskFeedback.done;
                b = b.taskFeedback.done;

                if (a === true && b === false) {
                    return 1;
                } else if (a === false && b === true) {
                    return -1;
                } else {
                    return 0;
                }
            },

        };
    };

    render() {
        const { t } = i18n;
        const { tasks, actions, columns } = this.state;

        return (
            <div className="Task">
                <div className={classnames({[classes.button]: true, 'mui-fixed': true,})}>
                    <Fab onClick={this.showAddDialog}>
                        <AddIcon />
                    </Fab>
                </div>

                <DialogEditTask
                    open={this.state.editDialogOpen}
                    viewOnly={this.state.editDialogViewOnly}
                    handleClose={this.hideEditDialog}
                    employees={this.props.employees}
                    task={this.state.selectedTask}
                    callback={this.updateList}
                    inspectionAssignId={this.props.inspectionAssignId}
                    incidentId={this.props.incidentId}
                />
                <ConfirmDialog
                    open={this.state.confirmDialogOpen}
                    handleClose={this.hideConfirmDialog}
                    deletetask={this.state.taskToHandle}
                    deleteTaskFunction={this.deleteTask}
                />
                <EnhancedTable
                    hover
                    columns={columns}
                    onClick={this.onRowClick}
                    defaultOrder="desc"
                    defaultOrderBy={t('Description')}
                    rows={tasks}
                    formatter={this.mapTaskToRow}
                    actions={actions}
                    onAction={this.handleAction}
                    sorter={this.taskSorter()}
                    emptyState={
                        tasks
                            ? t('No tasks')
                            : t('No tasks')
                    }

                    onPageChange={this.props.setPage}
                    onRowsPerPageChange={this.props.setRowsPerPage}
                    startPage={1}
                    startRowsPerPage={10}

                />

            </div>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(TaskView);
