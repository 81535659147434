// @flow
import React from 'react';
import { List } from 'immutable';
import './UploadDropdownDialog.css';
import {
    Table,
    TableBody,
    Button,
    DialogContent,
    DialogActions,
    DialogContentText,
} from '@mui/material';
import FileUpload from '../FileUpload';
import Loader from '../../Loader';
import employeeFileToDownload from '../../../Files/CSVMedewerkerExample.csv';
import employeeFileToDownloadWithLanguage from '../../../Files/CSVMedewerkerExampleWithLanguage.csv';
import projectFileToDownload from '../../../Files/CSVProjectExample.csv';

import employeeImg from '../../../images/imports/employee.png';
import projectImg from '../../../images/imports/project.png';
import { fetchCSV } from '../../../lib/api';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { getLanguages } from '../../../lib/utils';


type Props = {
    onUpload: (file: any) => mixed,
    acknowledgeImport: (file: any) => mixed,
    inProgress?: boolean,
    diff: {
        removed: List<TEmployee>,
        added: List<TEmployee>,
        edited: List<TEmployee>,
        conflicts: List<TEmployee>,
    },
    mapToDiffRow: (list: List<TEmployee>) => List<TEmployee>,
    handleClose: () => mixed,
};

type State = {
    file?: any,
};

class UploadDropdownDialog extends React.PureComponent<Props, State> {
    state = {};

    componentDidMount() {
        if (this.props.toolbox) {
            this.props.initDraft(this.props.employee);
        }
    }

    componentWillReceiveProps(newprops: Props) {}

    acknowledgeImport = () => {
        this.props.acknowledgeImport(this.state.file);
    };

    onUpload = (file: any) => {
        this.setState({
            file,
        });
        this.props.onUpload(file);
    };

    downloadExampleFileEmployee = () => {
        const languages = getLanguages();

        setTimeout(() => {
            const response = {
                file:
                    languages.length > 1
                        ? employeeFileToDownloadWithLanguage
                        : employeeFileToDownload,
            };
            window.location.href = response.file;
        }, 100);
    };

    downloadExampleFileProject = () => {
        setTimeout(() => {
            const response = {
                file: projectFileToDownload,
            };
            window.location.href = response.file;
        }, 100);
    };

    downloadEmployeeCsv = () => {
        let employees = [];

        this.props.employees.forEach((item) => {
            if (item.dtype && item.dtype === 'Extern') {
                employees.push({
                    externalId: item.externalId,
                    username: item.externalusername,
                    importType: item.importType,
                    firstname: item.firstname,
                    middlename: item.middlename,
                    lastname: item.lastname,
                    phonenumber: item.phonenumber,
                });
            }
        });

        fetchCSV({
            platform: 'VV',
            customer: 'VV',
            template: 'EMPLOYEE',
            options: {},
            data:
                employees.length > 0
                    ? employees.filter((e) => e.importType === 'IMPORT')
                    : this.props.employees.filter(
                          (e) => e.importType === 'IMPORT'
                      ),
            info: {},
        })
            .then((res) => {
                const url = URL.createObjectURL(res.body);
                const link = document.createElement('a');
                link.setAttribute('href', url);
                link.setAttribute('download', 'medewerkers.csv');
                link.click();
            })
            .catch((err) => console.log(err));
    };

    downloadProjectCsv = () => {
        fetchCSV({
            platform: 'VV',
            customer: 'VV',
            template: 'PROJECT',
            options: {},
            data: this.props.projects.filter((e) => e.importType === 'IMPORT'),
            info: {},
        })
            .then((res) => {
                const url = URL.createObjectURL(res.body);
                const link = document.createElement('a');
                link.setAttribute('href', url);
                link.setAttribute('download', 'projecten.csv');
                link.click();
            })
            .catch((err) => console.log(err));
    };

    render() {
        const { t } = this.props;
        let allOk = true;
        let removed = List();
        let added = List();
        let edited = List();
        let conflicts = List();
        if (this.props.diff) {
            removed = this.props.mapToDiffRow(this.props.diff.removed);
            added = this.props.mapToDiffRow(this.props.diff.added);
            if (
                this.props.diff.edited != null ||
                this.props.diff.conflicts != null
            ) {
                edited = this.props.mapToDiffRow(this.props.diff.edited);
                conflicts = this.props.mapToConflictRow(
                    this.props.diff.conflicts
                );
            }
        }

        let emailConflict = false;
        let idConflict = false;
        let languageConflict = false;

        if (conflicts.length > 0) {
            conflicts.forEach((conflict) => {
                if (conflict.props.conflictRow === 'email') {
                    emailConflict = true;
                } else if (conflict.props.conflictRow === 'externalId') {
                    idConflict = true;
                } else if (conflict.props.conflictRow === 'language') {
                    languageConflict = true;
                }
            });
        }

        return (
            <div style={{ height: '100%' }}>
                <DialogContent
                    style={{
                        height: 'calc(100% - 56px)',
                        boxSizing: 'border-box',
                    }}
                >
                    <Loader
                        size={100}
                        loading={this.props.inProgress}
                        className="loader-upload"
                    >
                        {this.props.diff ? (
                            this.props.diff.added.length !== 0 ||
                            this.props.diff.removed.length !== 0 ||
                            (this.props.diff.edited &&
                                this.props.diff.edited.length !== 0) ||
                            (this.props.diff.conflicts &&
                                this.props.diff.conflicts.length !== 0) ? (
                                <div
                                    style={{
                                        width: '100%',
                                        marginBottom: '40px',
                                        overflowY: 'hidden',
                                    }}
                                >
                                    {conflicts.length > 0 ? (
                                        <h2 style={{ color: '#FF0000' }}>
                                            {t('Conflicts')}
                                        </h2>
                                    ) : (
                                        ''
                                    )}
                                    {conflicts.length > 0 && (
                                        <div style={{ color: '#FF0000' }}>
                                            {t(
                                                'Please resolve the conflicts before you can import.'
                                            )}{' '}
                                        </div>
                                    )}
                                    <div
                                        style={{
                                            marginTop: '20px',
                                            marginBottom: '20px',
                                        }}
                                    >
                                        {emailConflict && (
                                            <div style={{ color: '#FF0000' }}>
                                                <b>{t('Email conflict')}:</b>
                                                {t(
                                                    'This email address is already registered with Veilig Vakwerk'
                                                )}
                                            </div>
                                        )}
                                        {idConflict && (
                                            <div style={{ color: '#FF0000' }}>
                                                <b>{t('Id conflict')}:</b>
                                                {t(
                                                    'This id is already in use in your employee list'
                                                )}{' '}
                                            </div>
                                        )}
                                        {languageConflict && (
                                            <div style={{ color: '#FF0000' }}>
                                                <b>{t('Language conflict')}:</b>
                                                {t(
                                                    'The language is too long use abbreviations'
                                                )}{' '}
                                            </div>
                                        )}
                                    </div>
                                    {conflicts.length > 0 ? (
                                        <Table style={{ overflow: 'visible' }}>
                                            <TableBody>{conflicts}</TableBody>
                                        </Table>
                                    ) : (
                                        ''
                                    )}
                                    {added.length > 0 ? (
                                        <h2>{t('Added')}</h2>
                                    ) : (
                                        ''
                                    )}
                                    {added.length > 0 ? (
                                        <Table>
                                            <TableBody>{added}</TableBody>
                                        </Table>
                                    ) : (
                                        ''
                                    )}
                                    {removed.length > 0 ? (
                                        <h2>{t('Deleted')}</h2>
                                    ) : (
                                        ''
                                    )}
                                    {removed.length > 0 ? (
                                        <Table>
                                            <TableBody>{removed}</TableBody>
                                        </Table>
                                    ) : (
                                        ''
                                    )}
                                    {edited.length > 0 ? (
                                        <h2>{t('Modified')}</h2>
                                    ) : (
                                        ''
                                    )}
                                    {edited.length > 0 ? (
                                        <Table>
                                            <TableBody>{edited}</TableBody>
                                        </Table>
                                    ) : (
                                        ''
                                    )}
                                    {added.forEach((item) => {
                                        if (item.props.employee != null) {
                                            if (
                                                //eslint-disable-next-line
                                                !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                                                    item.props.employee.username
                                                )
                                            ) {
                                                allOk = false;
                                            }
                                        }
                                    })}
                                    {edited.forEach((item) => {
                                        if (
                                            item.props.employee.username != null
                                        ) {
                                            if (
                                                //eslint-disable-next-line
                                                !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                                                    item.props.employee.username
                                                )
                                            ) {
                                                allOk = false;
                                            }
                                        }
                                    })}
                                    {allOk === false && (
                                        <div style={{ color: '#FF0000' }}>
                                            {t(
                                                '1 or more e-mail addresses are not entered correctly, please check them and try again'
                                            )}
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div>
                                    {t(
                                        'There are no changes in this uploaded file'
                                    )}
                                </div>
                            )
                        ) : this.props.importType === 'employee' ? (
                            <div>
                                <DialogContentText key="text">
                                    {t(
                                        'Select the CSV file with the employee data to import.'
                                    )}
                                </DialogContentText>
                                <FileUpload
                                    key="file"
                                    style={{ margin: '16px 0 0 -16px' }}
                                    name={this.props.title}
                                    onUpload={this.onUpload}
                                    inProgress={this.props.inProgress}
                                    importType={this.props.importType}
                                />
                                <img
                                    src={employeeImg}
                                    alt="-"
                                    style={{ maxWidth: '100%' }}
                                />
                            </div>
                        ) : (
                            <div>
                                <DialogContentText key="text">
                                    {t(
                                        'Select the CSV file containing the project / work area data to import.'
                                    )}
                                </DialogContentText>
                                <FileUpload
                                    key="file"
                                    style={{ margin: '16px 0 0 -16px' }}
                                    name={this.props.title}
                                    onUpload={this.onUpload}
                                    inProgress={this.props.inProgress}
                                    importType={this.props.importType}
                                />
                                <img
                                    src={projectImg}
                                    alt="-"
                                    style={{ maxWidth: '100%' }}
                                />
                            </div>
                        )}
                    </Loader>
                </DialogContent>

                <DialogActions>
                    <Button
                        className="secondaryButton"
                        onClick={
                            this.props.importType === 'employee'
                                ? this.downloadExampleFileEmployee
                                : this.downloadExampleFileProject
                        }
                    >
                        {t('Download sample file')}
                    </Button>
                    <Button
                        className="secondaryButton"
                        onClick={
                            this.props.importType === 'employee'
                                ? this.downloadEmployeeCsv
                                : this.downloadProjectCsv
                        }
                        style={{ marginRight: '95px' }}
                    >
                        {t('Download current list')}
                    </Button>
                    <Button
                        className="secondaryButton"
                        key="Annuleren"
                        onClick={this.props.handleClose}
                         
                    >
                        {t('Cancel')}
                    </Button>
                    {this.props.diff &&
                        (this.props.diff.added.length > 0 ||
                            this.props.diff.removed.length > 0 ||
                            (this.props.diff.edited &&
                                this.props.diff.edited.length > 0) ||
                            (this.props.diff.conflicts &&
                                this.props.diff.conflicts.length > 0)) && (
                            <Button
                                className="secondaryButton"
                                key="Opslaan"
                                disabled={
                                    conflicts.length > 0 || allOk === false
                                }
                                onClick={this.acknowledgeImport}
                                 
                            >
                                {t('Save')}
                            </Button>
                        )}
                </DialogActions>
            </div>
        );
    }
}

export default compose(withTranslation())(UploadDropdownDialog);
