// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import InspectionOverview from '../InspectionOverview';
import InspectionAssignedOverview from '../InspectionAssignedOverview';
import InspectionEdit from '../InspectionEdit';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import InspectionScheduledOverview from '../InspectionScheduledOverview/InspectionScheduledOverview';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import classes from './Inspection.module.scss';
import colors from "../../lib/themes/theme.scss";

type Props = {
    inspectionAssigns: any,
    editInspection: Function,
    match: any,
};

type State = {
    tab: number,
};

const mapStateToProps = (store, props) => {
    const inspections = store.entities.inspections;
    const inspection = inspections.byId[props.match.params.id];

    return {
        inspection: inspection,
        inspections: inspections
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        editInspection: (id) => {
            dispatch(push('/werkplekinspecties/' + id));
        },
    };
};

class Inspection extends React.Component<Props, State> {
    props: Props;
    state: State = {
        tab: 0
    };

    handleTabChange = (event: any, value: number) => {
        this.setState({ tab: value });
    };

    componentDidMount() {
        const tabs = ['#overzicht', '#ingepland', '#uitgestuurd'];

        if (this.props.location.hash) {
            const hash = this.props.location.hash.split('?')[0];

            this.setState({
                tab: tabs.indexOf(hash),
            });
        }
    }

    render() {
        const paramId = this.props.match.params.id;
        const showDetailDialog = paramId !== undefined;
        const { inspection, t } = this.props;

        const { tab} = this.state;

        return (
            <div>
                <Tabs
                    value={tab}
                    onChange={this.handleTabChange}
                    className={classes.root}
                    indicatorColor={colors.primaryColor}
                    textColor="secondary"
                    sx={{color: colors.mainTabsColor, '& .Mui-selected': { color: colors.mainTabsSelectedColor } }}
                >
                    <Tab label={t('Workplace inspections')} href="#overzicht" />
                    <Tab label={t('Scheduled')} href="#ingepland" />
                    <Tab label={t('Sent out')} href="#uitgestuurd" />
                </Tabs>

                {tab === 0 && <InspectionOverview />}
                {tab === 1 && (
                    <InspectionScheduledOverview inspections={this.props.inspections}/>
                )}
                {tab === 2 && (
                    <InspectionAssignedOverview inspections={this.props.inspections}/>
                )}
                <>
                    <InspectionEdit
                        open={showDetailDialog}
                        inspection={inspection}
                    />
                </>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(Inspection);
