// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';


import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { setCrumbPath, editEmployee } from '../../../actions';
import EditIcon from '@mui/icons-material/Edit';

import EmployeeInformation from '../EmployeeInformation';
import AppModule from '../../AppModule/AppModule';
import TableActionHeader from '../../TableActionHeader/TableActionHeader';
import { IconButton } from '@mui/material';
import { push } from 'connected-react-router';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import classes from './EmployeeDetailView.module.scss';
import colors from "../../../lib/themes/theme.scss";


type Props = {
    match: any,
    employee?: any,
    fetchSectors: () => mixed,
    fetchCategories: () => mixed,
    fetchEmployees: () => mixed,
    setCrumbPath: (employeeName: string) => void,
};

type State = {
    tab: number,
};


const mapStateToProps = (store, props) => {
    const employees = store.entities.employees;
    const employeeId = props.match.params.employeeId;
    const page = props.match.params.page;
    const rowsPerPage = props.match.params.rowsPerPage;

    const employee = employees.byId[employeeId];

    return {
        page,
        rowsPerPage,
        employee: employee,
        employees: employees,
        editemployee: store.drafts.employees.editemployee,
        allSectors: store.entities.sectors,
        allCategories: store.entities.categories,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        editEmployee: bindActionCreators(editEmployee, dispatch),
        setCrumbPath: (employeeName: string, page: number, rowsPerPage: number) => {
            console.log("INFO: EmmployeeDetailView: setCrumbPath: page " + page);
            dispatch(
                setCrumbPath({
                    title: 'Medewerkers',
                    crumbs: [{ name: employeeName, link: `/medewerkers?rows=${rowsPerPage}&page=${page}`}],
                })
            );
        },
        goToDialog: () => {
            dispatch(push('#dialog'));
        },
    };
};

class EmployeeDetailView extends React.Component<Props, State> {
    props: Props;
    state: State = {
        tab: 0,
    };


    componentWillReceiveProps(nextProps) {
        if (
            nextProps.employee &&
            _.get(this, 'props.employee.id', null) !==
                _.get(nextProps, 'employee.id', null)
        ) {
            this.props.setCrumbPath(nextProps.employee.fullname, this.props.page, this.props.rowsPerPage);
        }
    }

    handleTabChange = (event: any, value: number) => {
        this.setState({ tab: value });
    };

    componentDidMount() {
        const tabs = ['#informatie'];

        if (this.props.location.hash) {
            const hash = this.props.location.hash;

            this.setState({
                tab: ~tabs.indexOf(hash) ? tabs.indexOf(hash) : 0,
            });
        }

        if (this.props.employee) {
            this.props.setCrumbPath(this.props.employee.fullname, this.props.page, this.props.rowsPerPage);
        }

    }

    editEmployee = () => {
        this.props.editEmployee(this.props.employee);
        this.props.goToDialog();
        this.setState({ showDetailDialog: true });
    };

    render() {
        const { employee, editemployee } = this.props;
        const { tab } = this.state;

        const editAction = (
            <IconButton
                onClick={this.editEmployee}
                className={classes.button}
                key="edit"
            >
                <EditIcon />
            </IconButton>
        );
        const tableActionHeader = (
            <TableActionHeader
                editAction={
                    employee && tab === 0 && employee.importType !== 'IMPORT'
                        ? editAction
                        : undefined
                }
            />
        );

        return (
            <div>
                <Tabs
                    value={tab}
                    onChange={this.handleTabChange}
                    className={classes.root}
                    indicatorColor={colors.primaryColor}
                    textColor="secondary"
                    sx={{'& .Mui-selected': { color: colors.mainTabsSelectedColor } }}
                >
                    <Tab label="INFO" href="#informatie" sx={{color: colors.mainTabsColor }}/>
                </Tabs>
                <AppModule loading={false} hasTabs prepend={tableActionHeader}>
                    {tab === 0 && (
                        <EmployeeInformation
                            employee={employee}
                            editemployee={editemployee}
                        />
                    )}
                </AppModule>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EmployeeDetailView);
