// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {connect} from 'react-redux';
import {
    AppBar,
    Button,
    Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    IconButton,
    Input,
    InputLabel,
    Radio,
    RadioGroup,
    Toolbar,
    Typography,
} from '@mui/material';
import Select from 'react-select';

import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import nl from 'date-fns/locale/nl'
import en from 'date-fns/locale/en-US'
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import {compose} from 'recompose';
import {withTranslation} from 'react-i18next';

import {
    closeDialog,
    deployToolbox,
    fetchEmployees,
    fetchToolboxesOverview,
    fetchUsergroups,
    getCurrentEmployee,
    getToolboxById,
    processForm,
    processFormFailure,
} from '../../actions';
import {getForm, getToolboxOverviewV2} from '../../selectors';
import LegacyThemeProvider from '../LegacyThemeProvider';
import Step from '@mui/material/Step/Step';
import Stepper from '@mui/material/Stepper/Stepper';
import StepLabel from '@mui/material/StepLabel/StepLabel';
import classnames from 'classnames';
import TitleDescription from '../TitleDescription/TitleDescription';
import CreditsBlock from './CreditsBlock';
import {TEmployee} from '../../reducers/employeesReducer';
import {bindActionCreators} from 'redux';
import AppModule from '../AppModule';
import {isUndefinedOrNull} from '../../lib/utils';
import classes from './DialogToolboxDeploy.module.scss';
import colors from "../../lib/themes/theme.scss";


type Props = {
    open: boolean,
    processing: boolean,
    error: any,
    getCurrentEmployee: () => TEmployee,
    getToolboxById: () => mixed,
    fetchToolboxes: () => void
};

type State = {
    form: any,
    submitting: boolean,
    noOfExtern: number,
    nrOfTopics: number,
    groups: Array<any>,
};


const mapStateToProps = (store) => {
    return {
        ...getForm('toolboxDeploy')(store),
        open: store.ui.dialogs.toolboxDeploy.open,
        initialId: store.ui.dialogs.toolboxDeploy.data.id,
        initialScheduled: store.ui.dialogs.toolboxDeploy.data.initialScheduled,
        toolbox: store.entities.toolbox,
        toolboxes: getToolboxOverviewV2(store).filter(
            (tlbx) => tlbx.published === 'PUBLISHED'
        ),
        employees: store.entities.employees,
        usergroups: store.entities.usergroups,
        currentEmpl: store.drafts.employees.currentUser,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUsergroups: () => {
            dispatch(fetchUsergroups());
        },
        fetchToolboxesSent: () => {
        },

        fetchToolboxes: () => {
            dispatch(fetchToolboxesOverview());
        },
        fetchEmployees: () => {
            dispatch(fetchEmployees('employeesAll'));
        },
        fetchExternEmployees: () => {
            dispatch(fetchEmployees('extern'));
        },
        fetchRegularEmployees: () => {
            dispatch(fetchEmployees());
        },
        getToolboxById: (toolboxId) => {
            dispatch(getToolboxById(toolboxId));
        },
        onSubmit: (
            toolbox,
            employees,
            scheduledDate,
            isToolboxMeeting,
            toolboxMeetingDate,
            toolboxMeetingLocation,
            toolboxMeetingChairman,
            toolboxMeetingTopic,
            groups
        ) => {
            dispatch(
                processForm(
                    'toolboxDeploy',
                    deployToolbox(
                        toolbox,
                        employees,
                        scheduledDate,
                        isToolboxMeeting,
                        toolboxMeetingDate,
                        toolboxMeetingLocation,
                        toolboxMeetingChairman,
                        toolboxMeetingTopic,
                        groups
                    )
                )
            );
        },
        onClose: () => {
            dispatch(closeDialog('toolboxDeploy'));
        },
        onError: (message) => {
            dispatch(processFormFailure('toolboxDeploy', { message }));
        },
        fetchCurrentUser: () => {
            dispatch(getCurrentEmployee());
        },
        getCurrentEmployee: bindActionCreators(getCurrentEmployee, dispatch),
    };
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


class DialogToolboxDeploy extends React.Component<Props, State> {
    props: Props;
    state: State = {
        form: {
            toolbox: '',
            scheduled: 'now',
            scheduledDate: null,
            isToolboxMeeting: false,
            toolboxMeetingDate: null,
            toolboxMeetingLocation: null,
            toolboxMeetingChairman: null,
            toolboxMeetingTopic: new Map(),
            newTempSubject: '',
        },
        nrOfTopics: 0,
        stepIndex: 0,
        groups: [],
        employeeItems: {
            manual: [],
        },
        submitting: false,
        noOfExtern: 0,
    };

    handleClose = (event) => {
        this.props.onClose();
        if (window.location.pathname === '/medewerkers') {
            if (window.location.hash === '#externen') {
                this.props.fetchExternEmployees();
            } else {
                this.props.fetchRegularEmployees();
            }
        }

        this.setState({
            employeeItems: {
                manual: [],
            },
            groups: [],
            noOfExtern: 0,
        });
    };

    handleCheckedChange = (name) => (event) => {
        const formState = { ...this.state.form };
        this.setState({ form: { ...formState, [name]: event.target.checked } });
    };

    handleChange = (inputId) => (event) => {
        if (inputId === 'toolboxMeetingLocation') {
            if (event && event.target.value.length > 105) {
                return null;
            }
        }

        if (inputId === 'toolboxMeetingChairman') {
            if (event && event.target.value.length > 105) {
                return null;
            }
        }

        const formState = { ...this.state.form };
        const maxLength = 90; // Setting the maxLength attr on the input field does not work, this is a workaround

        if (
            event.target.value.length < maxLength &&
            (inputId === 'toolboxMeetingTopic' ||
                inputId === 'toolboxMeetingLocation' ||
                inputId === 'toolboxMeetingChairman' ||
                inputId === 'newTopic')
        ) {
            this.setState({
                form: { ...formState, [inputId]: event.target.value },
            });
        }
    };

    handleChangeSingle = (single) => {
        const formState = { ...this.state.form };
        this.setState({ form: { ...formState, toolbox: single.value } });
        // this.props.getToolboxById(single.value);
    };

    handleAddTopic = (event) => {
        const formState = { ...this.state.form };
        let value = event.target.value;

        if (value) {
            let toolboxTopics = formState.toolboxMeetingTopic
                ? formState.toolboxMeetingTopic
                : new Map();
            toolboxTopics.set('topic' + this.state.nrOfTopics, value);

            this.setState({
                form: { ...formState, toolboxMeetingTopic: toolboxTopics },
                newTempSubject: '',
                nrOfTopics: this.state.nrOfTopics + 1,
            });
        }
    };

    handleNewSubjectTyping = (event) => {
        this.setState({ newTempSubject: event.target.value });
    };

    handleEditTopic = (event) => {
        const formState = { ...this.state.form };
        const value = event.target.value;
        const id = event.target.id;

        if (value) {
            if (value.length < 255) {
                let toolboxTopic = formState.toolboxMeetingTopic;
                toolboxTopic.set(id, value);

                this.setState({
                    form: { ...formState, toolboxMeetingTopic: toolboxTopic },
                });
            }
        } else {
            this.handleDeleteTopic(id);
        }
    };

    handleDeleteTopic = (id) => {
        const formState = { ...this.state.form };

        let toolboxTopic = formState.toolboxMeetingTopic;
        toolboxTopic.delete(id);

        this.setState({
            form: { ...formState, toolboxMeetingTopic: toolboxTopic },
        });
    };

    handleToolboxMeetingDateChange = (date) => {
        const formState = { ...this.state.form };
        this.setState({ form: { ...formState, toolboxMeetingDate: date } });
    };

    noDuplicates = (employee, index, array) => {
        let occurrences = 0;
        array.forEach((element) => {
            if (employee && element && element.id === employee.id)
                occurrences++;
        });
        return occurrences < 2;
    };

    handleSubmit = (event) => {
        this.setState({
            submitting: true,
            noOfExtern: 0,
        });

        setTimeout(() => this.setState({ submitting: false }), 30000);

        this.props.onSubmit(
            this.state.form.toolbox,
            this.employeeList().map((emp) => {
                return emp.id;
            }),
            this.state.form.scheduledDate,
            this.state.form.isToolboxMeeting,
            this.state.form.toolboxMeetingDate,
            this.state.form.toolboxMeetingLocation,
            this.state.form.toolboxMeetingChairman,
            Array.from(
                this.state.form.toolboxMeetingTopic
                    ? this.state.form.toolboxMeetingTopic.values()
                    : []
            ),
            this.state.groups
        );
        this.emptyUsergroup();
    };

    handleStepSubmit = () => {
        this.props.fetchUsergroups();

        if (this.state.stepIndex === 0) {
            let toolbox = this.props.toolbox.byId[this.state.form.toolbox];
            if (!toolbox) return;
            // Check eerst of er wel vragen zijn
            if (!toolbox.toolbox || !toolbox.toolbox.questions.length) {
                this.showToolboxQuestionCountError();
            } else {
                // Check of alle vragen ook mogelijke antwoorden hebben
                let questionsWithoutAnswers = toolbox.toolbox.questions
                    .map((q, i) => {
                        return { index: i, answers: q.possibleAnswers };
                    })
                    .filter((q) => q.answers.length < 2)
                    .map((q) => q.index + 1);

                if (questionsWithoutAnswers.length) {
                    this.showToolboxQuestionAnswerError(
                        questionsWithoutAnswers
                    );
                } else {
                    let questionsWithoutCorrectAnswers = toolbox.toolbox.questions
                        .map((q, i) => {
                            return { index: i, correct: q.correctAnswers };
                        })
                        .filter((q) => !q.correct.length)
                        .map((q) => q.index + 1);

                    if (questionsWithoutCorrectAnswers.length) {
                        this.showToolboxQuestionCorrectAnswerError(
                            questionsWithoutCorrectAnswers
                        );
                    } else {
                        this.setState({ stepIndex: this.state.stepIndex + 1 });
                    }
                }
            }
        } else {
            if (this.state.form.scheduled !== 'scheduled') {
                const formState = { ...this.state.form };
                this.setState({ form: { ...formState, scheduledDate: null } });
            }

            this.setState({ stepIndex: this.state.stepIndex + 1 });
        }
        // }
    };

    handleScheduledDateChange = (date) => {
        const formState = { ...this.state.form };
        date =  new Date(date);
        this.setState({ form: { ...formState, scheduledDate: date } });
    };


    handleScheduledChange = (event, scheduled) => {
        const formState = { ...this.state.form };
        this.setState({ form: { ...formState, scheduled: scheduled } });
    };

    showToolboxQuestionCountError = () => {
        const { t } = this.props;
        this.setState({
            toolboxErrorDialogOpen: true,
            toolboxErrorDialogText: t(
                'Toolbox must have at least one question'
            ),
        });
    };

    showToolboxQuestionAnswerError = (emptyQuestions) => {
        const { t } = this.props;
        let text =
            `${t('Toolbox questions must have at least 2 answers')} ${t(
                'At question number'
            )} ` +
            emptyQuestions
                .map((qi, i) => {
                    let count = emptyQuestions.length;
                    if (i === count - 1) {
                        return qi;
                    } else if (i === count - 2) {
                        return qi + ` ${t('and')} `;
                    } else {
                        return qi + ', ';
                    }
                })
                .join('') +
            ` ${t('not enough question are added')}`;
        this.setState({
            toolboxErrorDialogOpen: true,
            toolboxErrorDialogText: text,
        });
    };

    showToolboxQuestionCorrectAnswerError = (emptyQuestions) => {
        const { t } = this.props;
        let text =
            `${t(
                'Toolbox questions must have at least 1 correct market answer'
            )} ${t('At question number')} ` +
            emptyQuestions
                .map((qi, i) => {
                    let count = emptyQuestions.length;
                    if (i === count - 1) {
                        return (
                            qi +
                            (count === 1
                                ? ` ${t('is not yet an answer')}`
                                : ` ${t('are not yet answers')}`)
                        );
                    } else if (i === count - 2) {
                        return qi + ` ${t('and')} `;
                    } else {
                        return qi + ', ';
                    }
                })
                .join('') +
            ` ${t('marked correct')}`;
        this.setState({
            toolboxErrorDialogOpen: true,
            toolboxErrorDialogText: text,
        });
    };

    handleStepBack = () => {
        this.setState({ stepIndex: this.state.stepIndex - 1 });
    };

    handleStepSet = (index) => {
        this.setState({
            stepIndex: index,
        });
    };

    componentDidMount() {
        this.props.fetchEmployees();
        this.props.fetchToolboxes();
        this.props.fetchToolboxesSent();
        this.props.fetchUsergroups();
        this.props.getCurrentEmployee();
        const formState = { ...this.state.form };
        this.setState({ form: { ...formState, 'isToolboxMeeting': false } });
    }

    componentDidUpdate(prevProps: Props, prevState: State, prevContext: *): * {
        if (this.state.form.toolbox !== prevState.form.toolbox) {
            this.props.getToolboxById(this.state.form.toolbox);
        }
        if (prevProps.toolbox !== this.props.toolbox) {
            if (this.props.toolbox.allIds.length > 0) {
                let activeId = this.props.toolbox.allIds[(this.props.toolbox.allIds.length - 1)];
                let activeToolbox = this.props.toolbox.byId[activeId];

                this.setState({ activeToolbox:  {
                        label: activeToolbox.title,
                        value : activeToolbox.id,
                    },
                });
            }


        }
    }

    handleUsergroupClick = (id, isToolboxMeeting) => () => {
        let groups = [];

        if (this.state.groups) {
            groups = this.state.groups;
        }

        groups.push(id);

        this.setState({
            groups: groups,
        });
    };

    handleUsergroupDelete = (id) => () => {
        let groups = [];

        if (this.state.groups) {
            groups = this.state.groups;
        }

        groups = groups.filter((g) => g !== id);

        this.setState({
            groups: groups,
        });
    };

    emptyUsergroup = () => {
        let items = this.state.employeeItems;
        items = [];
        this.setState({ employeeItems: items, groups: [] });
    };

    employeeClicked = (user) => (event) => {
        const items = this.state.employeeItems;
        items.manual = [];

        if (event && event.length > 0) {
            event.forEach((employee) => {
                items.manual.push(this.props.employees.byId[employee.value]);
            });
        }

        this.setState(
            {
                employeeItems: items,
            },
            () => {
                this.countExtern();
            }
        );
    };

    employeeList = () => {
        const list = [];
        for (var key in this.state.employeeItems) {
            if (this.state.employeeItems[key]) {
                this.state.employeeItems[key].forEach((user) => {
                    let contains = false;
                    list.forEach((addedItem) => {
                        if (addedItem && user && addedItem.id === user.id) {
                            contains = true;
                        }
                    });
                    if (!contains) {
                        list.push(user);
                    }
                });
            }
        }
        return list.length === 0
            ? []
            : list.filter(this.noDuplicates).filter(
                  (user) =>
                      user &&
                      (user.state === 'ACTIVE' ||
                          user.roles.find((element) => {
                              return element === 'EXTERN';
                          }))
              );
    };

    filterActiveEmpl = (usergroups) => {
        if (usergroups) {
            return usergroups.allIds
                .map((id) => usergroups.byId[id])
                .filter((usergroup) => !this.allUsersInactive(usergroup.users));
        }

        return usergroups;
    };

    allUsersInactive = (users) => {
        let inactive = true;
        if (users) {
            if (users.length < 1) {
                return true;
            } else {
                users.forEach((user) => {
                    if (
                        user.state === 'ACTIVE' ||
                        user.roles.find((element) => {
                            return element === 'EXTERN';
                        })
                    ) {
                        inactive = false;
                    }
                });
            }
        }
        return inactive;
    };

    grayOutComp = (user) => {
        return false;
    };

    formatDate = () => {
        return moment(this.state.form.scheduledDate).format('LLL');
    };

    countExtern = () => {
        const items = this.state.employeeItems;
        let tempItems = [];

        for (const key in items) {
            if (items[key] !== null) {
                items[key].forEach((element) => {
                    if (
                        element &&
                        element.extern === true &&
                        !tempItems.includes(element.id)
                    )
                        tempItems.push(element.id);
                });
            }
        }

        this.setState({
            noOfExtern: tempItems.length,
        });
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.open && !this.props.open) {
            this.props.fetchEmployees();
            this.setState({
                form: {
                    toolbox: nextProps.initialId || '',
                    scheduled: nextProps.initialScheduled ? 'scheduled' : 'now',
                    employees: [],
                },
                // stepIndex: nextProps.initialId ? 1 : 0
                stepIndex: 0,
            });
        }
    }

    render() {
        const {
            open,
            toolboxes,
            employees,
            usergroups,
            currentEmpl,
            t,
        } = this.props;
        const {
            toolboxErrorDialogOpen,
            toolboxErrorDialogText,
            stepIndex,
        } = this.state;

        let noOfExtern = this.state.noOfExtern;

        let finalgroupusers = '';

        let usergroupamount = 0;

        let filteredUsergroups = [];
        filteredUsergroups = this.filterActiveEmpl(usergroups);

        let usergrouplist = [];

        let toolboxTopicItems = [];
        let toolboxLastScreenItems = [];
        if (this.state.form.toolboxMeetingTopic) {
            this.state.form.toolboxMeetingTopic.forEach((value, id) => {
                toolboxTopicItems.push(
                    <FormControl>
                        <div style={{ display: 'flex', background: 'white' }}>
                            <Input
                                style={{
                                    backgroundColor: 'white',
                                    height: '40px',
                                    paddingLeft: '25px',
                                    paddingTop: '10px',
                                }}
                                fullWidth={true}
                                
                                id={id}
                                onChange={this.handleEditTopic}
                                value={value}
                                autoFocus={true}
                                onFocus={function (e) {
                                    let val = e.target.value;
                                    e.target.value = '';
                                    e.target.value = val;
                                }}
                            ></Input>
                            <Button onClick={() => this.handleDeleteTopic(id)}>
                                <DeleteIcon />
                            </Button>
                        </div>
                    </FormControl>
                );
                toolboxLastScreenItems.push(
                    <Typography>{'• ' + value}</Typography>
                );
            });
        }

        let myLocale = nl;
        if (t('LOCALE') === 'en') {
            myLocale = en;
        }


        return (
            <Dialog
                fullScreen
                maxWidth={false}
                open={open ?? false}
                onClose={this.handleClose}
                TransitionComponent={Transition}
                className={classes.root}
            >
                <AppBar   className={classes.appBar}>
                    <Toolbar disableGutters className={classes.toolbar}>
                        <IconButton
                            className={classes.appBarButton}
                            color="inherit"
                            onClick={this.handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography
                            type="title"
                            color="inherit"
                            className={classes.flex}
                        >
                            {t('Toolbox')}{' '}
                            {this.state.form.scheduled === 'now'
                                ? t('Send out')
                                : t('Schedule')}
                        </Typography>
                        {stepIndex === 3 && (
                            <Button
                                
                                variant="contained"
                                color="primary"
                                onClick={this.handleSubmit}
                                disabled={this.state.submitting}
                            >
                                {this.state.form.scheduledDate
                                    ? t('Schedule')
                                    : t('Send out')}
                            </Button>
                        )}
                    </Toolbar>
                    <div className={classes.stepperWrapper}>
                        <div className={classes.stepper}>
                            <LegacyThemeProvider>
                                <Stepper activeStep={stepIndex}>
                                    <Step>
                                        <StepLabel>
                                            {t('Choose toolbox')}
                                        </StepLabel>
                                    </Step>
                                    <Step>
                                        <StepLabel>
                                            {t('Choose employees')}
                                        </StepLabel>
                                    </Step>
                                    <Step>
                                        <StepLabel>{t('Schedule')}</StepLabel>
                                    </Step>
                                    <Step>
                                        <StepLabel>
                                            {t('Check')} / {t('Send out')}
                                        </StepLabel>
                                    </Step>
                                </Stepper>
                            </LegacyThemeProvider>
                        </div>
                    </div>
                </AppBar>

                <Dialog
                    open={toolboxErrorDialogOpen ?? false}
                    onClose={() => {
                        this.setState({ toolboxErrorDialogOpen: false });
                    }}
                >
                    <DialogTitle>
                        {t('Toolbox can not be send out yet')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {toolboxErrorDialogText}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            sx={{color: colors.secondaryColor}}
                            onClick={() => {
                                this.setState({
                                    toolboxErrorDialogOpen: false,
                                });
                            }}
                             
                        >
                            {t('Ok')}
                        </Button>
                    </DialogActions>
                </Dialog>

                {toolboxes && (
                    <DialogContent className={classes.content}>
                        <div className={classes.formWrapper}>
                            {stepIndex === 0 && (
                                <div className={classes.step}>
                                    <Typography
                                        type="subheading"
                                        align="center"
                                    >
                                        {t('Select toolbox to send out')}
                                    </Typography>

                                    <FormControl style={{ marginTop: '32px' }}>
                                        <p
                                            style={{
                                                fontSize: '12px',
                                                color: 'rgba(0,0,0,0.54)',
                                                textAlign: 'left',
                                                lineHeight: '16px',
                                            }}
                                        >
                                            {t('Toolbox')}
                                        </p>
                                        <Select
                                            isMulti={false}
                                            fullWidth
                                            value={this.state.activeToolbox}
                                            onChange={this.handleChangeSingle}
                                            options={toolboxes.sort(function (a, b) {
                                                if (a.title.toLowerCase() < b.title.toLowerCase())
                                                    return -1;
                                                if (a.title.toLowerCase() > b.title.toLowerCase())
                                                    return 1;
                                                if (a.version < b.version)
                                                    return -1;
                                                if (a.version > b.version)
                                                    return 1;
                                                return 0;
                                            })
                                                .map((c) => ({
                                                    label: c.title,
                                                    value: c.id,
                                                    htmlLabel: (
                                                        <div
                                                            key={c.id}
                                                            value={c.id}
                                                            className={classes.menuItem}
                                                        >
                                                            <img className={classes.toolboxIcon}
                                                                 src={
                                                                     c.source ===
                                                                     'cumela'
                                                                         ? '/assets/images/cumela-logo.png'
                                                                         : c.source ===
                                                                         'global'
                                                                             ? '/assets/images/vvw-admin-logo.svg'
                                                                             : c.rootboxSource ===
                                                                             'global' ||
                                                                             c.rootboxSource ===
                                                                             'cumela'
                                                                                 ? '/assets/images/aangepast.svg'
                                                                                 : '/assets/images/vvw-logo.svg'
                                                                 }
                                                                 alt={'logo'}
                                                            />
                                                            <div style={{display: 'inline',}}>
                                                                <div style={{display: 'inline',}}>
                                                                    {c.title}{' '}
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            'inline',
                                                                        color:
                                                                            '#616161',
                                                                        fontSize:
                                                                            '12px',
                                                                    }}
                                                                >
                                                                    {'v' +
                                                                        c.version}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ),
                                                }))
                                            }
                                        />
                                    </FormControl>

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={
                                                    this.state.form
                                                        .isToolboxMeeting
                                                }
                                                onChange={this.handleCheckedChange(
                                                    'isToolboxMeeting'
                                                )}
                                                value="isToolboxMeeting"
                                            />
                                        }
                                        label={t('This is a toolboxmeeting')}
                                    />
                                    <div
                                        className={classes.step}
                                        style={{ paddingTop: '0px' }}
                                    >
                                    </div>

                                    <p
                                        style={
                                            this.state.form.isToolboxMeeting ===
                                            true
                                                ? {
                                                      size: '16px',
                                                      fontWeight: 'bold',
                                                      marginTop: '25px',
                                                      marginBottom: '25px',
                                                      color: 'rgba(0,0,0,0.87)',
                                                      lineHeight: '20px',
                                                  }
                                                : { display: 'none' }
                                        }
                                    >
                                        {t('Additional toolbox meeting data')}
                                    </p>

                                    <FormControl
                                        style={
                                            this.state.form.isToolboxMeeting ===
                                            true
                                                ? {}
                                                : { display: 'none' }
                                        }
                                    >
                                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={myLocale} >
                                            <DateTimePicker
                                                id="DialogToolboxDeploy-toolboxMeetingDate"
                                                viewRenderers={{
                                                    hours: renderTimeViewClock,
                                                    minutes: renderTimeViewClock,
                                                    seconds: renderTimeViewClock,
                                                  }}
                                                ampm={false}
                                                okLabel={t('Ok')}
                                                cancelLabel={t('Cancel')}
                                                fullWidth={true}
                                                inputFormat={t('DateTimeFormat')}
                                                onChange={this.handleToolboxMeetingDateChange}
                                                minDate={new Date()}
                                                label={t("Choose date")}
                                                value={this.state.form.toolboxMeetingDate ? this.state.form.toolboxMeetingDate : null}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>

                                    <FormControl
                                        style={
                                            this.state.form.isToolboxMeeting ===
                                            true
                                                ? {}
                                                : { display: 'none' }
                                        }
                                    >
                                        <p
                                            style={{
                                                marginBottom: '-5px',
                                                fontSize: '12px',
                                                color: 'rgba(0,0,0,0.54)',
                                                textAlign: 'left',
                                                lineHeight: '16px',
                                            }}
                                        >
                                            {t('Location')}
                                        </p>
                                        <Input
                                            fullWidth={true}
                                            margin="dense"
                                            id="DialogInspectionDeploy-location"
                                            value={
                                                this.state.form
                                                    .toolboxMeetingLocation
                                            }
                                            onChange={this.handleChange(
                                                'toolboxMeetingLocation'
                                            )}
                                            placeholder={t(
                                                'Specify a location'
                                            )}
                                        />
                                    </FormControl>

                                    <FormControl
                                        style={
                                            this.state.form.isToolboxMeeting ===
                                            true
                                                ? {}
                                                : { display: 'none' }
                                        }
                                    >
                                        <p
                                            style={{
                                                marginBottom: '-5px',
                                                fontSize: '12px',
                                                color: 'rgba(0,0,0,0.54)',
                                                textAlign: 'left',
                                                lineHeight: '16px',
                                                paddingTop: '8px',
                                            }}
                                        >
                                            {t('Chairman')}
                                        </p>
                                        <Input
                                            fullWidth={true}
                                            id="DialogInspectionDeploy-chairman"
                                            value={
                                                this.state.form
                                                    .toolboxMeetingChairman
                                            }
                                            onChange={this.handleChange(
                                                'toolboxMeetingChairman'
                                            )}
                                            placeholder={t(
                                                'Optional name of chairman'
                                            )}
                                        />
                                    </FormControl>

                                    <p
                                        style={
                                            this.state.form.isToolboxMeeting ===
                                            true
                                                ? {
                                                      size: '16px',
                                                      fontWeight: 'bold',
                                                      marginTop: '35px',
                                                      marginBottom: '20px',
                                                      color: 'rgba(0,0,0,0.87)',
                                                      lineHeight: '20px',
                                                  }
                                                : { display: 'none' }
                                        }
                                    >
                                        {t('Topics to cover')}
                                    </p>

                                    {toolboxTopicItems}

                                    {this.state.form.isToolboxMeeting && (
                                        <FormControl>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    background:
                                                        'rgba(220,220, 220, 0.3)',
                                                }}
                                            >
                                                <Input
                                                    style={{
                                                        backgroundColor:
                                                            'rgba(220,220, 220, 0.3)',
                                                        height: '40px',
                                                        paddingLeft: '10px',
                                                    }}
                                                    fullWidth={true}
                                                    disableUnderline={true}
                                                    id="addNewSubject"
                                                    value={
                                                        this.state
                                                            .newTempSubject
                                                    }
                                                    onChange={
                                                        this.handleAddTopic
                                                    }
                                                    // onBlur={this.handleNewSubjectTyping}
                                                    autoFocus={true}
                                                    placeholder={t(
                                                        'Add subject'
                                                    )}
                                                />
                                            </div>
                                        </FormControl>
                                    )}

                                    <div className={classes.stepFooter}>
                                        <Divider className={classes.divider} />
                                        <Button
                                            disabled={
                                                !this.state.form
                                                    .isToolboxMeeting
                                                    ? this.state.form
                                                          .toolbox === ''
                                                    : this.state.form
                                                          .toolbox === '' ||
                                                      isUndefinedOrNull(
                                                          this.state.form
                                                              .toolboxMeetingLocation
                                                      ) ||
                                                      this.state.form
                                                          .toolboxMeetingLocation ===
                                                          '' ||
                                                      !toolboxTopicItems ||
                                                      (toolboxTopicItems &&
                                                          toolboxTopicItems.length ===
                                                              0) ||
                                                      isUndefinedOrNull(
                                                          this.state.form
                                                              .toolboxMeetingDate
                                                      ) ||
                                                      this.state.form
                                                          .toolboxMeetingDate ===
                                                          ''
                                            }
                                            className={classes.stepButton}
                                            
                                            variant="contained"
                                            color="primary"
                                            onClick={this.handleStepSubmit}
                                        >
                                            {t('Continue')}
                                        </Button>
                                    </div>
                                </div>
                            )}
                            {stepIndex === 1 && (
                                <div className={classes.step}>
                                    <Typography type="title" align="center">
                                        {t('Choose employees')}
                                    </Typography>
                                    <Typography
                                        className={classes.subheader}
                                        type="subheading"
                                        align="center"
                                    >
                                        {t(
                                            'Select employees to receive toolbox'
                                        )}
                                    </Typography>

                                    {filteredUsergroups.length > 0 ? (
                                        <div
                                            style={{
                                                marginBottom: '5px',
                                                marginTop: '32px',
                                            }}
                                        >
                                            <InputLabel>
                                                {t('Employee groups')}{' '}
                                            </InputLabel>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {filteredUsergroups.map(
                                        (usergroup, index) => {
                                            const selected = this.state.groups
                                                ? this.state.groups.includes(
                                                      usergroup.id
                                                  )
                                                : false;
                                            return (
                                                <div
                                                    className={classes.chip}
                                                    key={index}
                                                >
                                                    <Chip
                                                        className={classnames({
                                                            [classes.selectedChip]: selected,
                                                        })}
                                                        label={usergroup.name}
                                                        onClick={this.handleUsergroupClick(
                                                            usergroup.id,
                                                            this.state.form
                                                                .isToolboxMeeting
                                                        )}
                                                        onDelete={ selected ? this.handleUsergroupDelete( usergroup.id ) : undefined
                                                        }
                                                    />
                                                </div>
                                            );
                                        }
                                    )}

                                    {filteredUsergroups.length > 0 &&
                                        this.state.groups &&
                                        this.state.groups.length > 0 && (
                                            <InputLabel
                                                style={{
                                                    display: 'block',
                                                    marginTop: '20px',
                                                }}
                                            >
                                                {t('Employees in groups')}
                                            </InputLabel>
                                        )}

                                    {filteredUsergroups.length > 0 &&
                                        this.state.groups &&
                                        this.state.groups.length > 0 &&
                                        filteredUsergroups.map(
                                            (usergroup, index) => {
                                                let users = '';
                                                if (
                                                    this.state.groups.includes(
                                                        usergroup.id
                                                    )
                                                ) {
                                                    usergroup.users.map(
                                                        (user) => {
                                                            if (
                                                                !this.grayOutComp(
                                                                    user
                                                                ) ||
                                                                this.state.form
                                                                    .isToolboxMeeting
                                                            ) {
                                                                if (
                                                                    !usergrouplist.includes(
                                                                        user.id
                                                                    )
                                                                ) {
                                                                    usergrouplist.push(
                                                                        user.id
                                                                    );
                                                                    if (
                                                                        user.dtype ===
                                                                        'Extern'
                                                                    ) {
                                                                        noOfExtern++;
                                                                        users =
                                                                            users +
                                                                            user.fullname +
                                                                            ' (Externe), ';
                                                                    } else {
                                                                        users =
                                                                            users +
                                                                            user.fullname +
                                                                            ', ';
                                                                    }
                                                                }
                                                            }
                                                            return null
                                                        }
                                                        
                                                    );
                                                }

                                                // return users.substring(0, users.length - 2);
                                                return users;
                                            }
                                        )}

                                    <InputLabel
                                        style={{
                                            display: 'block',
                                            marginTop: '20px',
                                            marginBottom: '3px',
                                        }}
                                    >
                                        {t('Employees')}
                                    </InputLabel>
                                    <FormControl>
                                        <Select
                                            closeOnSelect={false}
                                            disabled={false}
                                            isMulti
                                            onChange={this.employeeClicked(0)}
                                            options={employees.allIds
                                                .map((id) => employees.byId[id])
                                                .filter((empl) => empl.state === 'ACTIVE' || empl.extern === true)
                                                // .filter(empl=> this.grayOutComp(empl)===false)
                                                .filter((empl) =>
                                                    this.state.form.isToolboxMeeting ? true : this.grayOutComp(empl) === false)
                                                .filter((empl) => !usergrouplist.includes(empl.id))
                                                .sort(function (a, b) {
                                                    if (a.firstname.toLowerCase() < b.firstname.toLowerCase())
                                                        return -1;
                                                    if (a.firstname.toLowerCase() > b.firstname.toLowerCase())
                                                        return 1;
                                                    if (a.lastname.toLowerCase() < b.lastname.toLowerCase())
                                                        return -1;
                                                    if (a.lastname.toLowerCase() > b.lastname.toLowerCase())
                                                        return 1;
                                                    return 0;
                                                })
                                                .map((c) => ({
                                                    value: c.id,
                                                    label: c.fullname.replace(
                                                        '  ',
                                                        ' '
                                                    ),
                                                }))}
                                            placeholder={`${t(
                                                'Select employees'
                                            )} `}
                                            removeSelected={true}
                                            rtl={false}
                                        />
                                    </FormControl>

                                    {(noOfExtern || noOfExtern > 0) && (
                                        <CreditsBlock
                                            noOfExtern={noOfExtern}
                                            currentEmpl={currentEmpl}
                                        />
                                    )}

                                    <div className={classes.stepFooter}>
                                        <Divider className={classes.divider} />
                                        <Button
                                            className={classes.stepButton}
                                            onClick={this.handleStepBack}
                                        >
                                            {t('Back')}
                                        </Button>
                                        <Button
                                            disabled={
                                                (this.employeeList().length ===
                                                    0 &&
                                                    usergrouplist.length < 1) ||
                                                noOfExtern >
                                                    currentEmpl.subscription
                                                        .credits
                                            }
                                            className={classes.stepButton}
                                            
                                            variant="contained"
                                            color="primary"
                                            onClick={this.handleStepSubmit}
                                        >
                                            {t('Continue')}
                                        </Button>
                                    </div>
                                </div>
                            )}
                            {stepIndex === 2 && (
                                <div className={classes.step}>
                                    <Typography type="title" align="center">
                                        {t('Schedule')}
                                    </Typography>
                                    <Typography
                                        className={classnames({
                                            [classes.subheader]: true,
                                            [classes.padBottom]: true,
                                        })}
                                        type="subheading"
                                        align="center"
                                    >
                                        {t('When can toolbox be send out')}
                                    </Typography>

                                    <FormControl>
                                        <RadioGroup
                                            name="scheduled"
                                            value={this.state.form.scheduled}
                                            onChange={
                                                this.handleScheduledChange
                                            }
                                        >
                                            <FormControlLabel
                                                className={classes.radioTitle}
                                                value="now"
                                                control={ <Radio selected color="primary"/> }
                                                label={t('Send out now')}
                                            />
                                            <FormControlLabel
                                                style={{ width: '100%' }}
                                                value="scheduled"
                                                control={ <Radio color="primary" /> }
                                                label={
                                                    <div style={{ width: '100%', }} >
                                                        <FormControl
                                                            className={classnames(
                                                                {
                                                                    [classes.datePickerControl]: true,
                                                                    [classes.datePickerVisible]:
                                                                        this.state.form.scheduled === 'scheduled',
                                                                }
                                                            )}
                                                        >
                                                                <span className={ classes.radioTitle }>
                                                                    {t('Send out on')}

                                                                </span>
                                                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={myLocale}>
                                                                    <DateTimePicker
                                                                        id="DialogToolboxDeploy-schedule"
                                                                        viewRenderers={{
                                                                            hours: renderTimeViewClock,
                                                                            minutes: renderTimeViewClock,
                                                                            seconds: renderTimeViewClock,
                                                                          }}
                                                                        ampm={false}
                                                                        okLabel={t('Ok')}
                                                                        cancelLabel={t('Cancel')}
                                                                        label={t('Choose date')}
                                                                        fullWidth={true}
                                                                        views={['year', 'month', 'day', 'hours', 'minutes']}
                                                                        inputFormat={t('DateTimeFormat')}
                                                                        onChange={this.handleScheduledDateChange}
                                                                        minDate={new Date()}
                                                                        value={this.state.form.scheduledDate}
                                                                    />
                                                                </LocalizationProvider>
                                                            {/*<FormHelperText className={classes.dateHelperText}>De datum waarop de werkplekinspectie gereed moet zijn</FormHelperText>*/}
                                                        </FormControl>
                                                    </div>
                                                }
                                            />
                                        </RadioGroup>
                                    </FormControl>

                                    {this.state.form.scheduled ===
                                        'scheduled' &&
                                        moment(
                                            this.state.form.scheduledDate
                                        ).diff(moment()) < 0 && (
                                            <div style={{ color: 'red' }}>
                                                {t('Selected date had expired')}
                                            </div>
                                        )}

                                    <div className={classes.stepFooter}>
                                        <Divider className={classes.divider} />
                                        <Button
                                            className={classes.stepButton}
                                            onClick={this.handleStepBack}
                                        >
                                            {t('Back')}
                                        </Button>
                                        <Button
                                            disabled={
                                                (this.employeeList().length ===
                                                    0 &&
                                                    this.state.groups.length ===
                                                        0) ||
                                                (this.state.form.scheduled ===
                                                    'scheduled' &&
                                                    !this.state.form
                                                        .scheduledDate) ||
                                                (this.state.form.scheduled ===
                                                    'scheduled' &&
                                                    moment(
                                                        this.state.form
                                                            .scheduledDate
                                                    ).diff(moment()) < 0)
                                            }
                                            className={classes.stepButton}
                                            
                                            variant="contained"
                                            color="primary"
                                            onClick={this.handleStepSubmit}
                                        >
                                            {t('Continue')}
                                        </Button>
                                    </div>
                                </div>
                            )}
                            {stepIndex === 3 && (
                                <AppModule loading={this.state.submitting}>
                                    <div className={classes.step}>
                                        <Typography
                                            className={classnames({
                                                [classes.padBottom]: true,
                                                [classes.subheader]: true,
                                            })}
                                            type="body1"
                                            align="center"
                                            paragraph={true}
                                        >
                                            {t(
                                                'Check all data and send toolbox'
                                            )}
                                        </Typography>

                                        <div className={classes.finalBox}>
                                            <Typography
                                                style={{ float: 'left' }}
                                                className={classes.finalTitle}
                                                type="body1"
                                                align="left"
                                                paragraph={true}
                                            >
                                                1. {t('Choose toolbox')}
                                            </Typography>

                                            <Button
                                                style={{
                                                    float: 'right',
                                                    marginTop: '-5px',
                                                }}
                                                className={classes.stepButton}
                                                onClick={() =>
                                                    this.handleStepSet(0)
                                                }
                                            >
                                                {t('Change')}
                                            </Button>
                                            <div
                                                style={{ clear: 'both' }}
                                            ></div>

                                            <TitleDescription
                                                className={classes.item}
                                                itemTitle={t('Toolbox')}
                                                itemDescription={
                                                    this.props.toolboxes.filter(
                                                        (e) =>
                                                            e.id ===
                                                            this.state.form
                                                                .toolbox
                                                    ).length !== 0 &&
                                                    this.props.toolboxes.filter(
                                                        (e) =>
                                                            e.id ===
                                                            this.state.form
                                                                .toolbox
                                                    )[0].title !== undefined &&
                                                    this.props.toolboxes.filter(
                                                        (e) =>
                                                            e.id ===
                                                            this.state.form
                                                                .toolbox
                                                    )[0].title
                                                }
                                            />

                                            {this.state.form
                                                .isToolboxMeeting && (
                                                <div className={classes.item}>
                                                    <Typography
                                                        style={{
                                                            marginTop: '25px',
                                                        }}
                                                        className={
                                                            classes.finalTitle
                                                        }
                                                    >
                                                        {t('Toolboxmeeting')}
                                                    </Typography>

                                                    <TitleDescription
                                                        className={classes.item}
                                                        itemTitle={t(
                                                            'Date and time'
                                                        )}
                                                        itemDescription={moment(
                                                            this.state.form
                                                                .toolboxMeetingDate
                                                        ).format('LLL')}
                                                    />
                                                    <TitleDescription
                                                        className={classes.item}
                                                        itemTitle={t(
                                                            'Location'
                                                        )}
                                                        itemDescription={
                                                            this.state.form
                                                                .toolboxMeetingLocation
                                                        }
                                                    />
                                                    <TitleDescription
                                                        className={classes.item}
                                                        itemTitle={t(
                                                            'Chairman'
                                                        )}
                                                        itemDescription={
                                                            this.state.form
                                                                .toolboxMeetingChairman
                                                                ? this.state
                                                                      .form
                                                                      .toolboxMeetingChairman
                                                                : '-'
                                                        }
                                                    />
                                                    <TitleDescription
                                                        className={classes.item}
                                                        itemTitle={t(
                                                            'Subject(s)'
                                                        )}
                                                        itemDescription={
                                                            toolboxLastScreenItems
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </div>

                                        <div className={classes.finalBox}>
                                            <Typography
                                                style={{ float: 'left' }}
                                                className={classes.finalTitle}
                                                type="body1"
                                                align="left"
                                                paragraph={true}
                                            >
                                                2. {t('Selected employees')}
                                            </Typography>

                                            <Button
                                                style={{
                                                    float: 'right',
                                                    marginTop: '-5px',
                                                }}
                                                className={classes.stepButton}
                                                onClick={() =>
                                                    this.handleStepSet(1)
                                                }
                                            >
                                                {t('Change')}
                                            </Button>
                                            <div
                                                style={{ clear: 'both' }}
                                            ></div>

                                            <div
                                                style={{ visibility: 'hidden' }}
                                            >
                                                {(usergrouplist = [])}

                                                {filteredUsergroups.length >
                                                    0 &&
                                                    this.state.groups &&
                                                    this.state.groups.length >
                                                        0 &&
                                                    filteredUsergroups.map(
                                                        (usergroup, index) => {
                                                            noOfExtern = this
                                                                .state
                                                                .noOfExtern;
                                                            if (
                                                                this.state.groups.includes(
                                                                    usergroup.id
                                                                )
                                                            ) {
                                                                usergroup.users.forEach(
                                                                    (user) => {
                                                                        if (
                                                                            !this.grayOutComp(
                                                                                user
                                                                            ) ||
                                                                            this
                                                                                .state
                                                                                .form
                                                                                .isToolboxMeeting
                                                                        ) {
                                                                            if (
                                                                                !usergrouplist.includes(
                                                                                    user.id
                                                                                )
                                                                            ) {
                                                                                usergrouplist.push(
                                                                                    user.id
                                                                                );
                                                                                usergroupamount++;
                                                                                if (
                                                                                    user.dtype ===
                                                                                    'Extern'
                                                                                ) {
                                                                                    noOfExtern++;
                                                                                    finalgroupusers =
                                                                                        finalgroupusers +
                                                                                        user.fullname +
                                                                                        ' (Externe), ';
                                                                                } else {
                                                                                    finalgroupusers =
                                                                                        finalgroupusers +
                                                                                        user.fullname +
                                                                                        ', ';
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                );
                                                            }
                                                            return null
                                                        }
                                                    )}
                                            </div>

                                            <TitleDescription
                                                className={classes.item}
                                                itemTitle={t('Employee(s)')}
                                                itemDescription={
                                                    this.state.employeeItems
                                                        .manual
                                                        ? this.state.employeeItems.manual
                                                              .map(
                                                                  (e) =>
                                                                      e.fullname
                                                              )
                                                              .join(', ') +
                                                          finalgroupusers
                                                        : finalgroupusers /* Bugfix */
                                                }
                                            />

                                            <TitleDescription
                                                className={classes.item}
                                                itemTitle={t('Total employees')}
                                                itemDescription={
                                                    this.state.noOfExtern +
                                                    this.state.employeeItems
                                                        .manual
                                                        ? this.state
                                                              .employeeItems
                                                              .manual.length +
                                                          usergroupamount
                                                        : usergroupamount /* Bugfix */
                                                }
                                            />

                                            {noOfExtern > 0 && (
                                                <div>
                                                    <TitleDescription
                                                        className={classes.item}
                                                        itemTitle={t(
                                                            'External employees'
                                                        )}
                                                        itemDescription={
                                                            noOfExtern
                                                        }
                                                    />

                                                    <div
                                                        className={
                                                            classes.halfWidth
                                                        }
                                                    >
                                                        <TitleDescription
                                                            className={
                                                                classes.item
                                                            }
                                                            itemTitle={t(
                                                                'Amount of credits required'
                                                            )}
                                                            itemDescription={
                                                                noOfExtern
                                                            }
                                                        />
                                                    </div>

                                                    <div
                                                        className={
                                                            classes.halfWidth
                                                        }
                                                    >
                                                        <TitleDescription
                                                            className={
                                                                classes.item
                                                            }
                                                            itemTitle={t(
                                                                'Coins'
                                                            )}
                                                            itemDescription={
                                                                this.props
                                                                    .currentEmpl
                                                                    .subscription
                                                                    .credits
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <div className={classes.finalBox}>
                                            <Typography
                                                style={{ float: 'left' }}
                                                className={classes.finalTitle}
                                                type="body1"
                                                align="left"
                                                paragraph={true}
                                            >
                                                3. {t('Send date')}
                                            </Typography>

                                            <Button
                                                style={{
                                                    float: 'right',
                                                    marginTop: '-5px',
                                                }}
                                                className={classes.stepButton}
                                                onClick={() =>
                                                    this.handleStepSet(2)
                                                }
                                            >
                                                {t('Change')}
                                            </Button>
                                            <div
                                                style={{ clear: 'both' }}
                                            ></div>

                                            <TitleDescription
                                                className={classes.item}
                                                itemTitle={t('When')}
                                                itemDescription={
                                                    this.state.form
                                                        .scheduledDate
                                                        ? moment(
                                                              this.state.form
                                                                  .scheduledDate
                                                          ).format('LLL')
                                                        : t('Send out now')
                                                }
                                            />
                                        </div>
                                        <div className={classes.stepFooter}>
                                            <Divider
                                                className={classes.divider}
                                            />
                                            <Button
                                                className={classes.stepButton}
                                                onClick={this.handleStepBack}
                                                disabled={this.state.submitting}
                                            >
                                                {t('Back')}
                                            </Button>
                                            <Button
                                                className={classes.stepButton}
                                                
                                                variant="contained"
                                                color="primary"
                                                onClick={this.handleSubmit}
                                                disabled={this.state.submitting}
                                            >
                                                {this.state.form.scheduledDate
                                                    ? t('Schedule')
                                                    : t('Send out')}
                                            </Button>
                                        </div>
                                    </div>
                                </AppModule>
                            )}
                        </div>
                    </DialogContent>
                )}
            </Dialog>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    
    withTranslation()
)(DialogToolboxDeploy);
