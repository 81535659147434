// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
    Dialog,
    DialogContent,
    Toolbar,
    Typography,
    AppBar,
    Button,
    IconButton,
    Paper,
    DialogTitle,
    DialogContentText,
    FormControlLabel,
    DialogActions,
    Switch,
    Divider,
    List,
    ListItemText,
    ListItem,
    Popover,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Loader from '../../Loader';
import { inProgress } from '../../../selectors';
import getRoles from '../../../selectors/getRoles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { fetchSubscriptionCategories } from '../../../actions/subscriptionActions';
import { bindActionCreators } from 'redux';
import { upgradeAbonnee } from '../../../actions/subscriptionActions';
import { TSubscription } from '../../../reducers/subscriptionReducer';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import classes from './AbonnementEdit.module.scss';

type Props = {
    loading: boolean,
    onUpgraded: () => mixed,
    upgradeAbonnee: (abonnee: TSubscription) => void,
};

type State = {
    warningDialogOpen: boolean,
    subscription: any,
    menuOpen: boolean,
    menuCreditsOpen: boolean,
    menuCumelaOpen: boolean,
    menuCaoleoOpen: boolean,
    prijsZonderKorting: any,
    dialogUpgraded: boolean,
    rowMenuAnchorElement: null,
    creditsPrice: any,
    defaultPrice: any,
};

const mapStateToProps = (store, props) => {
    let categories = store.subscription.categories;
    const credits = [
        { amount: 100, price: 99 },
        { amount: 1000, price: 899 },
        { amount: 5000, price: 3999 },
    ];

    return {
        loading: !inProgress(store),
        roles: getRoles(store),
        categories: categories,
        credits: credits,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        upgradeAbonnee: bindActionCreators(upgradeAbonnee, dispatch),
        onClose: () => {
            // window.$crisp.push(['do', 'chat:show']);

            dispatch(push('/abonnement'));
        },
        fetchCategories: (id: Number) => {
            dispatch(fetchSubscriptionCategories(id));
        },
    };
};

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class AbonnementEdit extends React.Component<Props, State> {
    props: Props;
    state: State = {
        warningDialogOpen: false,
        subscription: this.props.subscription,
        menuOpen: false,
        menuCreditsOpen: false,
        menuCumelaOpen: false,
        menuCaoleoOpen: false,
        rowMenuAnchorElement: null,
        prijsZonderKorting: 0,
        creditsPrice: 99,
        defaultPrice: 0,
    };

    handleClose = (event) => {
        this.props.onClose();
        if (this.props.subscription.vv_functions) {
            this.setState({
                subscription: {
                    ...this.props.subscription,
                    price: this.state.defaultPrice,
                },
            });
        } else {
            this.setState({
                subscription: {
                    ...this.props.subscription,
                    maxEmployees: 25,
                    price: 299,
                },
            });
        }
    };

    requestWarningDialogClose = () => {
        this.setState({
            subscription: {
                ...this.state.subscription,
                vv_functions: false,
            },
        });

        this.setState({
            warningDialogOpen: false,
        });
    };

    requestDialogUpgradedClose = () => {
        this.setState({
            dialogUpgraded: false,
        });
        this.props.onClose();
        this.props.onUpgraded();

        window.location.reload();
    };

    handleSneldelenChange = (event) => {
        if (this.props.subscription.credits_setup !== true) {
            this.setState({
                subscription: {
                    ...this.state.subscription,
                    credits: this.state.subscription.credits_setup
                        ? this.props.subscription.credits
                        : 100,
                    credits_setup: !this.state.subscription.credits_setup,
                },
            });
        }
    };

    handleEmployeesChange = (action) => (event) => {
        this.setState({
            menuOpen: false,
            prijsZonderKorting: action.prijsZonderKorting,
            subscription: {
                ...this.state.subscription,
                maxEmployees: action.maxEmployees,
                price: action.prijs,
                priceInteger: action.prijs,
            },
        });
    };

    handleCreditsChange = (action) => (event) => {
        this.setState({
            menuCreditsOpen: false,
            creditsPrice: action.price,
            subscription: {
                ...this.state.subscription,
                credits: action.amount,
            },
        });
    };

    handleCumelaChange = (action) => (event) => {
        if (action === true) {
            this.props.fetchCategories(-1);
        } else {
            this.props.fetchCategories(-3);
        }
        this.setState({
            menuCumelaOpen: false,
            subscription: {
                ...this.state.subscription,
                cumela: action,
                caoLeo: false,
            },
        });
    };

    handleCaoleoChange = (action) => (event) => {
        if (action === true) {
            this.props.fetchCategories(-2);
        } else {
            this.props.fetchCategories(-3);
        }
        this.setState({
            menuCaoleoOpen: false,
            subscription: {
                ...this.state.subscription,
                caoLeo: action,
            },
        });
    };

    openEmployeeMenu = (event) => {
        if (
            this.props.subscription &&
            this.props.subscription.maxEmployees > 0 &&
            this.props.subscription &&
            this.props.subscription.maxEmployees < 250
        ) {
            this.setState({
                menuOpen: true,
                rowMenuAnchorElement: event.currentTarget,
            });
        }
    };

    openCumelaMenu = (event) => {
        this.setState({
            menuCumelaOpen: true,
            rowMenuAnchorElement: event.currentTarget,
        });
    };

    openCaoleoMenu = (event) => {
        this.setState({
            menuCaoleoOpen: true,
            rowMenuAnchorElement: event.currentTarget,
        });
    };

    openCreditsMenu = (event) => {
        this.setState({
            menuCreditsOpen: true,
            rowMenuAnchorElement: event.currentTarget,
        });
    };

    handleRowMenuRequestClose = (event) => {
        this.setState({ menuOpen: false });
    };

    handleRowMenuCreditsRequestClose = (event) => {
        this.setState({ menuCreditsOpen: false });
    };

    handleRowMenuCumelaRequestClose = (event) => {
        this.setState({ menuCumelaOpen: false });
    };

    handleRowMenuCaoleoRequestClose = (event) => {
        this.setState({ menuCaoleoOpen: false });
    };

    handleUpgrade = (event) => {
        // this.setState({
        //     subscription: {
        //         // ...this.state.subscription,
        //         vv_functions: true
        //     }
        // });

        // alert("sss" + this.state.subscription.vv_functions);

        this.setState({ warningDialogOpen: false, dialogUpgraded: true });

        this.props.upgradeAbonnee(this.state.subscription);
    };

    handleUpgradeDialog = (event) => {
        this.setState({
            subscription: {
                ...this.state.subscription,
                vv_functions: true,
            },
        });
        //
        // alert(this.state.subscription.vv_functions);
        this.setState({ warningDialogOpen: true });
    };

    componentDidMount() {
        this.props.fetchCategories(this.props.subscription.id);
        if (this.state.subscription.maxEmployees === 0) {
            //TODO: GET PRICE FROM SERVER
            this.setState({
                subscription: {
                    ...this.state.subscription,
                    maxEmployees: 25,
                    price: 299,
                },
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (
            nextProps.categories !== this.props.categories ||
            (nextProps.categories && this.props.categories === null)
        ) {
            for (let i = 0; i !== nextProps.categories.length; i++) {
                if (
                    nextProps.categories[i].maxEmployees ===
                    this.state.subscription.maxEmployees.toString()
                ) {
                    this.setState({
                        prijsZonderKorting:
                            nextProps.categories[i].prijsZonderKorting,
                        defaultPrice: nextProps.categories[i].prijs,
                        subscription: {
                            ...this.state.subscription,
                            price: nextProps.categories[i].prijs,
                        },
                    });
                }
            }
        }
    }

    render() {
        const { open, loading, categories, credits, t } = this.props;
        const {
            warningDialogOpen,
            dialogUpgraded,
            subscription,
            prijsZonderKorting,
        } = this.state;

        const accountType = subscription.cumela
            ? 'cumela'
            : subscription.caoLeo
            ? 'caoleo'
            : 'veiligvakwerk';
        const OPSTARTPRIJS = 99;

        let subscriptionName = '';
        if (subscription.credits_setup && !subscription.vv_functions) {
            subscriptionName = t('Veilig Vakwerk Sneldelen only');
        } else {
            if (subscription.cumela) {
                if (
                    subscription.maxEmployees > 250 &&
                    prijsZonderKorting === 0
                ) {
                    this.setState({
                        prijsZonderKorting: 3 * subscription.price,
                    });
                }
                if (subscription.credits_setup) {
                    subscriptionName = t('Veilig Vakwerk Cumela met Sneldelen');
                } else {
                    subscriptionName = t('Veilig Vakwerk Cumela');
                }
            } else {
                if (subscription.caoLeo) {
                    if (subscription.credits_setup) {
                        subscriptionName = t(
                            'Veilig Vakwerk cao Groen, Grond en Infrastructuur met Sneldelen'
                        );
                    } else {
                        subscriptionName = t(
                            'Veilig Vakwerk cao Groen, Grond en Infrastructuur'
                        );
                    }
                } else {
                    if (subscription.credits_setup) {
                        subscriptionName = t('Veilig Vakwerk met Sneldelen');
                    } else {
                        subscriptionName = t('Veilig Vakwerk Standaard');
                    }
                }
            }
        }

        return (
            <Dialog
                fullScreen
                maxWidth={false}
                open={open}
                onClose={this.handleClose}
                TransitionComponent={Transition}
            >
                <Dialog
                    open={warningDialogOpen}
                    onClose={this.requestWarningDialogClose}
                >
                    <DialogTitle>{t('Upgrade subscription')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {this.props.subscription.maxEmployees !==
                                subscription.maxEmployees &&
                                t('Request subscription for maximal') +
                                    ' ' +
                                    subscription.maxEmployees +
                                    ' ' +
                                    t('employees') +
                                    '?' +
                                    t('Yearly costs are') +
                                    ' €' +
                                    subscription.price +
                                    '. '}
                            {this.props.subscription.credits_setup === false &&
                                subscription.credits_setup === true &&
                                t('One-off costs are') +
                                    t('EENMALIGE KOSTEN') +
                                    ' + ' +
                                    subscription.credits +
                                    ' ' +
                                    t('Credits') +
                                    ' (€' +
                                    this.state.creditsPrice +
                                    ').'}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                             
                            onClick={this.requestWarningDialogClose}
                        >
                            {t('Cancel')}
                        </Button>
                        <Button   onClick={this.handleUpgrade}>
                            {t('Confirm')}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={dialogUpgraded}
                    onClose={this.requestDialogUpgradedClose}
                >
                    <DialogTitle>
                        {t('Your subscription had been upgraded')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t('We received your subscription adjustment')}{' '}
                            <br />
                            {t('subscription asjustments info')} <br />
                            {t('You can get started right away.')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                             
                            onClick={this.requestDialogUpgradedClose}
                        >
                            {t('Ok')}
                        </Button>
                    </DialogActions>
                </Dialog>

                <AppBar   className={classes.appBar}>
                    <Toolbar disableGutters>
                        <IconButton
                            className={classes.appBarButton}
                            color="inherit"
                            onClick={this.handleClose}
                            size="large">
                            <CloseIcon />
                        </IconButton>
                        <Typography
                            type="title"
                            color="inherit"
                            className={classes.flex}
                        >
                            {t('Update subscription')}
                        </Typography>
                    </Toolbar>
                </AppBar>

                <DialogContent className={classes.content}>
                    <Loader loading={loading}>
                        <div className={classes.abonnementContent}>
                            <div className={classes.abonnementInfoCard}>
                                <div
                                    style={{
                                        background: '#FFDD4A',
                                        height: '308px',
                                    }}
                                >
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            fontSize: '24px',
                                            fontFamily:
                                                'filson-soft,sans-serif',
                                            lineHeight: '24px',
                                            paddingTop: '36px',
                                        }}
                                    >
                                        {subscription.maxEmployees < 0
                                            ? t('Unlimited number of employees')
                                            : t('Till') +
                                              ' ' +
                                              subscription.maxEmployees +
                                              ' ' +
                                              t('employees')}
                                    </div>
                                    {accountType === 'cumela' &&
                                    subscription.maxEmployees > 0 ? (
                                        <div>
                                            <div
                                                style={{
                                                    opacity: '0.5',
                                                    fontSize: '14px',
                                                    color: '#282D35',
                                                    lineHeight: '24px',
                                                    textAlign: 'center',
                                                    paddingTop: '10px',
                                                }}
                                            >
                                                {t('Cumela discount')}
                                            </div>
                                            <div
                                                style={{
                                                    fontSize: '64px',
                                                    color: '#282D35',
                                                    lineHeight: '24px',
                                                    textAlign: 'center',
                                                    paddingTop: '47px',
                                                    fontFamily:
                                                        'filson-soft,sans-serif',
                                                }}
                                            >
                                                €
                                                {subscription.maxEmployees >
                                                    250 ||
                                                subscription.maxEmployees < 0
                                                    ? subscription.priceInteger
                                                    : subscription.price}
                                            </div>
                                            <div
                                                style={{
                                                    fontSize: '28.8px',
                                                    color: '#282D35',
                                                    lineHeight: '21.6px',
                                                    textAlign: 'center',
                                                    paddingTop: '31px',
                                                    fontFamily:
                                                        'filson-soft,sans-serif',
                                                    backgroundImage:
                                                        'url(/assets/images/stripe.svg)',
                                                    backgroundSize: '50% 50%',
                                                    backgroundRepeat:
                                                        'no-repeat',
                                                    backgroundPosition:
                                                        'bottom',
                                                }}
                                            >
                                                €{prijsZonderKorting}
                                            </div>
                                            <div
                                                style={{
                                                    opacity: '0.5',
                                                    fontSize: '17px',
                                                    color: '#282D35',
                                                    lineHeight: '24px',
                                                    textAlign: 'center',
                                                    paddingTop: '11px',
                                                }}
                                            >
                                                {t(
                                                    'Subscription price per year'
                                                )}
                                            </div>
                                        </div>
                                    ) : accountType === 'caoleo' &&
                                      subscription.maxEmployees > 0 ? (
                                        <div>
                                            <div
                                                style={{
                                                    opacity: '0.5',
                                                    fontSize: '14px',
                                                    color: '#282D35',
                                                    lineHeight: '24px',
                                                    textAlign: 'center',
                                                    paddingTop: '10px',
                                                }}
                                            >
                                                {t('Groen, grond discount')}
                                            </div>
                                            <div
                                                style={{
                                                    fontSize: '64px',
                                                    color: '#282D35',
                                                    lineHeight: '24px',
                                                    textAlign: 'center',
                                                    paddingTop: '47px',
                                                    fontFamily:
                                                        'filson-soft,sans-serif',
                                                }}
                                            >
                                                €
                                                {subscription.maxEmployees >
                                                    250 ||
                                                subscription.maxEmployees < 0
                                                    ? subscription.priceInteger
                                                    : subscription.price}
                                            </div>
                                            <div
                                                style={{
                                                    fontSize: '28.8px',
                                                    color: '#282D35',
                                                    lineHeight: '21.6px',
                                                    textAlign: 'center',
                                                    paddingTop: '31px',
                                                    fontFamily:
                                                        'filson-soft,sans-serif',
                                                    backgroundImage:
                                                        'url(/assets/images/stripe.svg)',
                                                    backgroundSize: '50% 50%',
                                                    backgroundRepeat:
                                                        'no-repeat',
                                                    backgroundPosition:
                                                        'bottom',
                                                }}
                                            >
                                                €{prijsZonderKorting}
                                            </div>
                                            <div
                                                style={{
                                                    opacity: '0.5',
                                                    fontSize: '17px',
                                                    color: '#282D35',
                                                    lineHeight: '24px',
                                                    textAlign: 'center',
                                                    paddingTop: '11px',
                                                }}
                                            >
                                                {t(
                                                    'Subscription price per year'
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <div
                                                style={{
                                                    fontSize: '64px',
                                                    color: '#282D35',
                                                    lineHeight: '24px',
                                                    textAlign: 'center',
                                                    paddingTop: '81px',
                                                    fontFamily:
                                                        'filson-soft,sans-serif',
                                                }}
                                            >
                                                €
                                                {subscription.maxEmployees >
                                                    250 ||
                                                subscription.maxEmployees < 0
                                                    ? subscription.priceInteger
                                                    : subscription.price}
                                            </div>
                                            <div
                                                style={{
                                                    opacity: '0.5',
                                                    fontSize: '17px',
                                                    color: '#282D35',
                                                    lineHeight: '24px',
                                                    textAlign: 'center',
                                                    paddingTop: '50px',
                                                }}
                                            >
                                                {t(
                                                    'Subscription price per year'
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div
                                    style={
                                        this.props.subscription.vv_functions &&
                                        this.props.subscription.maxEmployees.toString() ===
                                            subscription.maxEmployees.toString() &&
                                        this.props.subscription
                                            .credits_setup ===
                                            subscription.credits_setup
                                            ? {
                                                  background: '#FFDD4A',
                                                  height: '185px',
                                                  opacity: '0.8',
                                              }
                                            : this.props.subscription
                                                  .credits_setup === false &&
                                              subscription.credits_setup ===
                                                  true
                                            ? {
                                                  background: '#FFDD4A',
                                                  height:
                                                      subscriptionName.length >
                                                      39
                                                          ? '280px'
                                                          : '260px',
                                                  opacity: '0.8',
                                              }
                                            : {
                                                  background: '#FFDD4A',
                                                  height: '240px',
                                                  opacity: '0.8',
                                              }
                                    }
                                >
                                    <div
                                        style={{
                                            marginLeft: '16px',
                                            marginRight: '16px',
                                            paddingTop: '22px',
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontSize: '14px',
                                                marginBottom: '4px',
                                                lineHeight: '24px',
                                            }}
                                        >
                                            {t('Subscription')}
                                        </div>
                                        <b style={{ lineHeight: '24px' }}>
                                            {subscriptionName}
                                        </b>
                                        <Divider
                                            style={{
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                        />
                                        <div
                                            style={{
                                                fontSize: '14px',
                                                marginBottom: '4px',
                                                lineHeight: '24px',
                                            }}
                                        >
                                            {t('Number of employees')}
                                        </div>
                                        <b style={{ lineHeight: '24px' }}>
                                            {subscription.maxEmployees < 0
                                                ? t(
                                                      'Unlimited number of employees'
                                                  )
                                                : t('Till') +
                                                  ' ' +
                                                  subscription.maxEmployees +
                                                  ' ' +
                                                  t('active employees')}
                                        </b>
                                        {((this.props.subscription
                                            .vv_functions &&
                                            this.props.subscription.maxEmployees.toString() !==
                                                subscription.maxEmployees.toString()) ||
                                            this.props.subscription
                                                .credits_setup !==
                                                subscription.credits_setup) && (
                                            <Divider
                                                style={{
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                }}
                                            />
                                        )}
                                        {((this.props.subscription
                                            .vv_functions &&
                                            this.props.subscription.maxEmployees.toString() !==
                                                subscription.maxEmployees.toString()) ||
                                            this.props.subscription
                                                .credits_setup !==
                                                subscription.credits_setup) && (
                                            <div
                                                style={{
                                                    fontSize: '14px',
                                                    marginBottom: '4px',
                                                    lineHeight: '24px',
                                                }}
                                            >
                                                {t('New costs')}
                                            </div>
                                        )}
                                        {this.props.subscription.vv_functions &&
                                            this.props.subscription.maxEmployees.toString() !==
                                                subscription.maxEmployees.toString() && (
                                                <b
                                                    style={{
                                                        lineHeight: '24px',
                                                    }}
                                                >
                                                    €{subscription.price}{' '}
                                                    {t('yearly')}
                                                </b>
                                            )}
                                        {(this.props.subscription
                                            .vv_functions === false ||
                                            (this.props.subscription
                                                .vv_functions &&
                                                this.props.subscription.maxEmployees.toString() ===
                                                    subscription.maxEmployees.toString())) &&
                                            this.props.subscription
                                                .credits_setup === false &&
                                            subscription.credits_setup ===
                                                true && (
                                                <b
                                                    style={{
                                                        lineHeight: '24px',
                                                    }}
                                                >
                                                    {' '}
                                                    {subscription.credits}{' '}
                                                    {t('credits')} (&euro;{' '}
                                                    {this.state.creditsPrice}) +
                                                    {t('EENMALIGE KOSTEN')}{' '}
                                                    {t('start-up costs')} = €
                                                    {parseInt(
                                                        this.state.creditsPrice,
                                                        10
                                                    ) + OPSTARTPRIJS}
                                                </b>
                                            )}
                                        {this.props.subscription.vv_functions &&
                                            this.props.subscription.maxEmployees.toString() !==
                                                subscription.maxEmployees.toString() &&
                                            this.props.subscription
                                                .credits_setup === false &&
                                            subscription.credits_setup ===
                                                true && (
                                                <b
                                                    style={{
                                                        lineHeight: '24px',
                                                    }}
                                                >
                                                    {' '}
                                                    + {
                                                        subscription.credits
                                                    }{' '}
                                                    {t('credits')} (&euro;{' '}
                                                    {this.state.creditsPrice}) +
                                                    {t('EENMALIGE KOSTEN')}{' '}
                                                    {t('start-up costs')} = €
                                                    {parseInt(
                                                        this.state.creditsPrice,
                                                        10
                                                    ) +
                                                        parseInt(
                                                            subscription.price,
                                                            10
                                                        ) +
                                                        OPSTARTPRIJS}
                                                </b>
                                            )}
                                    </div>
                                </div>
                            </div>
                            <div className={classes.abonnementInfoContent}>
                                <h2 style={{ fontSize: '24px' }}>
                                    {t('Subscription details')}
                                </h2>
                                <p
                                    style={{
                                        opacity: '0.54',
                                        marginBottom: '50px',
                                        lineHeight: '24px',
                                    }}
                                >
                                    {subscription.maxEmployees > 0
                                        ? subscription.maxEmployees === 250
                                            ? subscription.credits_setup ===
                                              true
                                                ? t(
                                                      'more employees credits info'
                                                  )
                                                : t(
                                                      'Adjust current subscription wishes.'
                                                  )
                                            : t(
                                                  'Adjust current subscription wishes.'
                                              )
                                        : subscription.credits_setup === true
                                        ? t('max enjoyment over subscription')
                                        : t(
                                              'Adjust current subscription wishes.'
                                          )}
                                </p>
                                <Divider />
                                <div style={{ display: 'block' }}>
                                    <p
                                        style={{
                                            marginLeft: '10px',
                                            marginRight: '50px',
                                        }}
                                    >
                                        <b>{t('Subscription type')}</b>
                                        <div
                                            style={{
                                                color: '#0091EA',
                                                float: 'right',
                                            }}
                                        >
                                            {subscriptionName}
                                        </div>
                                    </p>
                                </div>
                                {!this.props.subscription.vv_functions && (
                                    <Divider />
                                )}
                                {!this.props.subscription.vv_functions && (
                                    <div style={{ display: 'block' }}>
                                        <p
                                            style={{
                                                marginLeft: '10px',
                                                marginRight: '10px',
                                            }}
                                        >
                                            <b>
                                                {t(
                                                    'Are you a member of CUMELA Netherlands?'
                                                )}
                                            </b>
                                            <IconButton
                                                onClick={this.openCumelaMenu}
                                                style={{
                                                    width: '40px',
                                                    float: 'right',
                                                    marginTop: '-15px',
                                                }}
                                                size="large">
                                                <ExpandMoreIcon />
                                            </IconButton>
                                            <div
                                                style={{
                                                    color: '#0091EA',
                                                    float: 'right',
                                                }}
                                                onClick={this.openCumelaMenu}
                                            >
                                                {subscription.cumela
                                                    ? t('Yes')
                                                    : t('No')}
                                            </div>
                                        </p>
                                    </div>
                                )}
                                {!this.props.subscription.vv_functions &&
                                    !subscription.cumela && <Divider />}
                                {!this.props.subscription.vv_functions &&
                                    !subscription.cumela && (
                                        <div style={{ display: 'block' }}>
                                            <p
                                                style={{
                                                    marginLeft: '10px',
                                                    marginRight: '10px',
                                                }}
                                            >
                                                <b>
                                                    {t(
                                                        'Collective laber agreement?'
                                                    )}
                                                </b>
                                                <IconButton
                                                    onClick={
                                                        this.openCaoleoMenu
                                                    }
                                                    style={{
                                                        width: '40px',
                                                        float: 'right',
                                                        marginTop: '-15px',
                                                    }}
                                                    size="large">
                                                    <ExpandMoreIcon />
                                                </IconButton>
                                                <div
                                                    style={{
                                                        color: '#0091EA',
                                                        float: 'right',
                                                    }}
                                                    onClick={
                                                        this.openCaoleoMenu
                                                    }
                                                >
                                                    {subscription.caoLeo
                                                        ? t('yes')
                                                        : t('No')}
                                                </div>
                                            </p>
                                        </div>
                                    )}
                                <Divider />
                                <div style={{ display: 'block' }}>
                                    <p
                                        style={{
                                            marginLeft: '10px',
                                            marginRight: '10px',
                                        }}
                                    >
                                        <b>{t('Number of employees')}</b>
                                        <IconButton
                                            onClick={this.openEmployeeMenu}
                                            style={
                                                this.props.subscription
                                                    .maxEmployees > 0 &&
                                                this.props.subscription
                                                    .maxEmployees < 250
                                                    ? {
                                                          visibility: 'visible',
                                                          width: '40px',
                                                          float: 'right',
                                                          marginTop: '-15px',
                                                      }
                                                    : {
                                                          visibility: 'hidden',
                                                          width: '40px',
                                                          float: 'right',
                                                          marginTop: '-15px',
                                                      }
                                            }
                                            size="large">
                                            <ExpandMoreIcon />
                                        </IconButton>
                                        <div
                                            style={{
                                                color: '#0091EA',
                                                float: 'right',
                                            }}
                                            onClick={this.openEmployeeMenu}
                                        >
                                            {subscription.maxEmployees < 0
                                                ? t(
                                                      'Unlimited number of employees'
                                                  )
                                                : t('Till') +
                                                  ' ' +
                                                  subscription.maxEmployees +
                                                  ' ' +
                                                  t('active employees')}
                                        </div>
                                    </p>
                                </div>
                                <Divider />
                                <div style={{ display: 'block' }}>
                                    <p
                                        style={{
                                            marginLeft: '10px',
                                            marginRight: '10px',
                                        }}
                                    >
                                        <b>{t('Module sneldelen')}</b>
                                        <div
                                            style={
                                                this.props.subscription
                                                    .credits_setup != null &&
                                                this.props.subscription
                                                    .credits_setup === true
                                                    ? {
                                                          opacity: '0.5',
                                                          float: 'right',
                                                          marginTop: '-15px',
                                                          marginRight: '10px',
                                                      }
                                                    : {
                                                          opacity: '1.0',
                                                          float: 'right',
                                                          marginTop: '-15px',
                                                          marginRight: '10px',
                                                      }
                                            }
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={
                                                            subscription.credits_setup
                                                        }
                                                        onChange={
                                                            this
                                                                .handleSneldelenChange
                                                        }
                                                    />
                                                }
                                                id="unsafeIncident"
                                            />
                                        </div>
                                    </p>
                                </div>
                                {this.props.subscription.credits_setup ===
                                    false &&
                                    subscription.credits_setup === true && (
                                        <Divider />
                                    )}
                                {this.props.subscription.credits_setup ===
                                    false &&
                                    subscription.credits_setup === true && (
                                        <div style={{ display: 'block' }}>
                                            <p
                                                style={{
                                                    marginLeft: '10px',
                                                    marginRight: '10px',
                                                }}
                                            >
                                                <b>{t('Credit amount')}</b>
                                                <IconButton
                                                    onClick={
                                                        this.openCreditsMenu
                                                    }
                                                    style={{
                                                        width: '40px',
                                                        float: 'right',
                                                        marginTop: '-15px',
                                                    }}
                                                    size="large">
                                                    <ExpandMoreIcon />
                                                </IconButton>
                                                <div
                                                    style={{
                                                        color: '#0091EA',
                                                        float: 'right',
                                                    }}
                                                    onClick={
                                                        this.openCreditsMenu
                                                    }
                                                >
                                                    {subscription.credits}{' '}
                                                    {t('credits')}
                                                </div>
                                            </p>
                                        </div>
                                    )}
                                <Divider />
                                <Button
                                    
                                    variant="contained"
                                    color="primary"
                                    className={classes.upgradeButton}
                                    onClick={this.handleUpgradeDialog}
                                    disabled={
                                        this.props.subscription.vv_functions &&
                                        this.props.subscription.maxEmployees.toString() ===
                                            subscription.maxEmployees.toString() &&
                                        this.props.subscription
                                            .credits_setup ===
                                            subscription.credits_setup
                                    }
                                >
                                    {subscription.vv_functions
                                        ? t('Upgrade')
                                        : t('Request')}
                                </Button>
                            </div>
                        </div>
                        <Paper elevation={0}>
                            <Popover
                                anchorEl={this.state.rowMenuAnchorElement}
                                id={this.uuid + '-rowMenu'}
                                open={this.state.menuOpen}
                                onClose={this.handleRowMenuRequestClose}
                            >
                                <List>
                                    {categories !== null &&
                                        categories
                                            .filter(
                                                (category) =>
                                                    category.visible === '1' &&
                                                    category.maxEmployees >=
                                                        this.props.subscription
                                                            .maxEmployees
                                            )
                                            .map((action) => (
                                                <ListItem
                                                    button
                                                    key={action.id}
                                                    onClick={this.handleEmployeesChange(
                                                        action
                                                    )}
                                                >
                                                    <ListItemText
                                                        primary={
                                                            action.aantalMedewerkersDescription
                                                        }
                                                    />
                                                </ListItem>
                                            ))}
                                </List>
                            </Popover>
                        </Paper>
                        <Paper elevation={0}>
                            <Popover
                                anchorEl={this.state.rowMenuAnchorElement}
                                id={this.uuid + '-rowMenu'}
                                open={this.state.menuCreditsOpen}
                                onClose={
                                    this.handleRowMenuCreditsRequestClose
                                }
                            >
                                <List>
                                    {credits !== null &&
                                        credits.map((action, index) => (
                                            <ListItem
                                                button
                                                key={index}
                                                onClick={this.handleCreditsChange(
                                                    action
                                                )}
                                            >
                                                <ListItemText
                                                    primary={
                                                        action.amount +
                                                        ' ' +
                                                        t('credits')
                                                    }
                                                />
                                            </ListItem>
                                        ))}
                                </List>
                            </Popover>
                        </Paper>
                        <Paper elevation={0}>
                            <Popover
                                anchorEl={this.state.rowMenuAnchorElement}
                                id={this.uuid + '-rowMenu'}
                                open={this.state.menuCumelaOpen}
                                onClose={
                                    this.handleRowMenuCumelaRequestClose
                                }
                            >
                                <List>
                                    <ListItem
                                        button
                                        key={'2'}
                                        onClick={this.handleCumelaChange(false)}
                                    >
                                        <ListItemText primary={t('No')} />
                                    </ListItem>
                                    <ListItem
                                        button
                                        key={'1'}
                                        onClick={this.handleCumelaChange(true)}
                                    >
                                        <ListItemText primary={t('Yes')} />
                                    </ListItem>
                                </List>
                            </Popover>
                        </Paper>
                        <Paper elevation={0}>
                            <Popover
                                anchorEl={this.state.rowMenuAnchorElement}
                                id={this.uuid + '-rowMenu'}
                                open={this.state.menuCaoleoOpen}
                                onClose={
                                    this.handleRowMenuCaoleoRequestClose
                                }
                            >
                                <List>
                                    <ListItem
                                        button
                                        key={null}
                                        onClick={this.handleCaoleoChange(false)}
                                    >
                                        <ListItemText primary={t('No')} />
                                    </ListItem>
                                    <ListItem
                                        button
                                        key={null}
                                        onClick={this.handleCaoleoChange(true)}
                                    >
                                        <ListItemText primary={t('Yes')} />
                                    </ListItem>
                                </List>
                            </Popover>
                        </Paper>
                    </Loader>
                </DialogContent>
            </Dialog>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    
    withTranslation()
)(AbonnementEdit);
