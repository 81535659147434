// @flow
import React from 'react';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import {withRouter} from "react-router";
import {Table, TableBody, TableHead, TableRow, TableCell} from '@mui/material';
import classes from './ToolboxTrendQuestionList.module.scss';

type Props = {
    projects: [],
};

type State = {
};

class ToolboxTrendQuestionList extends React.Component<Props, State> {
    static contextTypes = {
        router: PropTypes.object,
    };

    state = {
        page: 0,
        rowsPerPage: 100,
        questions: ["A","B","C","D","E","F","G","H","I"],

        actions: [],
        columns: [],
        confirmDialogOpen: false,
    };


    componentDidMount() {
    }

    getPercentage(correctCnt, incorrectCnt){
        if(correctCnt !== 0 && incorrectCnt !== 0){
            return Math.round(correctCnt / (correctCnt + incorrectCnt) * 100)
        }else if(incorrectCnt === 0 && correctCnt !== 0){
            return 100
        }
        else{
            return 0;
        }
    }

    renderAnswersPercentage = (total,answer,index) => {
        let percentage = 0;
        let uitvullen = "\u00A0\u00A0\u00A0";
        if(total !== 0){
            percentage = Math.round((answer.counter/total) * 100)
            if (percentage < 10) {
                uitvullen = "\u00A0\u00A0\u00A0";
            }
            if (percentage >= 10) {
                uitvullen = "\u00A0";
            }
            if (percentage >= 100) {
                uitvullen = ""
            }
        }
        if(percentage !== 0){
            return(
                <span>{percentage}% {uitvullen} {this.state.questions[index]}: {this.truncate(answer.title, 50) }</span>
            )
        }else{
            return(
                <span>0% {uitvullen} {this.state.questions[index]}: {this.truncate(answer.title, 50) }</span>
            )
        }
    }

    truncate = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0,maxLength) + '\u2026';
        }
        return text;
    }

    renderRow = (trendData) => {
        let i = 0;
        return (
            <TableRow>
                <TableCell>{trendData.title}</TableCell>
                <TableCell>{trendData.correctCnt}</TableCell>
                <TableCell>{trendData.incorrectCnt}</TableCell>
                <TableCell>{this.getPercentage(trendData.correctCnt, trendData.incorrectCnt)}%</TableCell>
                <TableCell>
                    <div className={classes.answerPercentageWrapper}>
                        {trendData.answers.map(answer =>{
                            return this.renderAnswersPercentage((trendData.correctCnt + trendData.incorrectCnt) ,answer, i++)
                        })}
                    </div>
                </TableCell>
            </TableRow>
        )
    }

    render() {
        const { t } = this.props;
        const data = List( this.props.answerTrend );
        return (
                <Table >
                    <TableHead className={classes.myhead}>
                        <TableCell width={"30%"}>{t("Question")}</TableCell>
                        <TableCell width={"10%"}>{t("Correct")}</TableCell>
                        <TableCell width={"10%"}>{t("Not correct")}</TableCell>
                        <TableCell width={"10%"}>{t("Correct (%)")}</TableCell>
                        <TableCell width={"40%"}>{t("Answers (%)")}</TableCell>
                    </TableHead>
                    <TableBody>
                        {data.map(trend =>(
                            this.renderRow(trend, classes)
                        ))}
                    </TableBody>
                </Table>
        );
    }
}


export default withRouter(
    connect(
    )(compose(
        
        withTranslation())(ToolboxTrendQuestionList))
);
