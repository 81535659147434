// @flow
import React from 'react';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EnhancedTable from '../../EnhancedTable';
import ConfirmDialog from '../../ConfirmDialog';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import {withRouter} from "react-router";

type Props = {
    onItemClick: (number, number, number) => mixed,
    inspectionsAssignsByProject: [],
    projects: Array<any>,
    deleteProject: Function,
};

type State = {
    page: number,
    rowsPerPage: number,
    startPage: number,
    startRowsPerPage: number,
    confirmDialogOpen: boolean,
    projectToDelete: String,
};

const mapStateToProps = (state) => {
    return {};
};


class InspectionTrendList extends React.Component<Props, State> {
    static contextTypes = {
        router: PropTypes.object,
    };

    state = {
        page: 0,
        rowsPerPage: 10,

        actions: [],
        columns: [],
        confirmDialogOpen: false,
    };

    componentDidMount() {
        this.setState({
            actions: [],
            columns: [
                { id: 'title', numeric: false, size: '60%', label: 'Title' },
                {
                    id: 'correct',
                    numeric: false,
                    size: '10%',
                    label: 'Correct',
                },
                {
                    id: 'incorrect',
                    numeric: false,
                    size: '10%',
                    label: 'Incorrect',
                },
                {
                    id: 'na',
                    numeric: false,
                    size: '10%',
                    label: 'NA',
                },
                {
                    id: 'unsafe',
                    numeric: false,
                    size: '10%',
                    label: "Unsafe",
                },
            ],
        });
    }

    setPage = () => {
        // Do nothing
    }
    setRowsPerPage = () => {
        // Do nothing
    }
    onRowClick = (project) => {
        // Do nothing
    };

    hideConfirmDialog = () => {
        this.setState({
            confirmDialogOpen: false,
        });
    };

    showConfirmDialog = () => {
        this.setState({
            confirmDialogOpen: true,
        });
    };

    mapTrendToRow = (trenditem) => {
        // Columns: title, correct, incorrect, na, unsafe
        let answerCnt = trenditem.correctCnt + trenditem.incorrectCnt + trenditem.naCnt;
        if(answerCnt === 0){
            return {
                ...trenditem,
                title: trenditem.title + ' - ' + trenditem.subTitle,
                correct: '0 %',
                incorrect: '0 %',
                na: '0 %',
                unsafe: trenditem.unsafeCnt
            };
        }else{
            return {
                ...trenditem,
                title: trenditem.title + ' - ' + trenditem.subTitle,
                correct: (100 * trenditem.correctCnt / answerCnt).toFixed(2) + ' %',
                incorrect: (100 * trenditem.incorrectCnt / answerCnt).toFixed(2) + ' %',
                na: (100 * trenditem.naCnt / answerCnt).toFixed(2) + ' %',
                unsafe: trenditem.unsafeCnt
            };
        }
    };


    handleAction = (event, action) => {
        if (action.id === 'edit') {
            this.props.editProject(action.rowId);
        } else if (action.id === 'delete') {
            //this.props.deleteProject(action.rowId);
            this.setState({
                projectToDelete: action.rowId,
            });
            this.showConfirmDialog();
        }
    };

    trendSorter = () => {
        // Columns: title, correct, incorrect, na, unsafe
        // Data: id, title, subTitle, correctCnt, incorrectCnt, naCnt, unsafeCnt

        return {
            title: (a, b) => {
                a = a.id;
                b = b.id;

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            correct: (a, b) => {
                a = a.correctCnt;
                b = b.correctCnt;

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            incorrect: (a, b) => {
                a = a.incorrectCnt;
                b = b.incorrectCnt;

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            unsafe: (a, b) => {
                a = a.unsafeCnt;
                b = b.unsafeCnt;

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
        };
    };

    render() {
        const { t } = this.props;
        const data = List( this.props.trendinspection );


        const { actions, columns } = this.state;

        data.filter((project) => {
            return (
                (project.omschrijving &&
                    project.omschrijving
                        .toLowerCase()
                        .indexOf(this.props.searchtext.toLowerCase()) > -1) ||
                (project.opdrachtgever &&
                    project.opdrachtgever
                        .toLowerCase()
                        .indexOf(this.props.searchtext.toLowerCase()) > -1) ||
                (project.externalId &&
                    project.externalId
                        .toLowerCase()
                        .indexOf(this.props.searchtext.toLowerCase()) > -1)
            );
        });

        // EnhancedTable only works with Arrays not with lists.
        let arrayFromList = [];
        if (this.props.trendinspection) {
            arrayFromList = Array.from(this.props.trendinspection);
        }

        return (
            <div>
                <ConfirmDialog
                    open={this.state.confirmDialogOpen}
                    handleClose={this.hideConfirmDialog}
                    deleteproject={this.state.projectToDelete}
                    deleteProjectFunction={this.props.deleteProject}
                />
                <EnhancedTable
                    hover
                    columns={columns}
                    onClick={this.onRowClick}
                    defaultOrder="desc"
                    defaultOrderBy={t('Description')}
                    rows={arrayFromList}
                    formatter={this.mapTrendToRow}
                    actions={actions}
                    onAction={this.handleAction}
                    sorter={this.trendSorter()}
                    emptyState={
                        this.props.trendinspection && this.props.trendinspection.length > 0
                            ? t('No searchresults')
                            : t(
                                  'No projects added click on the plus sign to add a project'
                              )
                    }
                    onPageChange={this.setPage}
                    onRowsPerPageChange={this.setRowsPerPage}
                    startPage={0}
                    rowsPerPage={10}
                    startRowsPerPage={10}

                />
            </div>
        );
    }
}

export default withRouter(
    connect(
        mapStateToProps,
    )(compose(withTranslation())(InspectionTrendList))
);
