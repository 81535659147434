// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import classnames from 'classnames';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {Fab} from '@mui/material';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Menu,
    MenuItem,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import {
    openDialog,
    setCrumbPath,
    deleteInspection,
    publishInspection,
    retractInspection,
    fetchInspections,
} from '../../actions';
import AppModule from '../AppModule';
import EnhancedTable from '../EnhancedTable';
import moment from '../../lib/moment';
import {
    inProgress,
    getVisibleInspectionVersions,
    getPermissions,
} from '../../selectors';
import TableActionHeader from '../TableActionHeader/TableActionHeader';
import getRoles from '../../selectors/getRoles';
import ConfirmDialog from '../ConfirmDialog';
import FilterHeader from '../FilterHeader';
import $ from 'jquery';
import { percentColors1 } from '../../lib/utils';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import {withRouter} from "react-router";
import i18n from "i18next";
import classes from './InspectionOverview.module.scss';

type Props = {
    loading: boolean,
    inspections: Array<any>,
    permissions: any,
    setCrumbPath: () => void,
    deployInspection: (inspectionId: string) => void,
    editInspection: (inspectionId: string) => void,
    publishInspection: (inspectionId: string) => void,
    retractInspection: (inspectionId: string) => void,
    deleteInspection: (inspectionId: string) => void,
    createInspection: () => void,
    roles: Array<any>,
};

type State = {
    page: number,
    rowsPerPage: number,
    searchtext: string,
    inspectionErrorDialogOpen: boolean,
    inspectionErrorDialogText: string,
    columns: Array<any>,
    actions: Array<{
        id: string,
        label: string,
        isVisible?: (id: any) => boolean,
    }>,
    openType: boolean,
    showConfirmDialog: boolean,
    typeFilter: any,
};


const mapStateToProps = (store) => {
    let inspections = getVisibleInspectionVersions(store);

    return {
        loading: !inProgress(store),
        inspections: inspections,
        permissions: getPermissions(store),
        roles: getRoles(store),
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        editInspection: (id) => {
            // window.$crisp.push(['do', 'chat:hide']);

            dispatch(push('/werkplekinspecties/' + id));
        },
        deleteInspection: (inspection) => {
            dispatch(deleteInspection(inspection));
        },
        deployInspection: (inspection) => {
            dispatch(openDialog('inspectionDeploy', { id: inspection }));
        },
        createInspection: () => {
            dispatch(openDialog('inspectionCreate'));
        },
        goToPreview: (werkplekinspectieId, deployedDate, page, rowsPerPage) => {
            dispatch(
                push(`/werkplekinspectie/preview/${werkplekinspectieId}#overzicht?page=${page}&rowsPerPage=${rowsPerPage}`)
            );
        },
        setCrumbPath: (page: number, rowsPerPage: number) => {
            dispatch(setCrumbPath({
                title: 'Werkplekinspecties',
                link: `/werkplekinspecties#overzicht?page=${page}&rowsPerPage=${rowsPerPage}`,

            }));
        },
        publishInspection: (inspectionId: string) => {
            dispatch(publishInspection(inspectionId));
        },
        retractInspection: (inspectionId: string) => {
            dispatch(retractInspection(inspectionId));
        },
        fetchInspections: () => {
            dispatch(fetchInspections());
        },
    };
};

class InspectionOverview extends React.Component<Props, State> {
    props: Props;
    state: State = {
        page: 0,
        rowsPerPage: 10,
        columns: [],
        actions: [],
        inspectionErrorDialogOpen: false,
        inspectionErrorDialogText: '',
        searchtext: '',
        showConfirmDialog: false,
        typeFilter: 0,
        openType: false,
    };

    componentDidMount() {
        const versionOrPublished =
            this.props.permissions.publish.inspection ||
            this.props.roles.includes('ADMIN')
                ? { id: 'published', label: 'Published', size: '15%' }
                : {
                      id: 'deployed',
                      size: '15%',
                      label: 'Sent',
                  };

        this.setState({
            columns: [
                { id: 'logo', size: '48px', label: '' },
                { id: 'title', size: '35%', label: 'Title' },
                {
                    id: 'version',
                    size: '10%',
                    label: 'Version',
                },
                { id: 'date',  size: '15%', label: 'Edited' },
                {
                    id: 'language',
                    size: '15%',
                    label: 'Language',
                },
                versionOrPublished,
            ],
        });

        // If url params rows and page go to right page
        console.log("INFO: hash: " + this.props.location.hash);
        if (this.props.location.hash.includes("overzicht")) {
            const searchString = '?' + this.props.location.hash.split("?")[1]
            console.log("INFO: Custom searchString: " + searchString);
            let jumpToPage = 0;
            if (searchString.includes("page")) {
                jumpToPage = new URLSearchParams(searchString).get("page");
                console.log("INFO: page from SearchParams: " + jumpToPage);
                if (isNaN(jumpToPage)) { jumpToPage = 0; }
                this.setState({ startPage: Number(jumpToPage)});
                this.setState({ page: Number(jumpToPage)});
            }
            let startRowsPerPage = 10;
            if (searchString.includes("rowsPerPage")) {
                startRowsPerPage = new URLSearchParams(searchString).get("rowsPerPage");
                if (isNaN(startRowsPerPage)) { startRowsPerPage = 10; }
                console.log("INFO: rowsPerPage from SearchParams: " + startRowsPerPage);
                this.setState({ startRowsPerPage: Number(startRowsPerPage)});
                this.setState({ rowsPerPage: Number(startRowsPerPage)});

            }
        }

        // this.props.setCrumbPath(jumpToPage, startRowsPerPage);

        // Clear crumbPath
        this.props.setCrumbPath();

        const { t } = this.props;

        let actions = [];

        if (this.props.permissions.inspections.edit) {
            actions.push({ id: 'edit', label: t('Change') });
        }

        if (this.props.permissions.inspections.delete) {
            actions.push({
                id: 'delete',
                label: t('Delete'),
                isVisible: (id) => this.deletable(id),
            });
        }

        if (this.props.permissions.inspections.deploy) {
            actions.push({
                id: 'deploy',
                label: t('Send out'),
                isVisible: (id) => this.isPublished(id),
            });
        }

        if (this.props.permissions.publish.toolbox) {
            actions.push({
                id: 'publish',
                label: t('Publish'),
                isVisible: (id) => !this.isPublished(id),
            });
        }

        if (this.props.roles.includes('ADMIN')) {
            actions.push({
                id: 'publish',
                label: t('Publish'),
                isVisible: (id) => this.isPublishableForAdmin(id),
            });
        }

        if (this.props.permissions.publish.toolbox) {
            actions.push({
                id: 'retract',
                label: t('Pull Back'),
                isVisible: (id) => this.isPublished(id),
            });
        }

        if (this.props.roles.includes('ADMIN')) {
            actions.push({
                id: 'retract',
                label: t('Pull Back'),
                isVisible: (id) => this.isPublishedAndAdminInspection(id),
            });
        }

        this.setState({ actions });

    }

    setPage = (page) => {
        // Store page in state
        this.setState({ page: page });
    }
    setRowsPerPage = (rowsPerPage) => {
        // Store rowsPerPage in state
        this.setState({ rowsPerPage: rowsPerPage });
    }


    handleAction = (event, action) => {
        if (action.id === 'edit') {
            this.props.editInspection(action.rowId);
        } else if (action.id === 'delete') {
            if (action.rowId) {
                this.setState({ inspectionToDelete: action.rowId });
                this.showConfirmDialog();
            }

            this.props.fetchInspections();
            //this.props.deleteInspection(action.rowId);
        } else if (action.id === 'deploy') {
            const inspection = this.props.inspections.find(
                (t) => t.id === action.rowId
            );
            if (inspection) {
                this.checkValidInspectionAndPerformAction(inspection, () =>
                    this.props.deployInspection(inspection.id)
                );
            }
        } else if (action.id === 'publish') {
            const inspection = this.props.inspections.find(
                (t) => t.id === action.rowId
            );
            if (inspection) {
                this.checkValidInspectionAndPerformAction(inspection, () =>
                    this.props.publishInspection(inspection.id)
                );
            }
        } else if (action.id === 'retract') {
            this.props.retractInspection(action.rowId);
        }
    };

    handleTypeFilterChange = (event) => {
        this.handleRequestClose();
        this.setState({ typeFilter: event.target.value });
    };

    showConfirmDialog = () => {
        this.setState({
            showConfirmDialog: true,
        });
    };

    hideConfirmDialog = () => {
        this.setState({
            showConfirmDialog: false,
        });
    };

    handleRequestClose = () => {
        const newState = Object.assign({}, this.state, { openType: false });
        this.setState(newState);
    };

    handleTouchTap0 = (event: Event) => {
        // This prevents ghost click.
        event.preventDefault();

        const newState = Object.assign({}, this.state, {
            openType: true,
            anchorEl: event.currentTarget,
        });

        this.setState(newState);
        setTimeout(() => {
            $('ul.tabs').tabs();
        }, 100);
    };

    getFilterBar = (searchresults) => {
        const { t } = this.props;
        let vvw = false;
        let cumela = false;
        let aangepast = false;
        let eigen = false;

        this.props.inspections.forEach((inspection) => {
            if (
                inspection.source !== 'cumela' &&
                inspection.source !== 'Janssen de Jong Groep' &&
                inspection.source !== 'global' &&
                inspection.rootInspectionSource !== 'cumela' &&
                inspection.rootInspectionSource !== 'global'
            ) {
                eigen = true;
            } else if (
                inspection.source !== 'cumela' &&
                inspection.source !== 'global' &&
                (inspection.rootInspectionSource === 'cumela' ||
                    inspection.rootInspectionSource === 'global')
            ) {
                aangepast = true;
            } else if (inspection.source === 'global') {
                vvw = true;
            } else if (inspection.source === 'cumela') {
                cumela = true;
            }
        });

        let inspections = this.props.inspections;
        inspections = inspections.filter((i) => {
            return !i.deleted;
        });

        return (
            <div className="filterbar">
                <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                    {inspections.length +
                        ' ' +
                        t('Workplace inspection') +
                        (inspections.length === 1 ? '' : 's') +
                        (this.state.searchtext.length > 0
                            ? (searchresults.length === 1
                                  ? ' - ' +
                                    searchresults.length +
                                    t('searchresult')
                                  : '') +
                              (searchresults.length > 1 ||
                              searchresults.length === 0
                                  ? ' - ' +
                                    searchresults.length +
                                    t('searchresults')
                                  : '')
                            : '')}
                </div>
                <div style={{ marginLeft: '20px' }}>
                    <span onClick={this.handleTouchTap0}>
                        <FilterHeader
                            filterTitle={'Type'}
                            filterValue={
                                this.state.typeFilter === 0
                                    ? t('Alles')
                                    : this.state.typeFilter === 1
                                    ? t('Veilig Vakwerk')
                                    : this.state.typeFilter === 2
                                    ? 'CUMELA'
                                    : this.state.typeFilter === 3
                                    ? t('Edited')
                                    : t('Own')
                            }
                        />
                    </span>
                </div>

                <Menu
                    open={this.state ? this.state.openType : false}
                    onClose={this.handleRequestClose}
                    value={this.state.typeFilter}
                    anchorEl={this.state ? this.state.anchorEl : 0}
                >
                    <MenuItem onClick={this.handleTypeFilterChange} value={0}>
                        {t('Everything')}
                    </MenuItem>
                    {vvw && (
                        <MenuItem
                            onClick={this.handleTypeFilterChange}
                            value={1}
                        >
                            {t('Veilig vakwerk')}
                        </MenuItem>
                    )}
                    {cumela && (
                        <MenuItem
                            onClick={this.handleTypeFilterChange}
                            value={2}
                        >
                            {t('CUMELA')}
                        </MenuItem>
                    )}
                    {aangepast && (
                        <MenuItem
                            onClick={this.handleTypeFilterChange}
                            value={3}
                        >
                            {t('Edited')}
                        </MenuItem>
                    )}
                    {eigen && (
                        <MenuItem
                            onClick={this.handleTypeFilterChange}
                            value={4}
                        >
                            {t('Own')}
                        </MenuItem>
                    )}
                </Menu>
            </div>
        );
    };

    checkValidInspectionAndPerformAction = (
        inspection,
        action: () => mixed
    ) => {
        // Check eerst of er wel categorien zijn
        if (!inspection.inspection.categories.length) {
            this.showInspectioncategoryCountError();
        } else {
            // Check of alle vragen ook mogelijke antwoorden hebben
            let categoriesWithoutAnswers = inspection.inspection.categories
                .map((c, i) => {
                    return { index: i, items: c.items };
                })
                .filter((c) => c.items.length < 1)
                .map((c) => c.index + 1);

            if (categoriesWithoutAnswers.length) {
                this.showInspectionCategoriesItemsError(
                    categoriesWithoutAnswers
                );
            } else {
                action();
            }
        }
    };

    showInspectioncategoryCountError = () => {
        const { t } = this.props;
        this.setState({
            inspectionErrorDialogOpen: true,
            inspectionErrorDialogText: t(
                'Workplace inspection must have at least one checklist'
            ),
        });
    };

    showInspectionCategoriesItemsError = (emptyCategories) => {
        const { t } = this.props;
        let text =
            t(
                'All workplace inspection checklists must have at least one item to be inspected. At checklist number'
            ) +
            emptyCategories
                .map((c, i) => {
                    let count = emptyCategories.length;
                    if (i === count - 1) {
                        return c;
                    } else if (i === count - 2) {
                        return c + t('and');
                    } else {
                        return c + ', ';
                    }
                })
                .join('') +
            t('not enough inspection parts have been added');
        this.setState({
            inspectionErrorDialogOpen: true,
            inspectionErrorDialogText: text,
        });
    };

    handleAddButtonClick = (event) => {
        this.props.createInspection();
    };

    handleSearch = (searchtext) => {
        this.setState({
            searchtext: searchtext,
        });
    };

    publishedToText = (publishState) => {
        let text = '';
        let colorValue = 0;

        switch (publishState) {
            case 'PUBLISHED':
                text = 'JA';
                colorValue = 1;
                break;
            case 'UNPUBLISHED':
                text = 'CONCEPT';
                break;
            case 'RETRACTED':
                text = 'CONCEPT';
                colorValue = 0.5;
                break;
            default:
                text = '-';
                break;
        }

        return (
            <div
                className={'border-text'}
                style={{
                    backgroundColor: getColorForPercentage(colorValue),
                    borderColor: getColorForPercentage(colorValue),
                    width: '78px',
                }}
            >
                {text}
            </div>
        );
    };

    mapInspectionToRow = (inspection) => {
        const { t } = this.props;
        moment.locale(i18n.t('LOCALE'));

        return {
            ...inspection,
            date: !inspection.date
                ? '-'
                : moment(inspection.date).format(t('dateTime')),
            deployed: !inspection.lastDeployed
                ? '-'
                : moment(inspection.lastDeployed).format(t('dateTime')),
            published: this.publishedToText(inspection.published),
            logo:
                inspection.source === 'cumela' ? (
                    <div className={'tooltip'}>
                        <span
                            className={'tooltiptext'}
                            style={{ left: '30px' }}
                        >
                            Cumela inspectie
                        </span>
                        <img
                            height="20"
                            width="20"
                            src="/assets/images/cumela-logo.png"
                            alt={t('Cumela logo')}
                        />
                    </div>
                ) : inspection.source === 'global' ? (
                    <div className={'tooltip'}>
                        <span
                            className={'tooltiptext'}
                            style={{ left: '10px' }}
                        >
                            {t('Veilig vakwerk inspection')}
                        </span>
                        <img
                            height="20"
                            width="20"
                            src="/assets/images/vvw-admin-logo.svg"
                            alt={t('Admin logo')}
                        />
                    </div>
                ) : inspection.rootInspectionSource === 'global' ||
                  inspection.rootInspectionSource === 'cumela' ? (
                    <div className={'tooltip'}>
                        <span
                            className={'tooltiptext'}
                            style={{ left: '10px' }}
                        >
                            {t('Custom inspection')}
                        </span>
                        <img
                            height="20"
                            width="20"
                            src="/assets/images/aangepast.svg"
                            alt={t('Custom logo')}
                        />
                    </div>
                ) : (
                    <div className={'tooltip'}>
                        <span
                            className={'tooltiptext'}
                            style={{ left: '25px' }}
                        >
                            {t('Own inspection')}
                        </span>
                        <img
                            height="20"
                            width="20"
                            src="/assets/images/vvw-logo.svg"
                            alt={t('Own logo')}
                        />
                    </div>
                ),
        };
    };

    deletable = (inspectionId) => {
        const inspection = this.props.inspections.find(
            (inspection) => inspection.id === inspectionId
        );

        return (
            (inspection.source === 'global' &&
                this.props.roles.includes('ADMIN')) ||
            (inspection.source === 'cumela' &&
                this.props.roles.includes('CUMELA')) ||
            (inspection.source === 'Janssen de Jong Groep' &&
                this.props.roles.includes('JANSSENDEJONG')) ||
            (inspection.source !== 'global' &&
                inspection.source !== 'cumela' &&
                inspection.source !== 'Janssen de Jong Groep')
        );
    };

    isPublished = (inspectionId) => {
        const inspection = this.props.inspections.find(
            (inspection) => inspection.id === inspectionId
        );

        if (!inspection) {
            return false;
        }

        return inspection.published === 'PUBLISHED';
    };

    isPublishedAndAdminInspection = (inspectionId) => {
        const inspection = this.props.inspections.find(
            (inspection) => inspection.id === inspectionId
        );

        if (!inspection) {
            return false;
        }

        if (inspection.source === 'global') {
            return inspection.published === 'PUBLISHED';
        }
        return false;
    };

    isPublishableForAdmin = (inspectionId) => {
        const inspection = this.props.inspections.find(
            (inspection) => inspection.id === inspectionId
        );

        if (!inspection) {
            return false;
        }

        if (
            inspection.source === 'global' &&
            (inspection.published === 'UNPUBLISHED' ||
                inspection.published === 'RETRACTED')
        ) {
            return true;
        }
        return false;
    };

    onRowClick = (row) => {
        if (row.id) {
            this.props.goToPreview(row.id, null, this.state.page, this.state.rowsPerPage);
        }
    };

    inspectionSorter = () => {
        return {
            deployed: (a, b) => {
                a = a.lastDeployed;
                b = b.lastDeployed;

                if (a == null) {
                    return -1;
                }
                if (b == null) {
                    return 1;
                }

                a = moment(a).valueOf();
                b = moment(b).valueOf();

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            title: (a, b) => {
                a = a.title.toLowerCase();
                b = b.title.toLowerCase();

                if (a > b) {
                    return 1;
                } else if (a < b) {
                    return -1;
                } else {
                    return 0;
                }
            },
            logo: (a, b) => {
                if (a.source > b.source) {
                    return 1;
                } else if (a.source < b.source) {
                    return -1;
                } else {
                    return 0;
                }
            },
        };
    };

    render() {
        const { loading, t } = this.props;
        const {
            actions,
            columns,
            inspectionErrorDialogOpen,
            inspectionErrorDialogText,
        } = this.state;

        let inspections = this.props.inspections;
        inspections = inspections.filter((i) => {
            return !i.deleted;
        });
        
        const searchresults = inspections
            .filter((inspection) => {
                let textFound = false;
                inspection.inspection.categories.forEach((cat) => {
                    if (
                        cat.title
                            .toLowerCase()
                            .indexOf(this.state.searchtext.toLowerCase()) > -1
                    ) {
                        textFound = true;
                    } else {
                        cat.items.forEach((i) => {
                            if (
                                i.title
                                    .toLowerCase()
                                    .indexOf(
                                        this.state.searchtext.toLowerCase()
                                    ) > -1
                            ) {
                                textFound = true;
                            }
                        });
                    }
                });

                return (
                    inspection.title
                        .toLowerCase()
                        .indexOf(this.state.searchtext.toLowerCase()) > -1 ||
                    textFound ||
                    inspection.source
                        .toLowerCase()
                        .indexOf(this.state.searchtext.toLowerCase()) > -1
                );
            })
            .filter((inspection) => {
                if (this.state.typeFilter === 0) {
                    return true;
                } else if (this.state.typeFilter === 1) {
                    if (inspection.source === 'global') {
                        return true;
                    }
                } else if (this.state.typeFilter === 2) {
                    if (inspection.source === 'cumela') {
                        return true;
                    }
                } else if (this.state.typeFilter === 3) {
                    if (
                        inspection.source !== 'cumela' &&
                        inspection.source !== 'global' &&
                        (inspection.rootInspectionSource === 'cumela' ||
                            inspection.rootInspectionSource === 'global')
                    ) {
                        return true;
                    }
                } else if (this.state.typeFilter === 4) {
                    if (
                        inspection.source !== 'cumela' &&
                        inspection.source !== 'global' &&
                        inspection.rootInspectionSource !== 'cumela' &&
                        inspection.rootInspectionSource !== 'global'
                    ) {
                        return true;
                    }
                }
                return false;
            });

        const tableActionHeader = (
            <TableActionHeader
                searchPlaceholder="Zoek binnen werkplekinspecties"
                onSearchChange={this.handleSearch}
                title={this.getFilterBar(searchresults)}
            />
        );

        return (
            <div>
                <AppModule
                    loading={loading}
                    hasTabs
                    prepend={tableActionHeader}
                >
                    <Dialog
                        open={inspectionErrorDialogOpen ? inspectionErrorDialogOpen : false}
                        onClose={() => {
                            this.setState({ inspectionErrorDialogOpen: false });
                        }}
                    >
                        <DialogTitle>
                            {t('Workplace inspection cannot be send out yet')}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {inspectionErrorDialogText}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    this.setState({
                                        inspectionErrorDialogOpen: false,
                                    });
                                }}
                                color="primary"
                            >
                                {t('Ok')}
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <ConfirmDialog
                        open={this.state.showConfirmDialog ? this.state.showConfirmDialog : false}
                        handleClose={this.hideConfirmDialog}
                        deleteinspection={this.state.inspectionToDelete}
                        deleteInspectionFunction={this.props.deleteInspection}
                    />

                    <div
                        className={classnames({
                            [classes.button]: true,
                            'mui-fixed': true,
                        })}
                    >
                        <div className={'tooltip'}>
                            <span
                                className={'tooltiptext'}
                                style={{ top: '60px' }}
                            >
                                {t('Create new inspection')}
                            </span>
                            <Fab
                                onClick={this.handleAddButtonClick}
                            >
                                <AddIcon />
                            </Fab>
                        </div>
                    </div>
                    <EnhancedTable
                        defaultOrderBy={'deployed'}
                        defaultOrder={'asc'}
                        hover
                        onClick={this.onRowClick}
                        columns={columns}
                        rows={searchresults}
                        formatter={this.mapInspectionToRow}
                        actions={actions}
                        onAction={this.handleAction}
                        sorter={this.inspectionSorter()}
                        emptyState={
                            this.state.searchtext.length > 0
                                ? t('No searchresults')
                                : t(
                                      'No workplace inspections have been added yet, add a workplace inspection by clicking the plus sign at the top right'
                                  )
                        }

                        onPageChange={this.setPage}
                        onRowsPerPageChange={this.setRowsPerPage}
                        startPage={this.state.startPage}
                        startRowsPerPage={this.state.startRowsPerPage}

                    />
                </AppModule>
            </div>
        );
    }
}

var percentColors = percentColors1;

var getColorForPercentage = function (pct) {
    let color = percentColors[0];

    if (pct === 0) {
        color = percentColors[0];
    }

    if (pct === 1) {
        color = percentColors[2];
    }

    return 'rgba(' + [color.r, color.g, color.b].join(',') + ', 1)';
};


export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(compose(withTranslation(), )(InspectionOverview))
);
