// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';
import moment from '../../lib/moment';
import {
    fetchEmployees,
    getCurrentEmployee,
    fetchSubscriptions,
    upgradeSubscription,
    upgradeCredits,
    editEmployee,
} from '../../actions';

import AppModule from '../AppModule';
import TitleDescription from '../TitleDescription';
import { TEmployee } from '../../reducers/employeesReducer';
import { setCrumbPath } from '../../actions/uiActions';
import TableActionHeader from '../TableActionHeader/TableActionHeader';

import classes from './Abonnement.module.scss';
import { Button, IconButton } from '@mui/material';
import { getPermissions } from '../../selectors';
import EditIcon from '@mui/icons-material/Edit';
import { push } from 'connected-react-router';
import EmployeeEdit from '../Employee/EmployeeEdit';
import AbonnementEdit from './AbonnementEdit/AbonnementEdit';
import PaymentDialog from './PaymentDialog/PaymentDialog';
import AbonnementCredits from './AbonnementCredits/AbonnementCredits';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

type Props = {
    loading: boolean,
    employees: Array<any>,
    user: mixed,
    fetchEmployee: () => List<TEmployee>,
    getCurrentEmployee: () => TEmployee,
    parseImport: (file: any) => void,
    acknowledgeImport: (file: any) => void,
    clearDiff: () => void,
    onSave: (employee?: TEmployee) => void,
    editemployee: TEmployee,
    inProgress: boolean,
    diff: { removed: List<TEmployee>, added: List<TEmployee> },
};

type State = {
    showUpload: boolean,
    showDialogChoose: boolean,
    showConfirmDialog: boolean,
    currentEmpl: TEmployee,
    showUpgradeDialog: boolean,
    showCreditsDialog: boolean,
    showPaymentInfoDialog: boolean,
    editAbonnement: boolean,
    editPaymentInfo: boolean,
};

const getSubscriptionUpgrade = (subscription, subscriptionStubs) => {
    if (subscription && subscriptionStubs && subscriptionStubs.prijzen) {
        const prijzen = subscriptionStubs.prijzen;
        for (let i = 0; i < prijzen.length; i++) {
            const prijs = prijzen[i];
            if (prijs.maxEmployees > subscription.maxEmployees) {
                return prijs;
            }
        }

        const maxPrijs = prijzen[prijzen.length - 1]; // Max grootte
        maxPrijs.maxEmployees = -1;
        return maxPrijs;
    }
    return null;
};

const mapStateToProps = (store) => {
    const subscription = store.drafts.employees.currentUser
        ? store.drafts.employees.currentUser.subscription
        : null;

    return {
        currentEmpl: store.drafts.employees.currentUser,
        allEmployees: store.entities.employees,
        subscription: subscription,
        subscriptionUpgrade: getSubscriptionUpgrade(
            subscription,
            store.subscription.subscriptionStubs
        ),
        permissions: getPermissions(store),
        editemployee: store.drafts.employees.editemployee,
        editAbonnement: false,
        editPaymentInfo: false,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setCrumbPath: (companyName: string) => {
            dispatch(
                setCrumbPath({
                    title: 'Abonnement',
                })
            );
        },
        fetchCurrentUser: () => {
            dispatch(getCurrentEmployee());
        },
        goToDialog: () => {
            dispatch(push('#dialog'));
        },
        editabonnement: () => {
            // window.$crisp.push(['do', 'chat:hide']);
            dispatch(push('/abonnement/upgrade'));
        },
        editpaymentinfo: () => {
            // window.$crisp.push(['do', 'chat:hide']);
            dispatch(push('/abonnement/payment'));
        },
        editcredits: () => {
            // window.$crisp.push(['do', 'chat:hide']);
            dispatch(push('/abonnement/credits'));
        },
        editEmployee: bindActionCreators(editEmployee, dispatch),
        getCurrentEmployee: bindActionCreators(getCurrentEmployee, dispatch),
        fetchEmployees: bindActionCreators(fetchEmployees, dispatch),
        fetchSubscriptions: bindActionCreators(fetchSubscriptions, dispatch),
        upgradeSubscription: bindActionCreators(upgradeSubscription, dispatch),
        upgradeCredits: bindActionCreators(upgradeCredits, dispatch),
    };
};

class Abonnement extends React.Component<Props, State> {
    props: Props;
    state: State = {
        showUpgradeDialog: false,
        showCreditsDialog: false,
        showPaymentInfoDialog: false,
    };

    componentDidMount() {
        this.props.getCurrentEmployee();
        this.props.fetchEmployees();
        this.props.fetchSubscriptions();
        this.props.fetchCurrentUser();
    }

    componentWillReceiveProps(newProps) {
        const { t } = this.props;
        this.props.setCrumbPath(
            newProps.currentEmpl && newProps.currentEmpl.subscription
                ? newProps.currentEmpl.subscription.companyName
                : t('Overview')
        );
    }

    upgradeSubscription = () => {
        this.props.fetchCurrentUser();
    };

    editEmployee = () => {
        this.props.editEmployee(this.props.currentEmpl);
        this.props.goToDialog();
    };

    editPaymentInfo = () => {
        this.props.fetchCurrentUser();
    };

    render() {
        const { currentEmpl, allEmployees, t } = this.props;
        let subscription;
        let employeesSize;

        const paramId = this.props.match.params.id;
        const showDetailDialog = paramId === 'upgrade';
        const showCreditsDialog = paramId === 'credits';
        const showPaymentInfoDialog = paramId === 'payment';

        const editAction = (
            <IconButton
                onClick={this.editEmployee}
                className={classes.editButton}
                key="edit"
                size="large">
                <EditIcon />
            </IconButton>
        );

        if (currentEmpl && currentEmpl.subscription) {
            subscription = currentEmpl.subscription;
            if (allEmployees && allEmployees.allIds) {
                employeesSize = allEmployees.allIds.length;
            } else {
                employeesSize = 0;
            }
        } else {
            return <div />;
        }

        const tableActionHeader = <TableActionHeader editAction={editAction} />;

        const totalEmployees =
            employeesSize +
            ' ' +
            t('employee') +
            (employeesSize === 1 ? '' : 's');
        const activeEmployees =
            '(' +
            allEmployees.allIds
                .map((id) => allEmployees.byId[id])
                .filter((e) => e.state === 'ACTIVE').length +
            ' ' +
            t('active') +
            ')';

        const { permissions } = this.props;

        const upgradeButton = (
            <Button
                
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={this.props.editabonnement}
            >
                {subscription.vv_functions
                    ? t('Upgrade subscription')
                    : t('Request subscription')}
            </Button>
        );
        const paymentInfoButton = (
            <Button
                
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={this.props.editpaymentinfo}
            >
                {t('Payment details')}
            </Button>
        );

        const buyCreditsButton = (
            <Button
                
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={this.props.editcredits}
            >
                {t('Upgrade credits')}
            </Button>
        );

        const creditsFriendly = subscription.credits ? subscription.credits : 0;
        const abonneeType =
            subscription.credits_setup && !subscription.vv_functions
                ? t('Sneldelen only')
                : subscription.cumela === true
                ? subscription.credits_setup
                    ? t('Cumela met Sneldelen')
                    : 'Cumela'
                : subscription.caoLeo === true
                ? subscription.credits_setup
                    ? t('cao Groen, Grond en Infrastructuur met Sneldelen')
                    : t('cao Groen, Grond en Infrastructuur')
                : subscription.credits_setup
                ? t('Standaard met Sneldelen')
                : t('Standard');

        let noOfEmployees;
        if (!subscription.vv_functions && subscription.credits_setup) {
            // TODO: No of external employees
            noOfEmployees = subscription.maxEmployees;
        } else {
            noOfEmployees =
                subscription.maxEmployees < 0
                    ? t('Unlimited number of employees')
                    : t('Till') +
                      ' ' +
                      subscription.maxEmployees +
                      ' ' +
                      t('active employees');
        }

        // Internet Explorer bugfix
        if (!String.prototype.endsWith) {
            //eslint-disable-next-line
            String.prototype.endsWith = function (searchString, position) {
                let subjectString = this.toString();
                if (
                    typeof position !== 'number' ||
                    !isFinite(position) ||
                    Math.floor(position) !== position ||
                    position > subjectString.length
                ) {
                    position = subjectString.length;
                }
                position -= searchString.length;
                let lastIndex = subjectString.indexOf(searchString, position);
                return lastIndex !== -1 && lastIndex === position;
            };
        }

        let dialogEndsWith = window.location.href.endsWith('dialog');

        return permissions.subscription.view ? (
            <AppModule loading={false} prepend={tableActionHeader}>
                <div className="myContainer">
                    <h2>{t('Company details')}</h2>
                    <TitleDescription
                        className={classes.item}
                        itemTitle={t('Company name')}
                        itemDescription={subscription.companyName}
                    />
                    <TitleDescription
                        className={classes.item}
                        itemTitle={t('Member since')}
                        itemDescription={moment(
                            subscription.dateCreatedOn,
                            ''
                        ).format('DD/MM/YYYY')}
                    />
                    <TitleDescription
                        className={classes.item}
                        itemTitle={t('Contact person')}
                        itemDescription={
                            currentEmpl.fullname ? currentEmpl.fullname : '-'
                        }
                    />
                    {!(
                        subscription.credits_setup && !subscription.vv_functions
                    ) && (
                        <TitleDescription
                            className={classes.item}
                            itemTitle={t('Number of employees')}
                            itemDescription={
                                subscription.vv_functions
                                    ? totalEmployees + ' ' + activeEmployees
                                    : totalEmployees
                            }
                        />
                    )}
                    <TitleDescription
                        className={classes.item}
                        itemTitle={t('Emailadres')}
                        itemDescription={currentEmpl.username}
                    />
                    <TitleDescription
                        className={classes.item}
                        itemTitle={t('Subscription type')}
                        itemDescription={abonneeType}
                    />
                    <TitleDescription
                        className={classes.item}
                        itemTitle={t('Telephone number')}
                        itemDescription={
                            currentEmpl.phonenumber
                                ? currentEmpl.phonenumber
                                : '-'
                        }
                    />
                    <TitleDescription
                        className={classes.item}
                        itemTitle={t('Invoice email')}
                        itemDescription={
                            subscription.factuurEmail
                                ? subscription.factuurEmail
                                : '-'
                        }
                    />
                    {!subscription.vv_functions && (
                        <TitleDescription
                            className={classes.item}
                            itemDescription={null}
                        />
                    )}
                    {subscription.vv_functions && (
                        <TitleDescription
                            className={classes.item}
                            itemTitle={t('Subscription size')}
                            itemDescription={noOfEmployees}
                        />
                    )}
                    {subscription.vv_functions &&
                        !subscription.credits_setup && (
                            <TitleDescription className={classes.item} />
                        )}
                    {subscription.credits_setup && (
                        <TitleDescription
                            className={classes.item}
                            itemTitle={t('Sneldelen Credits')}
                            itemDescription={creditsFriendly}
                        />
                    )}
                    <TitleDescription
                        className={classes.item}
                        itemTitle={
                            subscription.vv_functions
                                ? t('Upgrade subscription')
                                : t('Request subscription')
                        }
                        itemDescription={upgradeButton}
                    />
                    {subscription.changePayment && (
                        <TitleDescription
                            className={classes.item}
                            itemTitle={
                                subscription.vv_functions
                                    ? t('Payment details')
                                    : t('Collection details')
                            }
                            itemDescription={paymentInfoButton}
                        />
                    )}
                    {subscription.credits_setup && (
                        <TitleDescription
                            className={classes.item}
                            itemTitle={t('Upgrade credits')}
                            itemDescription={buyCreditsButton}
                        />
                    )}
                </div>
                <EmployeeEdit
                    open={dialogEndsWith}
                    editemployee={this.props.editemployee}
                    abonneehouder={true}
                    factuurEmail={subscription.factuurEmail}
                    subscription={subscription}
                    pageAfterSave={'/abonnement'}
                />
                <AbonnementEdit
                    open={showDetailDialog}
                    subscription={subscription}
                    onUpgraded={this.upgradeSubscription}
                />
                <AbonnementCredits
                    open={showCreditsDialog}
                    subscription={subscription}
                    onUpgraded={this.upgradeSubscription}
                />
                {subscription.changePayment && (
                    <PaymentDialog
                        open={showPaymentInfoDialog}
                        subscription={subscription}
                        onUpgraded={this.editPaymentInfo}
                    />
                )}
            </AppModule>
        ) : (
            <AppModule loading={false} prepend={tableActionHeader}>
                <div className="myContainer">{t('No page access')}</div>
            </AppModule>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    
    withTranslation()
)(Abonnement);
