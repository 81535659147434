// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import {
	fetchSubscriptionFunctionality,
	fetchSubscriptionFunctionalityWhitelabel,
	fetchSubscriptionFunctionalityWhitelabelAppStoreCodes,
	parseSubscriptionFunctionalityWhitelabelAppStoreCodes,
	setCrumbPath,
	showSnackbar
} from '../../actions/index';
import {inProgress} from '../../selectors/index';

import classes from './SubscriptionFunctionalities.module.scss';
import AppModule from '../AppModule';
import TableActionHeader from '../TableActionHeader/TableActionHeader';
import {
    Dialog,
    DialogTitle,
    Divider,
    IconButton,
} from '@mui/material';
import TitleSwitch from '../TitleSwitch';
import TitleDescription from '../TitleDescription';
import Slide from '@mui/material/Slide';
import EditIcon from '@mui/icons-material/Edit';
import SimpleImageInput from '../SimpleImageInput';
import WhiteLabelEditCard from './WhitelabelEditCard';
import { connect } from 'react-redux';
import { isUndefinedOrNull } from '../../lib/utils';
import { changeSubscriptionFunctionality } from '../../actions';
import type { TSubscriptionFunctionality } from '../../reducers/subscriptionReducer';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import FileUpload from "../Util/FileUpload";

type Props = {
	loading: boolean,
	cumela?: boolean,
	columns?: any,
	fetchSubscriptionFunctionality: (subscriptionId: number) => mixed,
	changeSubscriptionFunctionality: (
	    subscriptionId: number,
        subscriptionFunctionality: TSubscriptionFunctionality
    ) => mixed,
	fetchSubscriptionFunctionalityWhitelabel: (subscriptionId: number) => mixed,
	fetchSubscriptionFunctionalityWhitelabelAppStoreCodes: (subscriptionId: number) => mixed,
};

type State = {};


const mapDispatchToProps = (dispatch) => {
    return {
        setCrumbPath: (abonneeCompanyName: string) => {
            dispatch(
                setCrumbPath({
                    title: 'Abonnee',
                    crumbs: [{ name: abonneeCompanyName, link: '/abonnee' }],
                })
            );
        },
        showSnackbar: (text) => {
            dispatch(showSnackbar(text));
        },
        changeSubscriptionFunctionality: (
            subscriptionId: number,
            subscriptionFunctionality: TSubscriptionFunctionality
        ) => {
            dispatch(
                changeSubscriptionFunctionality(
                    subscriptionId,
                    subscriptionFunctionality
                )
            );
        },
        fetchSubscriptionFunctionality: (subscriptionId: number) => {
            dispatch(fetchSubscriptionFunctionality(subscriptionId));
        },
        fetchSubscriptionFunctionalityWhitelabel: (subscriptionId: number) => {
            dispatch(fetchSubscriptionFunctionalityWhitelabel(subscriptionId));
        },
        fetchSubscriptionFunctionalityWhitelabelAppStoreCodes: (subscriptionId: number) => {
			dispatch(fetchSubscriptionFunctionalityWhitelabelAppStoreCodes(subscriptionId));
		},
		parseWhitelabelAppStoreCodes: (subscriptionId: number, file : any) => {
			dispatch(parseSubscriptionFunctionalityWhitelabelAppStoreCodes(subscriptionId, file))
		}
	};
};

const mapStateToProps = (store, props) => {
    return {
        functionality: store.subscription.functionality,
        functionalityWhitelabel: store.subscription.functionalityWhitelabel,
        functionalityWhitelabelAppStoreCodes: store.subscription.functionalityWhitelabelAppStoreCodes,
        loading: !inProgress(store),
    };
};

function Transition(props) {
    return <Slide direction="up" {...props} />;
}


class SubscriptionFunctionalities extends React.Component<Props, State> {
    state: State = {
        loading: false,
        showWhitelabelEditDialog: false,
    };


    componentDidMount() {
        const {
            abonnee,
            fetchSubscriptionFunctionality,
            fetchSubscriptionFunctionalityWhitelabel,
            fetchSubscriptionFunctionalityWhitelabelAppStoreCodes,
        } = this.props;
        fetchSubscriptionFunctionality(abonnee.id);
        fetchSubscriptionFunctionalityWhitelabel(abonnee.id);
        fetchSubscriptionFunctionalityWhitelabelAppStoreCodes(abonnee.id)
    }

    handleFunctionalitySwitch = (key, value) => {
        const {
            abonnee,
            functionality,
            changeSubscriptionFunctionality,
        } = this.props;
        changeSubscriptionFunctionality(abonnee.id, {
            ...functionality,
            [key]: value,
        });
    };

    handleShowEditDialog = (key) => {
        this.setState({
            [key]: true,
        });
    };

    handleHideEditDialog = (key) => {
        this.setState({
            [key]: false,
        });
    };

	uploadCsvFile = (file : any) => {
		const {abonnee, parseWhitelabelAppStoreCodes} = this.props;
		parseWhitelabelAppStoreCodes(abonnee.id, file)
	};

    render() {
        const {
            style,
            abonnee,
            functionality = {},
            functionalityWhitelabel = {},
            functionalityWhitelabelAppStoreCodes = {},
            t,
        } = this.props;
        const { showWhitelabelEditDialog, loading } = this.state;

        const functionalities = {
            whitelabel: {
                id: 'whitelabel',
                title: t('Whitelabel'),
            },
            microsoftAzureAD: {
                id: 'microsoftAzureAD',
                title: t('Microsoft Azure AD'),
            },
            multiLanguage: {
                id: 'multilanguage',
                title: t('Multi language'),
            },
        };

        if (!abonnee) return <div />;
        if (loading) return <div />;

        const tableActionHeader = (
            <TableActionHeader
                title={<span style={{ paddingLeft: '20px' }}></span>}
            />
        );

        return (
            <div>
                <AppModule hasTabs prepend={tableActionHeader}>
                    <div style={{ style }} className={classes.root}>
                        <div className={classes.header}>{t('Functionalities')}</div>
                        <Divider className={classes.divider} />
                        <TitleSwitch
                            className={classes.item}
                            itemTitle={functionalities.whitelabel.title}
                            initialValue={functionality.whitelabelEnabled}
                            onSwitch={(value) =>
                                this.handleFunctionalitySwitch(
                                    'whitelabelEnabled',
                                    value
                                )
                            }
                        >
                            <IconButton
                                onClick={() =>
                                    this.handleShowEditDialog(
                                        'showWhitelabelEditDialog'
                                    )
                                }
                            >
                                <EditIcon />
                            </IconButton>
                        </TitleSwitch>

                        {functionality.whitelabelEnabled === true && (
                            <div>
                                <TitleDescription
                                    className={classes.item}
                                    itemTitle={t('Url webversion')}
                                    itemDescription={
                                        isUndefinedOrNull(
                                            functionalityWhitelabel.url
                                        )
                                            ? ''
                                            : `www.${functionalityWhitelabel.url}.veiligvakwerk.nl/web`
                                    }
                                    style={{ width: '100%' }}
                                />
                                <TitleDescription
                                    className={classes.item}
                                    itemTitle={t('Package name iOS')}
                                    itemDescription={
                                        functionalityWhitelabel.packageNameIos
                                    }
                                />
                                <TitleDescription
                                    className={classes.item}
                                    itemTitle={t('Package name android')}
                                    itemDescription={
                                        functionalityWhitelabel.packageNameAndroid
                                    }
                                />
                                <TitleDescription
                                    className={classes.item}
                                    itemTitle={t('App Store link iOS')}
                                    itemDescription={
                                        functionalityWhitelabel.storeLinkIos
                                    }
                                    style={{ width: '100%' }}
                                />
                                <p>{t('Settings e-mail template')}</p>
                                <TitleDescription
                                    className={classes.item}
                                    itemTitle={t('App name')}
                                    itemDescription={
                                        functionalityWhitelabel.appName
                                    }
                                />
                                <TitleDescription
                                    className={classes.item}
                                    itemTitle={t('Support e-mail adres')}
                                    itemDescription={
                                        functionalityWhitelabel.supportMailAddress
                                    }
                                />
                                <TitleDescription
                                    className={classes.item}
                                    itemTitle={t('Team name')}
                                    itemDescription={
                                        functionalityWhitelabel.teamName
                                    }
                                />
                                <TitleDescription
                                    className={classes.item}
                                    itemTitle={t('Organisation name')}
                                    itemDescription={
                                        functionalityWhitelabel.organisationName
                                    }
                                />
                                <TitleDescription
                                    className={classes.item}
                                    itemTitle={t('Seperate backend')}
                                    itemDescription={
                                        functionalityWhitelabel.separateBackend
                                            ? t('Yes')
                                            : t('No')
                                    }
                                />

                                <TitleDescription
                                    className={classes.item}
                                    itemTitle={t('Main color')}
                                    itemDescription={
                                        functionalityWhitelabel.mailMainColor
                                    }
                                />
                                <TitleDescription
                                    className={classes.item}
                                    itemTitle={t('Font color')}
                                    itemDescription={
                                        functionalityWhitelabel.mailFontColor
                                    }
                                />
                                <TitleDescription
                                    className={classes.item}
                                    itemTitle={t('Logo')}
                                    itemDescription={
                                        functionalityWhitelabel.logo
                                            ? ''
                                            : t('No photo set')
                                    }
                                >
                                    {functionalityWhitelabel.logo && (
                                        <SimpleImageInput
                                            disabled={true}
                                            url={
                                                functionalityWhitelabel.logo &&
                                                functionalityWhitelabel.logo.url
                                            }
                                        />
                                    )}
                                </TitleDescription>

                                <p>{t('whitelabel_codes_title')}</p>
                                <TitleDescription
                                    className={classes.item}
                                    itemTitle={t('whitelabel_code_upload_available_codes')}
                                    itemDescription={functionalityWhitelabelAppStoreCodes.availableCodes | "0"}
                                />
                                <TitleDescription
                                    className={classes.item}
                                    itemTitle={t('whitelabel_code_upload_used_codes')}
                                    itemDescription={functionalityWhitelabelAppStoreCodes.claimedCodes | "0"}
                                />

                                <TitleDescription
                                    className={classes.item}
                                    itemTitle={t('whitelabel_code_upload_new_codes')}
                                    itemDescription={t('whitelabel_code_upload_warning')}
                                >
                                    <FileUpload
                                        title={t('whitelabel_code_upload_new_codes_input')}
                                        filled={true}
                                        onUpload={this.uploadCsvFile}
                                    />
                                </TitleDescription>
                            </div>
                        )}

                        <TitleSwitch
                            disabled={true}
                            initialValue={functionality.azureEnabled}
                            className={classes.item}
                            itemTitle={functionalities.microsoftAzureAD.title}
                            onSwitch={(value) =>
                                this.handleFunctionalitySwitch(
                                    'azureEnabled',
                                    value
                                )
                            }
                        />
                        <TitleSwitch
                            disabled={true}
                            initialValue={functionality.multiLanguageEnabled}
                            className={classes.item}
                            itemTitle={functionalities.multiLanguage.title}
                            onSwitch={(value) =>
                                this.handleFunctionalitySwitch(
                                    'multiLanguageEnabled',
                                    value
                                )
                            }
                        />

                        <Dialog
                            maxWidth="sm"
                            fullWidth={true}
                            open={showWhitelabelEditDialog}
                            onClose={() =>
                                this.handleHideEditDialog(
                                    'showWhitelabelEditDialog'
                                )
                            }
                            TransitionComponent={Transition}
                        >
                            <DialogTitle>{t('Change whitelabel')}</DialogTitle>
                            <WhiteLabelEditCard
                                abonnee={abonnee}
                                functionalityWhitelabel={
                                    functionalityWhitelabel
                                }
                                onCancel={() =>
                                    this.handleHideEditDialog(
                                        'showWhitelabelEditDialog'
                                    )
                                }
                                onSave={() =>
                                    this.handleHideEditDialog(
                                        'showWhitelabelEditDialog'
                                    )
                                }
                            />
                        </Dialog>
                    </div>
                </AppModule>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    
    withTranslation()
)(SubscriptionFunctionalities);
