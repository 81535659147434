// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';


import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Employee from '../Employee';
import Usergroup from '../../Usergroup';
import { TEmployee } from '../../../reducers/employeesReducer';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import classes from './EmployeesOverview.module.scss';
import colors from "../../../lib/themes/theme.scss";


type Props = {
    toolboxAssigns: any,
    fetchSectors: Function,
    fetchCategories: Function,
    match: any,
    getCurrentEmployee: () => TEmployee,
};

type State = {
    tab: number,
    currentEmpl: TEmployee,
};

const mapStateToProps = (store, props) => {
    return {
        currentEmpl: store.drafts.employees.currentUser,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};


class EmployeesOverview extends React.Component<Props, State> {
    props: Props;
    state: State = {
        tab: 0,
    };

    handleTabChange = (event: any, value: number) => {
        this.setState({ tab: value });
    };

    componentDidMount() {
        if (this.props.currentEmpl) {
            let tabs = ['#overzicht', '#externen', '#groepen'];
            if (
                this.props.currentEmpl.subscription.credits_setup === true &&
                this.props.currentEmpl.subscription.vv_functions === false
            ) {
                tabs = ['#externen', '#groepen'];
            } else if (
                this.props.currentEmpl.subscription.credits_setup === false &&
                this.props.currentEmpl.subscription.vv_functions === true
            ) {
                tabs = ['#overzicht', '#groepen'];
            }
            if (this.props.location.hash) {
                const hash = this.props.location.hash.split("?")[0];
                let index = tabs.indexOf(hash);
                index = index === -1 ? 0 : index;

                this.setState({
                    tab: index,
                });
            }
        } else {
            const tabs = ['#overzicht', '#externen', '#groepen'];

            if (this.props.location.hash) {
                const hash = this.props.location.hash.split("?")[0];

                let index = tabs.indexOf(hash);
                index = index === -1 ? 0 : index;

                this.setState({
                    tab: index,
                });
            }
        }
    }

    componentWillReceiveProps(newProps: Props) {
        // if (this.props.currentEmpl !== newProps.currentEmpl) {
        //     let tabs = ['#overzicht', '#externen', '#groepen'];
        //     if (
        //         newProps.currentEmpl.subscription.credits_setup === true &&
        //         newProps.currentEmpl.subscription.vv_functions === false
        //     ) {
        //         tabs = ['#externen', '#groepen'];
        //     } else if (
        //         newProps.currentEmpl.subscription.credits_setup === false &&
        //         newProps.currentEmpl.subscription.vv_functions === true
        //     ) {
        //         tabs = ['#overzicht', '#groepen'];
        //     }
        //     if (this.props.location.hash) {
        //         const hash = this.props.location.hash;
        //         let index = tabs.indexOf(hash);
        //         index = index === -1 ? 0 : index;
        //
        //         this.setState({
        //             tab: index,
        //         });
        //     }
        // }
    }

    render() {
        const { currentEmpl, t } = this.props;
        const { tab } = this.state;

        let vv_functions, credits_setup;
        if (currentEmpl) {
            vv_functions = currentEmpl.subscription.vv_functions;
            credits_setup = currentEmpl.subscription.credits_setup;
        }

        return (
            <div>
                <Tabs
                    value={tab}
                    onChange={this.handleTabChange}
                    className={classes.root}
                    indicatorColor={colors.primaryColor}
                    textColor="secondary"
                    sx={{ '& .Mui-selected': { color: colors.mainTabsSelectedColor } }}
                >
                    {/* ONLY ABO, NO SNELDELEN */}
                    {vv_functions && !credits_setup && (
                        <Tab label={t('Employees')} href="#overzicht" sx={{ color: colors.mainTabsColor }}/>
                    )}

                    {/* ABO AND SNELDELEN */}
                    {vv_functions && credits_setup && (
                        <Tab label={t('Employees')} href="#overzicht" sx={{ color: colors.mainTabsColor }}/>
                    )}
                    {vv_functions && credits_setup && (
                        <Tab label={t('externals')} href="#externen" sx={{ color: colors.mainTabsColor }}/>
                    )}

                    {/* ONLY SNELDELEN */}
                    {!vv_functions && credits_setup && (
                        <Tab label={t('Employees')} href="#externen" sx={{ color: colors.mainTabsColor }}/>
                    )}

                    {/* ALWAYS SHOW GROUPS */}
                    <Tab label={t('Groups')} href="#groepen" sx={{ color: colors.mainTabsColor }}/>
                </Tabs>

                {vv_functions && !credits_setup && tab === 0 && (
                    <Employee
                        params={this.props.match.params}
                        display={'employee'}
                    />
                )}
                {vv_functions && !credits_setup && tab === 1 && <Usergroup />}

                {!vv_functions && credits_setup && tab === 0 && (
                    <Employee
                        params={this.props.match.params}
                        display={'extern'}
                        vv_functions={vv_functions}
                    />
                )}
                {!vv_functions && credits_setup && tab === 1 && <Usergroup />}

                {vv_functions && credits_setup && tab === 0 && (
                    <Employee
                        params={this.props.match.params}
                        display={'employee'}
                    />
                )}
                {vv_functions && credits_setup && tab === 1 && (
                    <Employee
                        params={this.props.match.params}
                        display={'extern'}
                        vv_functions={vv_functions}
                    />
                )}
                {vv_functions && credits_setup && tab === 2 && <Usergroup />}
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(EmployeesOverview);
