// @flow
import React from 'react';
import { List, Map } from 'immutable';
import PaginatedSortFilterView from '../../Util/PaginatedSortFilterView';
import PropTypes from 'prop-types';
import StatisticsRowInspectionAssign from '../../StatisticsView/StatisticsRow/StatisticsRowInspectionAssign';
import { connect } from 'react-redux';
import type { TStateStatisticsListData } from '../../../reducers/StatisticsReducer';
import { fetchInspectionAssignsPerYear } from '../../../actions/inspectionActions';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

type Props = {
    statisticsData: List<TStateStatisticsListData>,
    onItemClick: (number) => mixed,
};

const mapStateToProps = (state) => {
    return {
        projects: state.entities.projects,
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchInspectionAssignsPerYear: (year) => {
            dispatch(fetchInspectionAssignsPerYear(year));
        },
    };
};

class StatisticsListInspectionAssign extends React.PureComponent<Props, void> {
    static contextTypes = {
        router: PropTypes.object,
    };

    rowProvider = (inspectionAssign) => {
        if (!inspectionAssign || !inspectionAssign.inspection) {
            return undefined;
        }

        return (
            <StatisticsRowInspectionAssign
                key={inspectionAssign.id}
                handleRowClick={() => this.onRowClick(inspectionAssign)}
                inspectionAssign={inspectionAssign}
            />
        );
    };

    componentWillReceiveProps(newProps: Props) {
        if (this.props.year !== newProps.year) {
            this.props.fetchInspectionAssignsPerYear(newProps.year);
        }
    }

    headerProvider = () => {
        const { t } = this.props;
        return {
            deployed: t('Date sent'),
            version: t('Version'),
            project: t('Project/work area'),
            activity: t('Work type'),
            opdrachtgever: t('Performer'),
            finished: t('Completed'),
            allOk: t('Everything alright'),
            opgelost: t('Done'),
        };
    };

    onRowClick = (inspectionAssign) => {
        this.props.onItemClick(inspectionAssign);
    };

    render() {
        let sorters: Map<
            string,
            (TStateStatisticsListData, TStateStatisticsListData) => number
        > = Map();
        sorters = sorters.set(
            'allOk',
            (inspectionAssignA, inspectionAssignB) => {
                if (
                    inspectionAssignA.result === null &&
                    inspectionAssignB.result === null
                ) {
                    return 0;
                }
                if (inspectionAssignA.result === null) {
                    return -1;
                }
                if (inspectionAssignB.result === null) {
                    return 1;
                }
                if (
                    inspectionAssignA.result.allCorrect === true &&
                    inspectionAssignB.result.allCorrect === false
                ) {
                    return 1;
                }
                if (
                    inspectionAssignA.result.allCorrect === false &&
                    inspectionAssignB.result.allCorrect === true
                ) {
                    return -1;
                }
                if (
                    inspectionAssignA.result.allCorrect === true &&
                    inspectionAssignB.result.allCorrect === true
                ) {
                    return 0;
                }
                if (
                    inspectionAssignA.result.allCorrect === false &&
                    inspectionAssignB.result.allCorrect === false
                ) {
                    return 0;
                }
                return 0;
            }
        );
        sorters = sorters.set(
            'opdrachtgever',
            (inspectionAssignA, inspectionAssignB) => {
                if (
                    inspectionAssignA.inspection.inspection.customer === null &&
                    inspectionAssignB.inspection.inspection.customer === null
                ) {
                    return 0;
                }
                if (inspectionAssignA.inspection.inspection.customer === null) {
                    return -1;
                }
                if (inspectionAssignB.inspection.inspection.customer === null) {
                    return 1;
                }
                if (
                    inspectionAssignA.inspection.inspection.customer >
                    inspectionAssignB.inspection.inspection.customer
                ) {
                    return 1;
                }
                if (
                    inspectionAssignA.inspection.inspection.customer <
                    inspectionAssignB.inspection.inspection.customer
                ) {
                    return -1;
                }
                if (
                    inspectionAssignA.inspection.inspection.customer ===
                    inspectionAssignB.inspection.inspection.customer
                ) {
                    return 0;
                }
                return 0;
            }
        );
        sorters = sorters.set(
            'finished',
            (inspectionAssignA, inspectionAssignB) => {
                if (
                    inspectionAssignA.result === null &&
                    inspectionAssignB.result === null
                ) {
                    return 0;
                }
                if (inspectionAssignA.result === null) {
                    return -1;
                }
                if (inspectionAssignB.result === null) {
                    return 1;
                }
                if (
                    inspectionAssignA.result.date === null &&
                    inspectionAssignB.result.date === null
                ) {
                    return 0;
                }
                if (inspectionAssignA.result.date === null) {
                    return -1;
                }
                if (inspectionAssignB.result.date === null) {
                    return 1;
                }
                if (
                    inspectionAssignA.result.date >
                    inspectionAssignB.result.date
                ) {
                    return 1;
                }
                if (
                    inspectionAssignA.result.date <
                    inspectionAssignB.result.date
                ) {
                    return -1;
                }
                if (
                    inspectionAssignA.result.date ===
                    inspectionAssignB.result.date
                ) {
                    return 0;
                }
                return 0;
            }
        );
        sorters = sorters.set(
            'opgelost',
            (inspectionAssignA, inspectionAssignB) => {
                if (
                    inspectionAssignA.kamResolved === null &&
                    inspectionAssignB.kamResolved === null
                ) {
                    return 0;
                }
                if (inspectionAssignA.kamResolved === null) {
                    return -1;
                }
                if (inspectionAssignB.result === null) {
                    return 1;
                }
                if (
                    inspectionAssignA.result.allCorrect === true &&
                    inspectionAssignB.result.allCorrect === false
                ) {
                    return 1;
                }
                if (
                    inspectionAssignA.result.allCorrect === false &&
                    inspectionAssignB.result.allCorrect === true
                ) {
                    return -1;
                }
                if (
                    inspectionAssignA.result.allCorrect === true &&
                    inspectionAssignB.result.allCorrect === true
                ) {
                    return 0;
                }
                if (
                    inspectionAssignA.result.allCorrect === false &&
                    inspectionAssignB.result.allCorrect === false
                ) {
                    return 0;
                }
                return 0;
            }
        );


        let filteredinspectionAssigns = [];
        this.props.inspectionAssigns &&
            this.props.inspectionAssigns.forEach((assign) => {
                if (assign.projectId) {
                    let thisProject = this.props.projects.byId[assign.projectId];
                    assign.project = thisProject;

                }
                let add = true;
                if (this.props.situatie && this.props.situatie !== 0) {
                    let found = false;
                    if (
                        this.props.situatie === 1 &&
                        assign.result &&
                        !assign.result.unsafe
                    ) {
                        found = true;
                    } else if (
                        this.props.situatie === 2 &&
                        assign.result &&
                        assign.result.unsafe
                    ) {
                        found = true;
                    }
                    if (!found) {
                        add = false;
                    }
                }

                if (add) {
                    filteredinspectionAssigns.push(assign);
                }
            });

        return (
            <PaginatedSortFilterView
                data={List(
                    filteredinspectionAssigns
                        ? filteredinspectionAssigns
                              .filter((inspection) =>
                                  this.props.searchtext
                                      ? inspection.user.lastname
                                            .replace('  ', ' ')
                                            .toLowerCase()
                                            .indexOf(
                                                this.props.searchtext.toLowerCase()
                                            ) > -1 ||
                                        (inspection.project != null &&
                                            inspection.project.externalId !=
                                                null &&
                                            inspection.project.externalId
                                                .toLowerCase()
                                                .indexOf(
                                                    this.props.searchtext.toLowerCase()
                                                ) > -1) ||
                                        (inspection.project != null &&
                                            inspection.project.omschrijving !=
                                                null &&
                                            inspection.project.omschrijving
                                                .toLowerCase()
                                                .indexOf(
                                                    this.props.searchtext.toLowerCase()
                                                ) > -1) ||
                                        (inspection.activity != null &&
                                            inspection.activity
                                                .toLowerCase()
                                                .indexOf(
                                                    this.props.searchtext.toLowerCase()
                                                ) > -1)
                                      : true
                              )
                              .filter((inspection) =>
                                  this.props.project &&
                                  this.props.project !== 'all' &&
                                  inspection.projectId
                                      ? true
                                      : true
                              )
                              .filter((inspection) =>
                                  this.props.uitvoerder &&
                                  this.props.uitvoerder !== 0 &&
                                  inspection.user &&
                                  inspection.user.id
                                      ? inspection.user.id ===
                                        this.props.uitvoerder
                                      : true
                              )
                              .filter((inspection) =>
                                  this.props.afgerond &&
                                  this.props.afgerond !== 0
                                      ? this.props.afgerond === 1
                                          ? inspection.result &&
                                            inspection.result.complete
                                          : !inspection.result ||
                                            !inspection.result.complete
                                      : true
                              )
                              .filter((inspection) =>
                                  this.props.resultaat &&
                                  this.props.resultaat !== 0
                                      ? this.props.resultaat === 1
                                          ? inspection.result &&
                                            inspection.result.allCorrect
                                          : inspection.result &&
                                            !inspection.result.allCorrect
                                      : true
                              )
                        : []
                )}
                headerProvider={this.headerProvider}
                rowProvider={this.rowProvider}
                initialSort="deployed"
                asc={false}
                onRowClick={this.onRowClick}
                sorters={sorters}
            />
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(StatisticsListInspectionAssign);
