/**
 * Created by MarkBuikema on 6/28/2017.
 */
// @flow

import { connect } from 'react-redux';
import React from 'react';
import type { StoreState } from '../../../reducers/index';
import StatisticsEmployeeCard from '../StatisticsCard/StatisticsEmployeeCard';
import type { Dispatch as ReduxDispatch } from 'redux';
import { bindActionCreators } from 'redux';
import { Map } from 'immutable';
import { push } from 'connected-react-router';
import {
    clearAllFilters,
    filterPeriodMonth,
    filterPeriodType,
    filterPeriodWeek,
    filterPeriodYear,
    setViewkey,
    setCrumbPath,
    fetchEmployees,
    fetchInspectionAssigns,
    filterPeriodFrom,
    filterPeriodTill,
    filterGroup,
} from '../../../actions';
import type { TStateStatisticsChartData } from '../../../reducers/StatisticsReducer';
import { loadEmployeeData } from '../../../actions/statisticsActions';
import PeriodFilterBlock from '../../FilterBlocks/PeriodFilterBlock';
import { getCurrentFilterSet } from '../../../reducers/filterReducer';
import moment from 'moment';
import AppModule from '../../AppModule';
import TableActionHeader from '../../TableActionHeader/TableActionHeader';
import StatisticsListEmployees from '../StatisticsList/StatisticsListEmployees';
import {
    getFilteredInspectionAssigns,
    getFilteredToolboxAssigns,
} from '../../../selectors';
import _ from 'lodash';
import { fetchCSV, fetchPDF } from '../../../lib/api';
import DescIcon from '@mui/icons-material/Description';
import { Fab, Menu, MenuItem} from '@mui/material';
import { fetchToolboxAssigns } from '../../../actions/toolboxActions';
import DownloadIcon from '@mui/icons-material/GetApp';
import CloseIcon from '@mui/icons-material/Close';
import InsertIcon from '@mui/icons-material/InsertDriveFile';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import FilterHeader from "../../FilterHeader";

type Dispatch = ReduxDispatch<StoreState, { type: $Subtype<string> }>;

const viewkey: string = 'STATISTICS_VIEW';

type Props = {
    setViewkey: () => mixed,
    employeeChartData: TStateStatisticsChartData,
    loadChartData: Function,
    params: any,
    periodType: string,
    periodYear: number,
    periodMonth: number,
    periodWeek: number,
    periodFrom: any,
    periodTill: any,
    period?: string,
    progtypes: Map<string, boolean>,
    minDuration: number,
    maxDuration: number,
    distributorFilters: Map<number, boolean>,
    deviceFilters: Map<string, boolean>,
    loadDistributors: () => mixed,
    loadDevices: () => mixed,
    loadProgtypes: () => mixed,
    goTo: (path) => mixed,
    filterPeriodType: Function,
    filterPeriodYear: Function,
    filterPeriodMonth: Function,
    filterPeriodWeek: Function,
    inspectionAssigns: Array<any>,
    clearAllFilters: Function,
    employees: Array<any>,
    toolboxAssigns: any,
    inspectionAssigns: any,
    usergroups: Array<any>,
    vv_functions: boolean,
    filterGroup: Function,
    groupFilter: number,
};

const mapStateToProps = (state: StoreState) => {
    let filters = getCurrentFilterSet(state.filters);

    return {
        employeeChartData: state.statistics.employeeChartData,
        periodType: filters.periodType,
        periodYear: filters.periodYear,
        periodMonth: filters.periodMonth,
        periodWeek: filters.periodWeek,
        periodFrom: filters.periodFrom,
        periodTill: filters.periodTill,
        minDuration: filters.minduration,
        maxDuration: filters.maxduration,
        groupFilter: filters.group,
        toolboxAssigns: getFilteredToolboxAssigns(state),
        inspectionAssigns: getFilteredInspectionAssigns(state),
        employees: state.entities.employees.allIds.map(
            (id) => state.entities.employees.byId[id]
        ),
        usergroups: state.entities.usergroups.allIds.map(
            (id) => state.entities.usergroups.byId[id]
        ),
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        loadChartData: bindActionCreators(loadEmployeeData, dispatch),
        filterPeriodType: bindActionCreators(filterPeriodType, dispatch),
        filterPeriodYear: bindActionCreators(filterPeriodYear, dispatch),
        filterPeriodMonth: bindActionCreators(filterPeriodMonth, dispatch),
        filterPeriodWeek: bindActionCreators(filterPeriodWeek, dispatch),
        filterPeriodFrom: bindActionCreators(filterPeriodFrom, dispatch),
        filterPeriodTill: bindActionCreators(filterPeriodTill, dispatch),
        filterGroup: bindActionCreators(filterGroup, dispatch),
        clearAllFilters: bindActionCreators(clearAllFilters, dispatch),
        setViewkey: () => {
            dispatch(setViewkey(viewkey));
        },
        goTo: (path) => {
            dispatch(push(path));
        },
        setCrumbPath: () => {
            dispatch(
                setCrumbPath({
                    title:
                        'Rapportages' /*, crumbs: [{ name: 'Werkplekinspecties', link: '/rapportages#werkplekinspecties' }]*/,
                })
            );
        },
        fetchEmployees: () => {
            dispatch(fetchEmployees());
        },
        fetchToolboxAssigns: () => {
            dispatch(fetchToolboxAssigns());
        },
        fetchInspectionAssigns: () => {
            dispatch(fetchInspectionAssigns());
        },
    };
};

class StatisticsEmployeeOverview extends React.Component<Props, void> {
    props: Props;
    state: {
        openGroups: false,
        anchorEl: 0,
    };

    componentDidMount() {
        this.props.fetchEmployees();
        this.props.fetchToolboxAssigns();
        this.props.fetchInspectionAssigns();

        //set view key
        this.props.setViewkey();
        this.props.setCrumbPath();

        let query = this.props.match.params;
        if (query.year) {
            this.props.clearAllFilters();

            if (query.month) {
                this.props.filterPeriodType('month');
                this.props.filterPeriodYear(parseInt(query.year, 10));
                this.props.filterPeriodMonth(parseInt(query.month - 1, 10));
            } else if (query.week) {
                this.props.filterPeriodType('week');
                this.props.filterPeriodYear(parseInt(query.year, 10));
                this.props.filterPeriodWeek(parseInt(query.week, 10));
            } else {
                this.props.filterPeriodType('year');
                this.props.filterPeriodYear(parseInt(query.year, 10));
            }
        }

        this.onFilterChanged();
    }

    componentWillReceiveProps(newProps: Props) {
        if (
            this.props.periodType !== newProps.periodType ||
            this.props.periodYear !== newProps.periodYear ||
            this.props.periodMonth !== newProps.periodMonth ||
            this.props.periodWeek !== newProps.periodWeek ||
            this.props.periodFrom !== newProps.periodFrom ||
            this.props.periodTill !== newProps.periodTill ||
            this.props.minDuration !== newProps.minDuration ||
            this.props.maxDuration !== newProps.maxDuration ||
this.props.groupFilter !== newProps.groupFilter ||            !_.isEqual(
                this.props.inspectionAssigns.length,
                newProps.inspectionAssigns.length
            ) ||
            !_.isEqual(
                this.props.toolboxAssigns.length,
                newProps.toolboxAssigns.length
            )
        ) {
            this.onFilterChanged(newProps);
        }
    }

    onFilterChanged = (props?: Props) => {
        if (!props) {
            props = this.props;
        }

        let path: string =
            this.props.location.pathname + '?year=' + props.periodYear;

        let period = moment().year(props.periodYear);
        let periodTill = '';
        switch (props.periodType) {
            case 'month':
                period = period.month(props.periodMonth);
                path += '&month=' + (props.periodMonth + 1);
                break;
            case 'week':
                let locale: string =
                    window.navigator.userLanguage || window.navigator.language;
                period = period
                    .week(props.periodWeek)
                    .locale(locale)
                    .weekday(0);
                path += '&week=' + props.periodWeek;
                break;
            case 'fromtill':
                //TODO: Format this
                //path = this.props.location.pathname + '?from=' + props.periodFrom + '&till=' + props.periodTill;
                if (props.periodFrom && props.periodTill) {
                    period = moment(props.periodFrom);
                    periodTill = moment(props.periodTill);
                }
                break;
            default:
                break;
        }

        if (props.periodType !== 'fromtill') {
            let periodString = period.format('YYYY-MM-DD') + 'T00:00:00.000Z';

            this.props.goTo(path + this.props.location.hash);

            // execute chart api call
            props.loadChartData(props.periodType, periodString, null, null, this.props.groupFilter);
        } else if (period && periodTill) {
            let periodString = period.format('YYYY-MM-DD') + 'T00:00:00.000Z';
            let periodEnd = periodTill.format('YYYY-MM-DD') + 'T00:00:00.000Z';

            this.props.goTo(path + this.props.location.hash);

            // execute chart api call
            props.loadChartData(
                props.periodType,
                periodString,
                periodEnd,
                null
            , null, this.props.groupFilter);
        }
    };

    onItemClicked = (clickedInspectionAssign) => {
        let target = 'rapportages/medewerker/' + clickedInspectionAssign.id;

        let url = window.location.href;
        let parts = url.split('?');
        if (parts.length > 1) {
            target += '?' + parts[1];
        }
        this.props.goTo(target);
    };

    toggleExport = () => {
        if (this.state && this.state.exportOpen) {
            this.setState({
                exportOpen: false,
            });
        } else {
            this.setState({
                exportOpen: true,
            });
        }
    };

    downloadCsv = () => {
        let usergroupUsers = this.props.groupFilter !== 0 ? this.props.usergroups.filter(usergroup => usergroup.id === this.props.groupFilter)[0].users : []
        let employees = this.props.employees.filter(employee => (this.state && this.state.searchtext) ? employee.fullname.replace('  ', ' ').toLowerCase().indexOf(this.state.searchtext.toLowerCase()) > -1 ? true : this.props.usergroups.filter(row => row.name.toLowerCase().indexOf(this.state.searchtext.toLowerCase()) > -1 && row.users.filter(user => user.id === employee.id).length > 0).length > 0 && true : true).filter(user => this.props.groupFilter === 0 || usergroupUsers.filter(usergroup => usergroup.id === user.id).length > 0)

        let data = [];

        let toolboxData = [];
        let inspectionData = [];

        this.props.toolboxAssigns.forEach((item) => {
            toolboxData.push({
                fullnameUser: item.fullnameUser,
                toolboxMeeting: item.toolboxMeeting,
                result: item.result
                    ? {
                          complete: true,
                      }
                    : null,
            });
        });

        this.props.inspectionAssigns.forEach((item) => {
            inspectionData.push({
                user: {
                    fullname: item.user.fullname,
                },
                result: item.result
                    ? {
                          complete: true,
                      }
                    : null,
            });
        });

        employees.forEach((item) => {
            let groups = '';
            let name = '';
            let id = '';
            let toolboxAmount = 0;
            let toolboxCompleted = 0;
            let toolboxMeetingAmount = 0;
            let inspectionAmount = 0;
            let inspectionCompleted = 0;

            if (item.fullname.includes('(Externe)')) {
                name = item.fullname.substring(0, item.fullname.length - 10);
                id = item.id;
            } else {
                name = item.fullname;
                id = item.id;
            }

            this.props.usergroups.forEach((row) => {
                row.users.forEach((user) => {
                    if (user.fullname.toLowerCase() === name.toLowerCase()) {
                        if (groups.length === 0) {
                            groups += row.name;
                        } else {
                            groups += ', ';
                            groups += row.name;
                        }
                    }
                });
            });

            this.props.toolboxAssigns.forEach((row) => {
                if (row.user.id === id) {
                    toolboxAmount++;
                    if (row.result !== null) {
                        toolboxCompleted++;
                    }
                    if (row.toolboxMeeting === true) {
                        toolboxMeetingAmount++;
                    }
                }
            });

            this.props.inspectionAssigns.forEach((row) => {
                if (row.user.id === id) {
                    inspectionAmount++;
                    if (row.result !== null) {
                        inspectionCompleted++;
                    }
                }
            });

            if (toolboxAmount + inspectionAmount > 0) {
                data.push({
                    fullname: item.fullname,
                    groups: groups,
                    toolboxes: toolboxAmount,
                    toolboxmeetings: toolboxMeetingAmount,
                    inspections: inspectionAmount,
                    finished: toolboxCompleted + inspectionCompleted,
                    total: toolboxAmount + inspectionAmount,
                    finishedToolbox: toolboxCompleted,
                    finishedInspection: inspectionCompleted,
                });
            }
        });

        fetchCSV({
            platform: 'VV',
            customer: 'VV',
            template: 'EMPLOYEES_RAPPORTAGE',
            options: {},
            data: data,
            info: {},
        })
            .then((res) => {
                const url = URL.createObjectURL(res.body);
                const link = document.createElement('a');
                link.setAttribute('href', url);
                link.setAttribute(
                    'download',
                    i18n.t('medewerkers_rapportage.csv')
                );
                link.click();
            })
            .catch((err) => console.log(err));
    };

    getList = (periodType, periodWeek, periodMonth, periodYear) => {
        return (
            <StatisticsListEmployees
                onItemClick={this.onItemClicked}
                periodType={periodType}
                periodWeek={periodWeek}
                periodMonth={periodMonth}
                periodYear={periodYear}
                vv_functions={this.props.vv_functions}
                group={this.props.groupFilter}
                searchtext={this.state ? this.state.searchtext : ''}
            />
        );
    };

    handleSearch = (searchtext) => {
        this.setState({
            searchtext: searchtext,
        });
    };

    handleGroupsFilterChange = (event) => {
        this.handleRequestClose();

        this.setState({ groupFilter: event.target.value }, this.onFilterChanged);
        this.props.filterGroup(event.target.value);
    }

    handleGroupsFilterOpen = (event: Event) => {
        // This prevents ghost click.
        event.preventDefault();

        const newState = Object.assign({}, this.state, {
            openGroups: true,
            anchorEl: event.currentTarget
        });

        this.setState(newState);
    };

    handleRequestClose = () => {
        const newState = Object.assign({}, this.state, { openGroups: false });
        this.setState(newState);
    };

    handleToolboxSectorFilterChange = (event) => {
        this.handleRequestClose();
        this.setState({ groupsFilter: event.target.value }, this.onFilterChanged);
        this.props.filterSector(event.target.value);

    }

    getFilterBar = () => {
        let groupName = "Alles";
        this.props.usergroups.forEach((item) => {
            if(this.props.groupFilter === item.id){
                groupName = item.name;
            }
            return null
        })

        return (
            <div className="filterbar">
                <PeriodFilterBlock />

                <div style={{ marginLeft: '20px' }}>
                    <span onClick={this.handleGroupsFilterOpen}>
                        <FilterHeader filterTitle={"Groep"} filterValue={groupName != null ? groupName : "Alles"} />
                    </span>
                </div>

                <Menu
                    open={this.state ? this.state.openGroups : false}
                    onClose={this.handleRequestClose}
                    value={null}
                    anchorEl={this.state ? this.state.anchorEl : 0}
                >
                    <MenuItem onClick={this.handleGroupsFilterChange} value={0} key={0}>Alles</MenuItem>
                    {this.props.usergroups.map(item => <MenuItem onClick={this.handleGroupsFilterChange} value={item.id} key={item.id}>{item.name}</MenuItem>)}
                </Menu>
            </div>
        );
    };

    getPDF = () => {
        let usergroupUsers = this.props.groupFilter !== 0 ? this.props.usergroups.filter(usergroup => usergroup.id === this.props.groupFilter)[0].users : []
        let employees = this.props.employees.filter(employee => (this.state && this.state.searchtext) ? employee.fullname.replace('  ', ' ').toLowerCase().indexOf(this.state.searchtext.toLowerCase()) > -1 ? true : this.props.usergroups.filter(row => row.name.toLowerCase().indexOf(this.state.searchtext.toLowerCase()) > -1 && row.users.filter(user => user.id === employee.id).length > 0).length > 0 && true : true).filter(user => this.props.groupFilter === 0 || usergroupUsers.filter(usergroup => usergroup.id === user.id).length > 0)

        let data = [];

        let toolboxData = [];
        let inspectionData = [];

        this.props.toolboxAssigns.forEach((item) => {
            toolboxData.push({
                fullnameUser: item.fullnameUser,
                userId: item.user.id,
                toolboxMeeting: item.toolboxMeeting,
                result: item.result
                    ? {
                          complete: true,
                      }
                    : null,
            });
        });

        this.props.inspectionAssigns.forEach((item) => {
            inspectionData.push({
                user: {
                    fullname: item.user.fullname,
                    id: item.user.id,
                },
                result: item.result
                    ? {
                          complete: true,
                      }
                    : null,
            });
        });

        employees.forEach((item) => {
            if (item.fullname.includes('(Externe)')) {
                data.push({
                    name: item.fullname,
                    id: item.id,
                    fullname: item.fullname.substring(
                        0,
                        item.fullname.length - 10
                    ),
                });
            } else {
                data.push({
                    name: item.fullname,
                    id: item.id,
                    fullname: item.fullname,
                });
            }
        });

        fetchPDF({
            platform: 'VV',
            customer: 'VV',
            template: 'EMPLOYEES_RAPPORTAGE',
            options: {},
            data: data,
            info: {
                chartData: this.props.employeeChartData.inspectionBar,
                toolboxAssigns: toolboxData,
                inspectionAssigns: inspectionData,
                usergroups: this.props.usergroups,
                periodType: this.props.periodType,
                periodYear: this.props.periodYear,
                periodMonth: this.props.periodMonth,
                periodWeek: this.props.periodWeek,
                periodFrom: moment(this.props.periodFrom).format('LL'),
                periodTill: moment(this.props.periodTill).format('LL'),
            },
        })
            .then((res) => {
                var encodedUri = window.URL.createObjectURL(res.body);
                var link = document.createElement('a');
                link.setAttribute('href', encodedUri);
                link.setAttribute(
                    'download',
                    i18n.t('medewerkersrapportage.pdf')
                );
                document.body.appendChild(link);
                link.click(); //
            })
            .catch((err) => console.log(err));
    };

    render() {
        const { employeeChartData, t } = this.props;
        if (this.props.employeeChartData.toolboxBar) {
            this.props.employeeChartData.toolboxBar.forEach(
                (month, i) =>
                    (this.props.employeeChartData.inspectionBar[i].toolbox =
                        month.total)
            );
        }

        const tableActionHeader = (
            <TableActionHeader
                title={this.getFilterBar()}
                onSearchChange={this.handleSearch}
                searchPlaceholder={t('Search in employees')}
            />
        );
        return (
            <div className="Statistics">
                <AppModule
                    loading={employeeChartData.inspectionBar ? false : true}
                    prepend={tableActionHeader}
                    hasTabs
                >
                    <StatisticsEmployeeCard
                        inspectionBar={
                            this.props.employeeChartData.inspectionBar
                                ? this.props.employeeChartData.inspectionBar
                                : []
                        }
                        onDownloadCSV={this.downloadCsv}
                        list={this.getList(
                            this.props.periodType,
                            this.props.periodWeek,
                            this.props.periodMonth,
                            this.props.periodYear
                        )}
                        totalToolboxes={
                            this.props.employeeChartData.totalToolboxes
                        }
                        totalInspections={
                            this.props.employeeChartData.totalInspections
                        }
                        vv_functions={this.props.vv_functions}
                    />
                </AppModule>
                <div
                    style={{
                        position: 'fixed',
                        top: '84px',
                        right: '36px',
                        zIndex: 1500,
                    }}
                >
                    <div>
                        <Fab   onClick={this.toggleExport}>
                            {this.state && this.state.exportOpen ? (
                                <CloseIcon />
                            ) : (
                                <DownloadIcon />
                            )}
                        </Fab>
                    </div>
                    <div
                        style={
                            this.state && this.state.exportOpen
                                ? {
                                      marginTop: '20px',
                                      marginLeft: '8px',
                                      visibility: 'visible',
                                      transition: 'margin-top 300ms',
                                  }
                                : {
                                      marginTop: '0px',
                                      marginLeft: '8px',
                                      visibility: 'hidden',
                                      transition: 'margin-top 300ms',
                                  }
                        }
                        className={'tooltip'}
                    >
                        <span
                            className={
                                this.state && this.state.exportOpen
                                    ? 'tooltiptextvisible'
                                    : 'tooltiptext'
                            }
                            style={{ top: '87px', right: '60px' }}
                        >
                            {t('Download PDF file')}
                        </span>
                        <Fab color="primary"  onClick={this.getPDF}>
                            <InsertIcon />
                        </Fab>
                    </div>
                    <div
                        style={
                            this.state && this.state.exportOpen
                                ? {
                                      marginTop: '15px',
                                      marginLeft: '8px',
                                      visibility: 'visible',
                                      transition: 'margin-top 300ms',
                                  }
                                : {
                                      marginTop: '0px',
                                      marginLeft: '8px',
                                      visibility: 'hidden',
                                      transition: 'margin-top 300ms',
                                  }
                        }
                        className={'tooltip'}
                    >
                        <span
                            className={
                                this.state && this.state.exportOpen
                                    ? 'tooltiptextvisible'
                                    : 'tooltiptext'
                            }
                            style={{ top: '145px', right: '60px' }}
                        >
                            {t('Download CSV file')}
                        </span>
                        <Fab
                            color="primary"
                            onClick={this.downloadCsv}
                        >
                            <DescIcon />
                        </Fab>
                    </div>
                </div>
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(StatisticsEmployeeOverview);
