// @flow
import React from "react"; // eslint-disable-line no-unused-vars
import { connect } from "react-redux";

import DrawerComponent from './Drawer';
import {
	setCrumbPath,
    fetchSpeakups,
    fetchSpeakupCompanies,
    fetchSpeakupContacts,
    deleteSpeakup,
    speekupSeen,
	setViewkey,
	filterPeriodType,
	filterPeriodYear,
	filterPeriodMonth,
	filterPeriodWeek,
	filterPeriodFrom,
	filterPeriodTill,
} from "../../actions";
import AppModule from "../AppModule";
import EnhancedTable from "../EnhancedTable";
import moment from "../../lib/moment";
import "moment/min/locales";
import { inProgress } from "../../selectors";
import TableActionHeader from "../TableActionHeader/TableActionHeader";
import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Menu,
	MenuItem,
} from '@mui/material';
import Viewer from "react-viewer";
import ConfirmDialog from "../ConfirmDialog";
import PeriodFilterBlock from "../FilterBlocks/PeriodFilterBlock";
import FilterHeader from "../FilterHeader";
import $ from "jquery";
import { bindActionCreators } from "redux";
import { getCurrentFilterSet } from "../../reducers/filterReducer";
import { compose } from "recompose";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import { filter, speakupSorter } from "./lib";
import classes from "./SpeakUp.module.scss";
moment.locale(i18n.t("LOCALE"));

const viewkey: string = "SPEAKUP_VIEW";


const mapStateToProps = (store) => {
	let filters = getCurrentFilterSet(store.filters);

	return {
		loading: !inProgress(store),
		speakups: store.entities.speakups,
		speakupcompany: store.entities.speakupcompany,
		speakupContacts: store.entities.speakupcontact,
		employees: store.entities.employees,
		periodType: filters.periodType,
		periodYear: filters.periodYear,
		periodMonth: filters.periodMonth,
		periodWeek: filters.periodWeek,
		periodFrom: filters.periodFrom,
		periodTill: filters.periodTill,
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		filterPeriodType: bindActionCreators(filterPeriodType, dispatch),
		filterPeriodYear: bindActionCreators(filterPeriodYear, dispatch),
		filterPeriodMonth: bindActionCreators(filterPeriodMonth, dispatch),
		filterPeriodWeek: bindActionCreators(filterPeriodWeek, dispatch),
		filterPeriodFrom: bindActionCreators(filterPeriodFrom, dispatch),
		filterPeriodTill: bindActionCreators(filterPeriodTill, dispatch),
        fetchSpeakups: () => {
            dispatch(fetchSpeakups());
        },
        fetchSpeakupCompanies: () => {
            dispatch(fetchSpeakupCompanies());
        },
        fetchSpeakupContacts: () => {
            dispatch(fetchSpeakupContacts());
        },
        speekupSeen: (data) => {
            dispatch(speekupSeen(data));
        },
        deleteSpeakup: (data) => {
            dispatch(deleteSpeakup(data));
        },
		setCrumbPath: () => {
			dispatch(setCrumbPath({ title: "Speak-Up!" }));
		},
		setViewkey: () => {
			dispatch(setViewkey(viewkey));
		},
	};
};

let users = [];

class SpeakUp extends React.Component<Props, State> {
	props: Props;
	state: State = {
		columns: [],
		actions: [],
		searchtext: "",
		speakUpOpen: false,
		imageVisible: false,
		activePhotoId: undefined,
		editMode: false,
		showDeleteDialog: false,
		anchorEl: 0,
		openEmployees: false,
		employeesFilter: 0,
		openWarning: false,
	};

	handleAction = (event, action) => {
		const { speakups } = this.props;
		if (action.id === "delete") {
			const row = speakups.byId[action.rowId];
			this.setState({
				speakupToDelete: row,
				speakup: row,
				showDeleteDialog: true,
			});
		}
	};

	handleSearch = (searchtext) => {
		this.setState({
			searchtext: searchtext,
		});
	};

	mapSpeakupToRow = (speakup) => {
		moment.locale(i18n.t("LOCALE"));

		return {
			...speakup,
			date: !speakup.date ? (
				"-"
			) : speakup.isnew ? (
				<div>
					<div
						style={{
							width: "8px",
							height: "8px",
							backgroundColor: "#0076FF",
							borderRadius: "24px",
							float: "left",
							marginTop: "4px",
						}}
					></div>
					<div style={{ float: "left", marginLeft: "8px" }}>
						{moment(speakup.date).format("L HH:mm")}
					</div>
				</div>
			) : (
				<div>
					<div
						style={{
							width: "8px",
							height: "8px",
							float: "left",
							marginTop: "4px",
						}}
					></div>
					<div style={{ float: "left", marginLeft: "8px" }}>
						{moment(speakup.date).format("L HH:mm")}
					</div>
				</div>
			),
			from: speakup.user
				? speakup.user.fullname || "Onbekend"
				: "Onbekend",
			title: speakup.remark.text ? speakup.remark.text.substring(0,30) + '...' : "-",
			attachments: speakup.remark.photos
				? speakup.remark.photos.length
				: "0",
		};
	};

	onRowClick = (row, event) => {
		const { speakups } = this.props;

		this.setState({
			speakUpOpen: true,
			speakup: speakups.byId[row.id],
		});

		if (speakups.byId[row.id].isnew) {
			this.props.speekupSeen(speakups.byId[row.id]);
		}
	};

	handleRequestClose = () => {
		const newState = Object.assign({}, this.state, {
			openEmployees: false,
		});
		this.setState(newState);
	};

	handleTouchTap1 = (event: Event) => {
		// This prevents ghost click.
		event.preventDefault();

		const newState = Object.assign({}, this.state, {
			openEmployees: true,
			anchorEl: event.currentTarget,
		});

		this.setState(newState);
		setTimeout(() => {
			$("ul.tabs").tabs();
		}, 100);
	};

	handleEmployeesFilterChange = (event) => {
		this.handleRequestClose();
		this.setState({ employeesFilter: event.target.value });
	};

	getFilterBar = () => {
		const { t } = this.props;

		return (
			<div className="filterbar">
				<PeriodFilterBlock />

				<div>
					<span onClick={this.handleTouchTap1}>
						<FilterHeader
							filterTitle={t("Detector")}
							filterValue={
								!this.props.employees ||
								this.state.employeesFilter === 0
									? t("Everything")
									: this.props.employees.byId[
											this.state.employeesFilter
									  ].fullname
							}
						/>
					</span>
				</div>

				<Menu
					open={this.state ? this.state.openEmployees : false}
					onClose={this.handleRequestClose}
					value={this.state.employeesFilter}
					anchorEl={this.state ? this.state.anchorEl : 0}
				>
					<MenuItem
						onClick={this.handleEmployeesFilterChange}
						value={0}
					>
						{t("Everything")}
					</MenuItem>
					{this.props.employees &&
						this.props.employees.allIds.length > 0 &&
						users &&
						users.map((id) => {
							return this.props.employees.byId[id] ? (
								<MenuItem
									onClick={this.handleEmployeesFilterChange}
									value={id}
								>
									{this.props.employees.byId[id].fullname}
								</MenuItem>
							) : null;
						})}
				</Menu>
			</div>
		);
	};

	closeDrawer = () => {
		this.setState({
			speakUpOpen: false,
			editMode: false,
		});
	};

	deleteSpeakup = () => {
		this.props.deleteSpeakup(this.state.speakup);

		this.setState({
			speakUpOpen: false,
			editMode: false,
		});
	};

	hideDeleteDialog = () => {
		this.setState({
			showDeleteDialog: false,
		});
	};

	componentDidMount() {
		const { t } = this.props;
		moment.locale(i18n.t("LOCALE"));

		this.props.setViewkey();
		this.props.setCrumbPath();
		this.props.fetchSpeakups();
        this.props.fetchSpeakupCompanies();
        this.props.fetchSpeakupContacts()

		let actions = [];

		actions.push({ id: "delete", label: t("Delete") });

		this.setState({
			actions: actions,
			columns: [
				{ id: "date", label: "Date", size: "25%" },
				{ id: "title", label: "Description", size: "25%" },
				{ id: "from", label: "Reported by", size: "25%" },
				{ id: "attachments", label: "Attachments", size: "25%" },
			],
		});

	}

	handleAttachment = (photo) => {
		this.setState({
			imageVisible: true,
			activePhotoId: photo,
		});
	};

	render() {
		const {
			periodType,
			loading,
			t,
			speakups,
			periodYear,
			periodMonth,
			periodWeek,
			periodFrom,
			periodTill,
			speakupcompany,
			speakupContacts,
		} = this.props;

		const { actions, columns, speakup, employeesFilter } = this.state;

		let speakupsArray = [];

		speakups &&
			speakups.allIds &&
			speakups.allIds.forEach((id) => {
				speakupsArray.push(speakups.byId[id]);

				if (
					speakups.byId[id].user &&
					!users.includes(speakups.byId[id].user.id)
				) {
					users.push(speakups.byId[id].user.id);
				}
			});

		speakupsArray = filter({
			array: speakupsArray,
			employees: employeesFilter,
			periodType: periodType,
			periodYear: periodYear,
			periodMonth: periodMonth,
			periodWeek: periodWeek,
			periodFrom: periodFrom,
			periodTill: periodTill,
		});

		const searchresults = speakupsArray;

		const tableActionHeader = (
			<TableActionHeader title={this.getFilterBar()} />
		);
        
		return (
			<div>
				<Dialog
					open={this.state.openWarning}
					onClose={() => this.setState({ openWarning: false })}
				>
					<DialogTitle className="title">
						{t("Not all mandatory fields completed")}
					</DialogTitle>
					<DialogContent>
						{t("Fill all fields and send again")}
					</DialogContent>
					<div className="confirmButton-container">
						<Button
							 
							className="confirmButton"
							onClick={() =>
								this.setState({ openWarning: false })
							}
						>
							{t("Ok")}
						</Button>
					</div>
				</Dialog>
				<ConfirmDialog
					open={this.state.showDeleteDialog}
					handleClose={this.hideDeleteDialog}
					deleteincident={this.state.speakupToDelete}
					deleteIncidentFunction={this.deleteSpeakup}
				/>
				<DrawerComponent
					open={this.state.speakUpOpen}
					onClose={this.closeDrawer}
					editMode={this.state.editMode}
					speakup={speakup}
					speakupcompany={speakupcompany}
					classes={classes}
					onAttachmentClick={this.handleAttachment}
					speakupContacts={speakupContacts}
				/>
				<AppModule loading={loading} prepend={tableActionHeader}>
					<EnhancedTable
						hover
						defaultOrderBy={"date"}
						defaultOrder="desc"
						columns={columns}
						onClick={this.onRowClick}
						rows={searchresults}
						formatter={this.mapSpeakupToRow}
						actions={actions}
						onAction={this.handleAction}
						sorter={speakupSorter()}
						emptyState={
							this.state.searchtext.length > 0
								? t("No searchresults")
								: t("No reports yet")
						}
					/>
				</AppModule>
				{speakup && speakup.remark.photos.length > 0 && (
					<Viewer
						visible={this.state.imageVisible}
						activeIndex={speakup.remark.photos.findIndex(
							(p) => p.id === this.state.activePhotoId
						)}
						drag={false}
						scalable={false}
						zIndex={2000}
						downloadable={true}
						onClose={() => {
							this.setState({ imageVisible: false });
						}}
						onMaskClick={() => {
							this.setState({ imageVisible: false });
						}}
						images={speakup.remark.photos.map((photo) => {
							return {
								src: photo.url,
								downloadUrl: photo.url + ".jpg",
							};
						})}
					/>
				)}
			</div>
		);
	}
}

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	
	withTranslation()
)(SpeakUp);
