// @flow
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    setCrumbPath,
    inviteEmployee,
    getCurrentEmployee,
} from '../../actions';
import { inProgress } from '../../selectors';
import moment from '../../lib/moment';
import '../SubscriptionGeneral/SubscriptionGeneral.module.scss';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SubscriptionGeneral from '../SubscriptionGeneral';
import SubscriptionActivity from '../SubscriptionActivity';
import SubscriptionAbonnement from '../SubscriptionAbonnement';
import SubscriptionFunctionalities from '../SubscriptionFunctionalities/SubscriptionFunctionalities';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import classes from './AbonneeInformation.module.scss';
import colors from "../../lib/themes/theme.scss";


type Props = {
    loading: boolean,
    cumela?: boolean,
    history?: any,
};

type State = {
    tab: number,
    fetched: boolean,
};


const mapDispatchToProps = (dispatch) => {
    return {
        inviteEmployee: bindActionCreators(inviteEmployee, dispatch),
        setCrumbPath: (abonneeCompanyName: string) => {
            dispatch(
                setCrumbPath({
                    title: 'Abonnee',
                    crumbs: [{ name: abonneeCompanyName, link: '/abonnee' }],
                })
            );
        },
        getCurrentEmployee: () => {
            dispatch(getCurrentEmployee());
        },
    };
};

const mapStateToProps = (store, props) => {
    return {
        loading: !inProgress(store),
        showDetailDialog: window.location.href.endsWith('dialog'),
        currentEmpl: store.drafts.employees.currentUser,
    };
};

class AbonneeInformation extends React.Component<Props, State> {
    state: State = {
        fetched: false,
        showDetailDialog: true,
        tab: 0,
    };

    mapHistoryToRow = (h, index) => ({
        ...h,
        maxEmployees:
            h.maxEmployees !== -1 ? h.maxEmployees : i18n.t('Infinite'),
        activatedOn: moment(h.activatedOn).format('DD/MM/YYYY'),
    });

    handleTabChange = (event: any, value: number) => {
        this.setState({ tab: value });
    };

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (this.state.fetched === false) {
            this.setState({ fetched: true });
            this.props.getCurrentEmployee();
        }
    }

    componentDidMount() {
        const tabs = [
            '#general',
            '#abonnement',
            '#activity',
            '#functionalities',
        ];

        if (window.location.hash) {
            const hash = window.location.hash;

            this.setState({
                tab: tabs.indexOf(hash),
            });
        }
    }

    render() {
        const { tab } = this.state;
        const { abonnee, history, t } = this.props;

        if (!abonnee) {
            return <div />;
        }
        return (
            <div>
                <Tabs
                    value={tab}
                    onChange={this.handleTabChange}
                    className={classes.root}
                    textColor="secondary"
                    sx={{color: colors.mainTabsColor, '& .Mui-selected': { color: colors.mainTabsSelectedColor } }}
                >
                    <Tab label={t('General')} href="#general" />
                    <Tab label={t('Subscription')} href="#abonnement" />
                    {this.props.currentEmpl.roles.includes('ADMIN') && (
                        <Tab label={t('Activity')} href="#activity" />
                    )}
                    <Tab label={t('Functionalities')} href="#functionalities" />
                </Tabs>

                {tab === 0 && (
                    <SubscriptionGeneral abonnee={abonnee} cumela={false} />
                )}
                {tab === 1 && (
                    <SubscriptionAbonnement abonnee={abonnee} cumela={false} />
                )}
                {tab === 2 &&
                    this.props.currentEmpl.roles.includes('ADMIN') && (
                        <SubscriptionActivity
                            abonnee={abonnee}
                            history={history}
                            cumela={false}
                        />
                    )}
                {tab === 3 && (
                    <SubscriptionFunctionalities
                        abonnee={abonnee}
                        cumela={false}
                    />
                )}
            </div>
        );
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(AbonneeInformation);
