//import mockApi from './mock-api';
import * as storage from './storage';
import Cookies from 'js-cookie';
import {V2_TOOLBOX_ASSIGN_OVERVIEW_API, V2_TOOLBOX_OVERVIEW_API, V2_TOOLBOX_SCHEDULED_OVERVIEW_API} from "./apiTypes";

let request = require('superagent-promise')(require('superagent'), Promise);

let statisticsChartRequest, statisticsListRequest;

if (process.env.NODE_ENV === `development` && !process.env.REACT_APP_API_HOST) {
    //request = mockApi;
}

const config = {
    API_HOST: process.env.REACT_APP_API_HOST || 'https://test.veiligvakwerk.nl/',
    RAPPORTAGE_HOST: process.env.REACT_APP_RAPPORTAGE_HOST || 'https://rapportage.grip52.com',
    API_PATH: 'api/'
};

export const apiUrl =
    config.API_HOST.replace(/\/*$/, '') +
    '/' +
    config.API_PATH.replace(/^\/+/, '').replace(/\/*$/, '') +
    '/';

const entityUriOverrides = {
    token: apiUrl + 'login'
};

const entityUri = entity => {
    return entityUriOverrides[entity] || apiUrl + entity;
};

const queryString = (query) => {
    let qStr = Object.keys(query)
        .reduce((q, key) => {
            q += '&' + key + '=' + query[key];
            return q;
        }, '')
        .replace(/^&/, '');

    if (qStr.length) {
        qStr = '?' + qStr;
    }

    return qStr;
};

const parseError = (entity, data, error) => {
    //console.log({ status: error.status || 500, ...(error.response.body || {}) });
    if (!error.response) return { status: 500 };
    return { status: error.status || 500, ...(error.response.body || {}) };
};

const parseResponse = (entity, data, response) => {
    const body = response.body || {};
    if (data) {
        return body;
    } else {
        if (entity === 'category') {
            return body._embedded.categories;
        } else if (entity === 'sector') {
            return body._embedded.sectors;
        } else if (entity === 'toolbox' || entity === 'toolboxes-projection' || entity === V2_TOOLBOX_OVERVIEW_API) {
            return body._embedded.toolboxes;
        } else if (entity === 'employee' || entity === 'employeesAll') {
            return body._embedded.employees;
        } else if (entity === 'extern') {
            return body._embedded.employees;
        } else if (entity === 'project') {
            return body._embedded.projects;
        } else if (entity === 'toolbox-assign-projection' || entity === V2_TOOLBOX_ASSIGN_OVERVIEW_API || entity === V2_TOOLBOX_SCHEDULED_OVERVIEW_API) {
            return body._embedded.toolboxAssigns;
        } else if (entity === 'extern-assign') {
            return body._embedded.externAssigns;
        } else if (entity === 'inspection/trend') {
            return body._embedded.trendinspection;
        } else if (entity === 'inspection-assign-detail') {
            return body._embedded.inspectionAssign;
        } else if (entity === 'inspection-assign-projection') {
            return body._embedded.inspectionAssigns;
        } else if (entity === 'inspection') {
            return body._embedded.inspections;
        } else if (entity === 'usergroup') {
            return body._embedded.usergroups;
        } else if (entity === 'toolbox/assigned') {
            return body._embedded.toolboxes;
        } else if (entity === 'inspection/assigned') {
            return body._embedded.inspections;
        } else if (entity === 'incident') {
            return body._embedded.incidents;
        } else if (entity === 'incidents') {
            return body._embedded.incidents;
        } else if (entity === 'document') {
            return body._embedded.documents;
        } else if (entity === 'speakups') {
            return body._embedded.speakups;
        } else if (entity === 'incidenttype') {
            return body._embedded.incidenttype;
        } else if (entity === 'incidentcause') {
            return body._embedded.incidentcause;
        } else if (entity === 'speakupcontact') {
            return body._embedded.speakupcontacts;
        } else if (entity === 'speakupcompany') {
            return body._embedded.speakupcompanies;
        } else if (entity === 'toolbox-group-assigns' || entity === 'inspection-group-assigns') {
            return body._embedded.groupassigns;
        }
    }
};

const authHeader = (token = null) => {
    return 'bearer ' + (token ? token : storage.fetchLogin().token);
};

export function create(entity, data, token = null) {
    return request
        .post(entityUri(entity))
        .timeout({ response: 300000, deadline: 600000 })
        .send(data)
        .set('Accept', 'application/json')
        .set('Authorization', authHeader(token))
        .set('Accept-Language', 'null')
        .withCredentials()
        .end()
        .then(response => parseResponse(entity, data, response))
        .catch(response => {
            throw parseError(entity, data, response);
        });
}

export function createWebclient(entity, data, token = null) {
    const lang = Cookies.get('lang');
    return request
        .post(entityUri(entity))
        .timeout({ response: 300000, deadline: 600000 })
        .send(data)
        .set('Accept', 'application/json')
        .set('Authorization', authHeader(token))
        .set('Logintype', 'webclient')
        .set('Accept-Language', lang || null)
        .withCredentials()
        .end()
        .then((response) => parseResponse(entity, data, response))
        .catch((response) => {
            throw parseError(entity, data, response);
        });
}

export function sendResults(entity, data, token = null) {
    return request
        .post(entityUri(entity))
        .timeout({ response: 300000, deadline: 600000 })
        .send(data)
        .set('Accept', 'application/json')
        .set('Authorization', authHeader(token))
        .withCredentials()
        .end()
        .then((response) => parseResponse(entity, data, response))
        .catch((response) => {
            throw parseError(entity, data, response);
        });
}

export function patch(entity, data, token = null) {
    let url = entityUri(entity);

    if (data && data.id) url += '/' + data.id;
    const lang = Cookies.get('lang');
    return request
        .patch(url)
        .timeout({ response: 300000, deadline: 600000 })
        .send(data)
        .set('Accept', 'application/json')
        .set('Authorization', authHeader(token))
        .set('Accept-Language', lang || 'nl')
        .withCredentials()
        .end()
        .then((response) => parseResponse(entity, data, response))
        .catch((response) => {
            throw parseError(entity, data, response);
        });
}

export function fetchAll(entity, query = {}, token = null) {
    let url = entityUri(entity) + queryString(query);
    const lang = Cookies.get('lang');
    return request
        .get(url)
        .timeout({ response: 300000, deadline: 600000 })
        .set('Accept', 'application/json')
        .set('Authorization', authHeader(token))
        .set('Accept-Language', lang || 'nl')
        .withCredentials()
        .end()
        .then((response) => parseResponse(entity, null, response))
        .catch((response) => {
            throw parseError(entity, null, response);
        });
}

export function fetch(entity, data = null, token = null) {
    let url = entityUri(entity);
    const lang = Cookies.get('lang');
    if (data && data.id) url += '/' + data.id;
    return request
        .get(url)
        .timeout({ response: 300000, deadline: 600000 })
        .set('Accept', 'application/json')
        .set('Authorization', authHeader(token))
        .set('Accept-Language', lang || 'nl')
        .withCredentials()
        .end()
        .then((response) => parseResponse(entity, data, response))
        .catch((response) => {
            throw parseError(entity, data, response);
        });
}

export function upsert(entity, data = null, token = null) {
    if (data && data.id) {
        return patch(entity, data, token);
    } else {
        return create(entity, data, token);
    }
}

export function deleteProject(entity, data = null, token = null) {
    let url = entityUri(entity);
    if (data) url += '/delete/' + data;
    return request
        .patch(url)
        .timeout({ response: 300000, deadline: 600000 })
        .set('Authorization', authHeader(token))
        .withCredentials()
        .end()
        .then((response) => parseResponse(entity, data, response))
        .catch((response) => {
            throw parseError(entity, data, response);
        });
}

export function changeFactuurEmail(entity, data = null, token = null) {
    let url = entityUri(entity);
    if (data) url += '/factuuremail';
    return request
        .post(url)
        .timeout({ response: 300000, deadline: 600000 })
        .send(data)
        .set('Accept', 'application/json')
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function deleteEmployee(entity, data = null, token = null) {
    let url = entityUri(entity);
    if (data) url += '/delete';
    return request
        .del(url)
        .timeout({ response: 300000, deadline: 600000 })
        .send(data)
        .set('Accept', 'application/json')
        .set('Authorization', authHeader(token))
        .withCredentials()
        .end()
        .then((response) => parseResponse(entity, data, response))
        .catch((response) => {
            throw parseError(entity, data, response);
        });
}

export function deleteToolbox(entity, data = null, token = null) {
    let url = entityUri(entity);
    if (data) url += '/' + data + '/delete';
    return request
        .patch(url)
        .timeout({ response: 300000, deadline: 600000 })
        .set('Authorization', authHeader(token))
        .withCredentials()
        .end()
        .then((response) => parseResponse(entity, null, response))
        .catch((response) => {
            throw parseError(entity, data, response);
        });
}

export function cancelToolbox(entity, data = null, token = null) {
    let url = entityUri(entity);
    if (data) url += '/' + data + '/cancel';
    return request
        .patch(url)
        .timeout({ response: 300000, deadline: 600000 })
        .set('Authorization', authHeader(token))
        .withCredentials()
        .end()
        .then((response) => parseResponse(entity, null, response))
        .catch((response) => {
            throw parseError(entity, data, response);
        });
}

export function cancelToolboxGroup(entity, data = null, token = null) {
    let url = entityUri(entity);
    if (data) url += '/' + data + '/cancelgroup';
    return request
        .patch(url)
        .timeout({ response: 300000, deadline: 600000 })
        .set('Authorization', authHeader(token))
        .withCredentials()
        .end()
        .then((response) => parseResponse(entity, null, response))
        .catch((response) => {
            throw parseError(entity, data, response);
        });
}

export function cancelInspection(entity, data = null, token = null) {
    let url = entityUri(entity);
    if (data) url += '/' + data + '/cancel';
    return request
        .patch(url)
        .timeout({ response: 300000, deadline: 600000 })
        .set('Authorization', authHeader(token))
        .withCredentials()
        .end()
        .then((response) => parseResponse(entity, null, response))
        .catch((response) => {
            throw parseError(entity, data, response);
        });
}

export function cancelInspectionGroup(entity, data = null, token = null) {
    let url = entityUri(entity);
    if (data) url += '/' + data + '/cancelgroup';
    return request
        .patch(url)
        .timeout({ response: 300000, deadline: 600000 })
        .set('Authorization', authHeader(token))
        .withCredentials()
        .end()
        .then((response) => parseResponse(entity, null, response))
        .catch((response) => {
            throw parseError(entity, data, response);
        });
}

export function deleteInspection(entity, data = null, token = null) {
    let url = entityUri(entity);
    if (data) url += '/' + data + '/delete';
    return request
        .patch(url)
        .timeout({ response: 300000, deadline: 600000 })
        .set('Authorization', authHeader(token))
        .withCredentials()
        .end()
        .then((response) => parseResponse(entity, null, response))
        .catch((response) => {
            throw parseError(entity, data, response);
        });
}

export function deleteUsergroup(entity, data = null, token = null) {
    let url = entityUri(entity);
    if (data) url += '/delete';
    return request
        .del(url)
        .timeout({ response: 300000, deadline: 600000 })
        .send(data)
        .set('Authorization', authHeader(token))
        .withCredentials()
        .end()
        .then((response) => parseResponse(entity, data, response))
        .catch((response) => {
            throw parseError(entity, data, response);
        });
}

export function del(entity, data = null, token = null) {
    let url = entityUri(entity);
    if (data && data.id) url += '/' + data.id;
    return request
        .del(url)
        .timeout({ response: 300000, deadline: 600000 })
        .set('Accept', 'application/json')
        .set('Authorization', authHeader(token))
        .withCredentials()
        .end()
        .then((response) => parseResponse(entity, data, response))
        .catch((response) => {
            throw parseError(entity, data, response);
        });
}

export function upload(entity, data = null, token = null) {
    let url = entityUri(entity);
    let formData = new FormData();
    formData.append('file', data);
    return request
        .post(url)
        .timeout({ response: 300000, deadline: 600000 })
        .send(formData)
        .set('Accept', 'application/json')
        .set('Authorization', authHeader(token))
        .withCredentials()
        .end()
        .then((response) => parseResponse(entity, data, response))
        .catch((response) => {
            throw parseError(entity, data, response);
        });
}

export function parseImport(entity: string, data: any, callback: Function) {
    let url = entityUri(entity);
    request
        .post(url)
        .timeout({ response: 300000, deadline: 600000 })
        .send(data)
        .type('text/csv')
        .set('Authorization', authHeader())
        .withCredentials()
        .end(callback);
}

export function acknowledgeImport(
    entity: string,
    data: any,
    callback: Function
) {
    let url = entityUri(entity) + '/update';
    request
        .post(url)
        .timeout({ response: 300000, deadline: 600000 })
        .send(data)
        .type('text/csv')
        .set('Authorization', authHeader())
        .withCredentials()
        .end(callback);
}

export function acknowledgeImportExtern(
    entity: string,
    data: any,
    callback: Function
) {
    let url = entityUri(entity) + '/updateextern';
    request
        .post(url)
        .send(data)
        .type('text/csv')
        .set('Authorization', authHeader())
        .withCredentials()
        .end(callback);
}

export function inviteEmployees(
    idList: List<number>,
    callback: (err: any) => void,
    language = 'nl'
) {
    let url = entityUri('employee') + '/invite';

    request
        .post(url)
        .send({ employeeIds: idList, locale: language })
        .set('Authorization', authHeader())
        .withCredentials()
        .end(callback);
}

export function getPIN(id) {
    let url = entityUri('pinbyuserid/'+id);

    return request
        .get(url)
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function getCurrentEmployee() {
    let url = entityUri('user');
    return request
        .get(url)
        .set('Authorization', authHeader())
        .set('Logintype', 'webclient')
        .withCredentials()
        .end();
}

export function getAppToolboxes() {
    let url = entityUri('toolbox/assigned');
    return request
        .get(url)
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function getEmployees(usergroup) {
    let url = entityUri('usergroup');
    return request
        .get(url)
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function fetchDashboard() {
    let url = entityUri('dashboard');
    return request
        .get(url)
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function fetchToolboxScheduled() {
        let url = entityUri('dashboard/toolboxScheduled');
        return request
            .get(url)
            .set('Authorization', authHeader())
            .withCredentials()
            .end();
}

export function fetchToolboxDeployed() {
    let url = entityUri('dashboard/toolboxDeployed');
    return request
        .get(url)
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function fetchInspectionAssign() {
    let url = entityUri('dashboard/inspectionAssign');
    return request
        .get(url)
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function fetchInspectionDeployed() {
    let url = entityUri('dashboard/inspectionDeployed');
    return request
        .get(url)
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function fetchTotals() {
    let url = entityUri('dashboard/totals');
    return request
        .get(url)
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function fetchFullName() {
    let url = entityUri('dashboard/fullName');
    return request
        .get(url)
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function fetchInspectionAssignPerYear(year) {
    const lang = Cookies.get('lang');
    return request
        .get(entityUri('inspection-assign-projection/' + year))
        .set('Authorization', authHeader())
        .set('Accept', 'application/json')
        .set('Accept-Language', lang || 'nl')
        .withCredentials()
        .end();
}

export function fetchInspectionDetail(inspectionAssignId) {
    const lang = Cookies.get('lang');
    return request
        .get(entityUri('inspection-assign-detail/' + inspectionAssignId))
        .set('Authorization', authHeader())
        .set('Accept', 'application/json')
        .set('Accept-Language', lang || 'nl')
        .withCredentials()
        .end();
}


export function fetchInspectionDetailCollection(data: any) {
    const lang = Cookies.get('lang');
    let url = entityUri('inspection-assign-detail/get-collection');

    return request
        .post(url)
        .send(data)
        .set('Authorization', authHeader())
        .set('Accept', 'application/json')
        .set('Accept-Language', lang || 'nl')
        .withCredentials()
        .end();
}


export function fetchInspectionAssignByProject(projectId) {
    const lang = Cookies.get('lang');
    return request
        .get(entityUri('inspection-assign/' + projectId))
        .set('Authorization', authHeader())
        .set('Accept', 'application/json')
        .set('Accept-Language', lang || 'nl')
        .withCredentials()
        .end();
}

export function fetchInspectionTrend(inspectionId) {
    const lang = Cookies.get('lang');
    return request
        .get(entityUri('inspection/trend/' + inspectionId))
        .set('Authorization', authHeader())
        .set('Accept', 'application/json')
        .set('Accept-Language', lang || 'nl')
        .withCredentials()
        .end();
}

export function getPreviousInspectionResult(inspectionId: String, projectId: String) {
    const url = entityUri('previous-inspection-results/' + inspectionId + "/" + projectId)

    return request
        .get(url)
        .timeout({ response: 300000, deadline: 600000 })
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function getProjectIdByInspectionId(inspectionId: Long) {
    const url = entityUri('inspection-project/') + inspectionId;

    return request
        .get(url)
        .timeout({ response: 300000, deadline: 600000 })
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function fetchPromoCodes() {
    const lang = Cookies.get('lang');
    return request
        .get(entityUri('promocodes'))
        .set('Authorization', authHeader())
        .set('Accept', 'application/json')
        .set('Accept-Language', lang || 'nl')
        .withCredentials()
        .end();
}
export function deletePromoCode(code: string) {
    const lang = Cookies.get('lang');
    return request
        .get(entityUri('promocode/delete/'+code))
        .set('Authorization', authHeader())
        .set('Accept', 'application/json')
        .set('Accept-Language', lang || 'nl')
        .withCredentials()
        .end();
}

export function addPromoCode(data: any) {
    let url = entityUri('promocode');

    return request
        .post(url)
        .timeout({ response: 300000, deadline: 600000 })
        .send(data)
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function fetchExternToolbox(token) {
    const lang = Cookies.get('lang');
    return request
        .get(entityUri('toolbox/extern/' + token))
        .set('Accept-Language', lang || 'nl')
        .timeout({ response: 300000, deadline: 600000 })
        .withCredentials()
        .end();
}

export function fetchWebclientHelp() {
    const lang = Cookies.get('lang');
    return request
        .get(entityUri('help'))
        .timeout({ response: 300000, deadline: 600000 })
        .set('Authorization', authHeader())
        .set('Accept-Language', lang || null)
        .withCredentials()
        .end();
}

export function getIncidents() {
    const lang = Cookies.get('lang');
    return request
        .get(entityUri('incident'))
        .set('Accept-Language', lang || 'nl')
        .timeout({ response: 300000, deadline: 600000 })
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function getSpeakups() {
    const lang = Cookies.get('lang');
    return request
        .get(entityUri('speakup'))
        .set('Accept-Language', lang || 'nl')
        .timeout({ response: 300000, deadline: 600000 })
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}


export function getPublicInspections() {
    const lang = Cookies.get('lang');
    return request
        .get(entityUri('inspection/selfAssignable'))
        .timeout({ response: 300000, deadline: 600000 })
        .set('Authorization', authHeader())
        .set('Accept-Language', lang || 'nl')
        .withCredentials()
        .end();
}

export function publish(entity: string, id: string) {
    let url = entityUri(entity) + '/' + id + '/publish';
    return request
        .patch(url)
        .timeout({ response: 300000, deadline: 600000 })
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function retract(entity: string, id: string) {
    let url = entityUri(entity) + '/' + id + '/retract';
    return request
        .patch(url)
        .timeout({ response: 300000, deadline: 600000 })
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function deleteInspectionAssign(entity: string, id: string) {
    let url = entityUri(entity) + '/' + id + '/deleteassign';
    return request
        .patch(url)
        .timeout({ response: 300000, deadline: 600000 })
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function deleteToolboxAssign(entity: string, id: string) {
    let url = entityUri(entity) + '/' + id + '/deleteassign';
    return request
        .patch(url)
        .timeout({ response: 300000, deadline: 600000 })
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function deleteToolboxAssigns(toolboxId: string, deploymentId: number) {
    let url = entityUri('v2/toolboxes/') + toolboxId + '/assigns/' + deploymentId;
    return request
        .del(url)
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function getToolboxById(toolboxId: any) {
    let url = entityUri('toolbox/') + toolboxId;
    const lang = Cookies.get('lang');
    return request
        .get(url)
        .set('Authorization', authHeader())
        .set('Accept-Language', lang || 'nl')
        .withCredentials()
        .end();
}


export function getToolboxAssignSizeWithFilter(filter: String, filterType: String, filterCategory: String){
    let url = entityUri('v2/toolboxes/overview/sent/size?searchString=' + filter  + "&filterType=" + filterType + "&filterCategory=" + filterCategory);
    const lang = Cookies.get('lang');
    return request
        .get(url)
        .set('Authorization', authHeader())
        .set('Accept-Language', lang || 'nl')
        .withCredentials()
        .end();
}

export function getToolboxByIdV2(toolboxId: string) {
    let url = entityUri('v2/toolboxes/') + toolboxId;
    const lang = Cookies.get('lang');
    return request
        .get(url)
        .set('Authorization', authHeader())
        .set('Accept-Language', lang || 'nl')
        .withCredentials()
        .end();
}

export function getToolboxResultsOverviewForDeployment(toolboxId: string, deploymentId: string) {
    let url = entityUri('v2/toolboxes/') + toolboxId + '/assigns/' + deploymentId + '/results';
    const lang = Cookies.get('lang');
    return request
        .get(url)
        .set('Authorization', authHeader())
        .set('Accept-Language', lang || 'nl')
        .withCredentials()
        .end();
}

export function getToolboxAssignById(toolboxAssignId: any) {
    let url = entityUri('toolbox-assign/') + toolboxAssignId;
    const lang = Cookies.get('lang');
    return request
        .get(url)
        .set('Authorization', authHeader())
        .set('Accept-Language', lang || 'nl')
        .withCredentials()
        .end();
}

export function getToolboxResultForDeploymentAndUser(toolboxId: string, deploymentId: number, userId: number) {
    let url = entityUri('v2/toolboxes/') + toolboxId + '/assigns/' + deploymentId + '/results/' + userId;
    const lang = Cookies.get('lang');
    return request
        .get(url)
        .set('Authorization', authHeader())
        .set('Accept-Language', lang || 'nl')
        .withCredentials()
        .end();
}

export function getToolboxInformation(toolboxId: string) {
    let url = entityUri('v2/toolboxes/') + toolboxId + '/information';
    const lang = Cookies.get('lang');
    return request
        .get(url)
        .set('Authorization', authHeader())
        .set('Accept-Language', lang || 'nl')
        .withCredentials()
        .end();
}

export function getToolboxTrend(toolboxId: string) {
    let url = entityUri('v2/toolboxes/') + toolboxId + '/trend';
    const lang = Cookies.get('lang');
    return request
        .get(url)
        .set('Authorization', authHeader())
        .set('Accept-Language', lang || 'nl')
        .withCredentials()
        .end();
}


// export function deleteToolboxAssigns(entity: string, id: string, users: any) {
//     let url = entityUri(entity) + '/' + id + '/deleteassigns';
//     return request
//         .patch(url)
//         .send(users)
//         .set('Authorization', authHeader())
//         .withCredentials()
//         .end();
// }

export function addUserToUsergroup(
    entity: string,
    usergroupId: any,
    employeeId: any
) {
    let url = entityUri(entity);
    if (usergroupId && employeeId)
        url += '/' + usergroupId + '/employee/' + employeeId + '/add';

    return request
        .patch(url)
        .timeout({ response: 300000, deadline: 600000 })
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function removeUserFromUsergroup(
    entity: string,
    usergroupId: any,
    employeeId: any
) {
    let url = entityUri(entity);
    if (usergroupId && employeeId)
        url += '/' + usergroupId + '/employee/' + employeeId + '/delete';

    return request
        .patch(url)
        .timeout({ response: 300000, deadline: 600000 })
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function fetchPDF(options: any) {
    const lang = Cookies.get('lang');
    return request
        .post(`${config.RAPPORTAGE_HOST}/pdf`)
        .timeout({ response: 300000, deadline: 600000 })
        .send(options)
        .set('Content-Type', 'application/json')
        .set('Access-Control-Allow-Origin', '*')
        .set('Accept-Language', lang || 'nl')
        .responseType('blob');
}

export function fetchCSV(options: any) {
    const lang = Cookies.get('lang');
    return request
        .post(`${config.RAPPORTAGE_HOST}/csv`)
        .timeout({ response: 300000, deadline: 600000 })
        .send(options)
        .set('Content-Type', 'application/json')
        .set('Access-Control-Allow-Origin', '*')
        .set('Accept-Language', lang || 'nl')
        .responseType('blob');
}

export function sendPDFInfo(id: string, data: any) {
    let formData = new FormData();
    formData.append('file', data);
    formData.append('id', id);
    
    return request
        .post(entityUri('SendPDFByMail'))
        .timeout({ response: 300000, deadline: 600000 })
        .send(formData)
        .set("Authorization", authHeader())
        .withCredentials()
        .end();
}

export function upgradeSubscription() {
    const url = entityUri('subscription') + '/upgrade';

    return request
        .post(url)
        .timeout({ response: 300000, deadline: 600000 })
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function upgradeCredits() {
    const url = entityUri('subscription') + '/buycredits';

    return request
        .post(url)
        .timeout({ response: 300000, deadline: 600000 })
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function getSubscriptions() {
    const url = entityUri('subscription');

    return request
        .get(url)
        .timeout({ response: 300000, deadline: 600000 })
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function createSector(data: any) {
    let url = entityUri('sector');

    return request
        .post(url)
        .timeout({ response: 300000, deadline: 600000 })
        .send(data)
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function fetchAssignedToolboxesOverviewWithParams(filter: String, pageNumber: Number, pageSize: Number, orderBy: String, orderDirection: String, filterType: String,filterCategory: String ){
    let url = entityUri('v2/toolboxes/overview/sent?searchString=' + filter + "&pageNumber=" + pageNumber + "&pageSize=" + pageSize + "&orderBy=" + orderBy + "&orderDirection=" + orderDirection + "&filterType=" + filterType + "&filterCategory=" + filterCategory )
    return request
    .get(url)
    .set('Authorization', authHeader())
    .withCredentials()
    .end()
}

export function fetchAssignedInspectionsOverviewWithParams(searchString: String, pageNumber: Number, pageSize: Number, orderBy: String, orderDirection: String, filterType: String,filterCategory: String ){
    let url = entityUri('inspection-assign-projection?searchString=' + searchString + "&pageNumber=" + pageNumber + "&pageSize=" + pageSize + "&orderBy=" + orderBy + "&orderDirection=" + orderDirection + "&filterType=" + filterType)
    return request
    .get(url)
    .set('Authorization', authHeader())
    .withCredentials()
    .end()
}

export function sendToolboxReminder(toolboxId: string, deploymentId: number) {
    let url = entityUri('v2/toolboxes/' + toolboxId + '/assigns/' + deploymentId + '/sendReminder');

    return request
        .post(url)
        .send()
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function sendToolboxReminderToUser(toolboxId: string, deploymentId: number, userId: number) {
    let url = entityUri('v2/toolboxes/' + toolboxId + '/assigns/' + deploymentId + '/sendReminder/' + userId);

    return request
        .post(url)
        .send()
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function getToolboxScheduledGroups() {
    let url = entityUri('group-assigns');

    return request
        .post(url)
        .timeout({ response: 300000, deadline: 600000 })
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function updateInspectionAssign(id, data: any) {
    let url = entityUri('updateinspectionassign/' + id);

    return request
        .post(url)
        .timeout({ response: 300000, deadline: 600000 })
        .send(data)
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}


export function saveIncident(data: any) {
    let url = entityUri('incidentedit');

    return request
        .post(url)
        .timeout({ response: 300000, deadline: 600000 })
        .send(data)
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function speekupSeen(data: any) {
	let url = entityUri("speakupseen");

	return request
		.post(url)
		.timeout({ response: 300000, deadline: 600000 })
		.send(data)
		.set("Authorization", authHeader())
		.withCredentials()
		.end();
}

export function documentDownloaded(data: any, state) {
    let url = entityUri("document/" + data.id) + '/state';
    if (state) { data.state = 'DOWNLOADED'; } else { data.state = 'DOWNLOAD_FAILED'; }

    return request
        .post(url)
        .timeout({ response: 300000, deadline: 600000 })
        .send(data)
        .set("Authorization", authHeader())
        .withCredentials()
        .end();
}

export function seenIncident(data: any) {
    let url = entityUri('incidentseen');

    return request
        .post(url)
        .timeout({ response: 300000, deadline: 600000 })
        .send(data)
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function deleteDocument(data) {
	let url = entityUri("documentdelete");

	return request
		.del(url)
		.timeout({ response: 300000, deadline: 600000 })
		.send({
			id: data.id,
		})
		.set("Authorization", authHeader())
		.withCredentials()
		.end();
}

export function deleteSpeakup(data: any) {
	let url = entityUri("speakupdelete");

	return request
		.del(url)
		.timeout({ response: 300000, deadline: 600000 })
		.send({
            id:data.id
        })
		.set("Authorization", authHeader())
		.withCredentials()
		.end();
}

export function deleteIncident(data: any) {
    let url = entityUri('incidentdelete');

    return request
        .post(url)
        .timeout({ response: 300000, deadline: 600000 })
        .send(data)
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function sendInspectionPushReminder(inspectionId: any, data: any) {
    let url = entityUri('inspection/pushreminder/') + inspectionId;

    return request
        .post(url)
        .timeout({ response: 300000, deadline: 600000 })
        .send(data)
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}


export function getAbonnees() {
    const url = entityUri('subscriptions/getSubscriptions');

    return request
        .get(url)
        .timeout({ response: 300000, deadline: 600000 })
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function getAbonneesCUMELA() {
    const url = entityUri('subscriptions/getSubscriptionsCUMELA');

    return request
        .get(url)
        .timeout({ response: 300000, deadline: 600000 })
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function getSubscriptionCategories(id: Number) {
    const url = entityUri('subscription/categories/') + id;

    return request
        .get(url)
        .timeout({ response: 300000, deadline: 600000 })
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function getHistory(id: Number) {
    const url = entityUri('subscriptions/getSubscriptionHistory/') + id;

    return request
        .get(url)
        .timeout({ response: 300000, deadline: 600000 })
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}


export function impersonateUser(id) {
    const url = entityUri('impersonate/') + id;
    return request
        .get(url)
        .timeout({ response: 300000, deadline: 600000 })
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function getNotificationSettings() {
    const url = entityUri('kamsettings');
    return request
        .get(url)
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function postNotificationSettings (data: any) {
    let url = entityUri('kamsetting');

    return request
        .post(url)
        .send(data)
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function apiStatisticsCall(path: string, periodType: string, period: string, periodTill: string, isToolboxMeeting: boolean, isNoToolboxMeeting: boolean, sector: String, category: String, afgerond: String, project: String, uitvoerder: number, resultaat: number, situatie: number, inspectionId: string, groupFilter: number, dispatch: Dispatch<TLoginAction>, callback: Function) {

    if (path === 'chartData' && statisticsChartRequest) {
        statisticsChartRequest.abort();
    } else if (path === 'listData' && statisticsListRequest) {
        statisticsListRequest.abort();
    }

    let inspectionPath = inspectionId ? '/' + inspectionId : '';


    let url = config.API_HOST + 'api/statistics/' + path + inspectionPath + '?';
    // if (distributorScope) {
    //     url += 'distributorScope=' + distributorScope + '&';
    // } else if (customerScope) {
    //     url += 'customerScope=' + customerScope + '&';
    // } else if (locationScope) {
    //     url += 'locationScope=' + locationScope + '&';
    // } else if (deviceScope) {
    //     url += 'deviceScope=' + deviceScope + '&';
    // }

    // if (distributorFilter && distributorFilter.length) {
    //     url += 'distributors=';
    //     for (let i = 0; i < distributorFilter.length; i++) {
    //         url += distributorFilter[i];
    //         if (i < distributorFilter.length - 1) {
    //             url += ',';
    //         }
    //     }
    //     url += '&';
    // }
    // if (customerFilter && customerFilter.length) {
    //     url += 'customers=';
    //     for (let i = 0; i < customerFilter.length; i++) {
    //         url += customerFilter[i];
    //         if (i < customerFilter.length - 1) {
    //             url += ',';
    //         }
    //     }
    //     url += '&';
    // }
    // if (locationFilter && locationFilter.length) {
    //     url += 'locations=';
    //     for (let i = 0; i < locationFilter.length; i++) {
    //         url += locationFilter[i];
    //         if (i < locationFilter.length - 1) {
    //             url += ',';
    //         }
    //     }
    //     url += '&';
    // }
    // if (deviceFilter && deviceFilter.length) {
    //     url += 'devices=';
    //     for (let i = 0; i < deviceFilter.length; i++) {
    //         url += deviceFilter[i];
    //         if (i < deviceFilter.length - 1) {
    //             url += ',';
    //         }
    //     }
    //     url += '&';
    // }
    // if (therapyTypes && therapyTypes.length) {
    //     url += 'therapies=';
    //     for (let i = 0; i < therapyTypes.length; i++) {
    //         url += therapyTypes[i];
    //         if (i < therapyTypes.length - 1) {
    //             url += ',';
    //         }
    //     }
    //     url += '&';
    // }
    // if (programIds && programIds.length) {
    //     url += 'programIds=';
    //     for (let i = 0; i < programIds.length; i++) {
    //         url += programIds[i];
    //         if (i < programIds.length - 1) {
    //             url += ',';
    //         }
    //     }
    //     url += '&';
    // }
    // if (minDuration || minDuration === 0) {
    //     url += 'minDuration=' + minDuration + '&';
    // }
    // if (maxDuration || maxDuration === 0) {
    //     url += 'maxDuration=' + maxDuration + '&';
    // }
    if (sector === undefined) {
        sector = 0;
    }
    if (afgerond === undefined) {
        afgerond = 0;
    }
    const lang = Cookies.get('lang');
    let locale: string =
        lang || window.navigator.userLanguage || window.navigator.language;

    url += 'locale=' + locale + '&';
    url += 'periodType=' + periodType + '&';
    url += 'period=' + period + '&';
    url += 'periodTill=' + periodTill + '&';
    url += 'isToolboxMeeting=' + isToolboxMeeting + '&';
    url += 'isNoToolboxMeeting=' + isNoToolboxMeeting + '&';
    url += 'sector=' + sector + '&';
    url += 'category=' + category + '&';
    url += 'afgerond=' + afgerond + '&';

    url += 'project=' + project + '&';
    url += 'uitvoerder=' + uitvoerder + '&';
    url += 'resultaat=' + resultaat + '&';
    url += 'situatie=' + situatie + '&';
    url += 'group=' + groupFilter;

    let req = request
        .get(url)
        .set('Authorization', authHeader())
        .set('Accept', 'application/json')
        .withCredentials();
    if (path === 'chartData') {
        statisticsChartRequest = req;
    } else if (path === 'listData') {
        statisticsListRequest = req;
    }

    req.end(mycallback(dispatch, callback));
}

function mycallback(dispatch: Dispatch<TLoginAction>, callback: Function) {
    return function (err: any, res: any) {
        if (err && err.status === 401) {
            // dispatch(logoutUser());
        }
        if (callback) {
            callback(err, res);
        }
    };
}

export function getSubscriptionFunctionality(subscriptionId: number) {
    return request
        .get(apiUrl + `subscription/${subscriptionId}/functionality`)
        .timeout({ response: 300000, deadline: 600000 })
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function changeSubscriptionFunctionality(
    subscriptionId: number,
    data: any
) {
    return request
        .patch(apiUrl + `subscription/${subscriptionId}/functionality`)
        .timeout({ response: 300000, deadline: 600000 })
        .send(data)
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function getSubscriptionFunctionalityWhitelabel(subscriptionId: number) {
    return request
        .get(apiUrl + `subscription/${subscriptionId}/functionality/whitelabel`)
        .timeout({ response: 300000, deadline: 600000 })
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function changeSubscriptionFunctionalityWhitelabel(
    subscriptionId: number,
    data: any
) {
    return request
        .patch(
            apiUrl + `subscription/${subscriptionId}/functionality/whitelabel`
        )
        .timeout({ response: 300000, deadline: 600000 })
        .send(data)
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function getWhitelabelAppStoreCodes(subscriptionId: number) {
    return request
        .get(
            apiUrl +
                `subscription/${subscriptionId}/functionality/whitelabel/appstore-codes`
        )
        .timeout({ response: 300000, deadline: 600000 })
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function parseWhitelabelAppStoreCodes(
    subscriptionId: number,
    data: any,
    callback: Function
) {
    return request
        .post(
            apiUrl +
                `subscription/${subscriptionId}/functionality/whitelabel/appstore-codes`
        )
        .timeout({ response: 300000, deadline: 600000 })
        .send(data)
        .type('text/csv')
        .set('Authorization', authHeader())
        .withCredentials()
        .end(callback);
}

export function exchangeAidForPin(aid: string) {
    return request
        .get(apiUrl + `pin?aid=${aid}`)
        .timeout({ response: 300000, deadline: 600000 })
        .set('Accept', 'application/json')
        .end()
        .then((response) => JSON.parse(response.text).pin)
        .catch((response) => {
            throw response;
        });
}

// fetch task list by subscriptionholder
export function fetchAllTasks() {
    const lang = Cookies.get('lang');
    return request
        .get(entityUri('tasklist'))
        .set('Authorization', authHeader())
        .set('Accept', 'application/json')
        .set('Accept-Language', lang || 'nl')
        .withCredentials()
        .end();
}


// fetch Tasks For Inspection Assign
export function fetchTasksForInspectionAssign(inspectionAssignId) {
    const lang = Cookies.get('lang');
    return request
        .get(entityUri('task/inspection_assign/' + inspectionAssignId))
        .set('Authorization', authHeader())
        .set('Accept', 'application/json')
        .set('Accept-Language', lang || 'nl')
        .withCredentials()
        .end();
}

// fetch Tasks For Incident
export function fetchTasksForIncident(incidentId) {
    const lang = Cookies.get('lang');
    return request
        .get(entityUri('task/incident/' + incidentId))
        .set('Authorization', authHeader())
        .set('Accept', 'application/json')
        .set('Accept-Language', lang || 'nl')
        .withCredentials()
        .end();
}

export function apiAddUpdateTask(task) {
    const lang = Cookies.get('lang');
    return request
        .post(entityUri('task'))
        .send(task)
        .set('Authorization', authHeader())
        .set('Accept', 'application/json')
        .set('Accept-Language', lang || 'nl')
        .withCredentials()
        .end();
}

export function apiDeleteTaskById(taskId) {
    const lang = Cookies.get('lang');
    const task = { id: taskId };
    return request
        .post(entityUri('taskdelete'))
        .send(task)
        .set('Authorization', authHeader())
        .set('Accept', 'application/json')
        .set('Accept-Language', lang || 'nl')
        .withCredentials()
        .end();
}

// WebClient call
export function getTasks() {
    const lang = Cookies.get('lang');
    return request
        .get(entityUri('task'))
        .set('Accept-Language', lang || 'nl')
        .timeout({ response: 300000, deadline: 600000 })
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}

export function getTaskById(taskId) {
    const lang = Cookies.get('lang');
    return request
        .get(entityUri('task/' + taskId))
        .set('Accept-Language', lang || 'nl')
        .timeout({ response: 300000, deadline: 600000 })
        .set('Authorization', authHeader())
        .withCredentials()
        .end();
}


